import React, { useContext, useState, useEffect } from 'react';
import { SocketContext } from '../../../../main/context/socket';
import { Button, FormGroup, Label, Collapse } from 'reactstrap';
import axiosInstance from 'main/axios';
import { useParams, useHistory } from 'react-router-dom';
import { URLS } from 'library/common/constants/UrlConstants';
import { images } from 'library/common/constants/ImageConstants';
import { setApiMessage } from 'library/common/constants/function';
import PageLoader from 'library/common/components/PageLoader';
import popularLinks from '../../../../resources/images/popular_links.svg';
import OtherLinks from '../../../../resources/images/links.svg';
import './index.scss';
import { createDraftOrg } from 'modules/Organization/menu-items';
import { fetchFromStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';

const intlSociallinks = [
  {
    id: 1,
    linkType: 'facebook',
    linkUrl: '',
  },
  {
    id: 2,
    linkType: 'twitter',
    linkUrl: '',
  },
  {
    id: 3,
    linkType: 'instagram',
    linkUrl: '',
  },
  {
    id: 4,
    linkType: 'linkedin',
    linkUrl: '',
  },
  {
    id: 5,
    linkType: 'youtube',
    linkUrl: '',
  },
  {
    id: 6,
    linkType: 'pinterest',
    linkUrl: '',
  },
];

const SocialLinks = () => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const params = useParams();
  const [socialLinksData, setSocialLinksData] = useState([]);
  const [socialError, setsocialError] = useState({});

  const [customLinksData, setcustomLinksDataData] = useState([]);
  const [loading, setloading] = useState(false);
  const [linkValues, setLinkValues] = useState([]);
  const [collapse, setCollapse] = useState([0]);
  const [status, setStatus] = useState('');
  const [isVisible, setisVisible] = useState(false);
  const history = useHistory();
  const isNewOrg = window.location.pathname === '/organization/organiser-details';

  useEffect(() => {
    getSocial();
  }, []);

  const addLinkFields = () => {
    setcustomLinksDataData([
      ...customLinksData,
      {
        id: '',
        linkType: 'custom',
        linkName: `Link ${customLinksData?.length + 1}`,
        linkUrl: '',
        lnameErr: '',
        llinkErr: '',
      },
    ]);
    setCollapse([...collapse, customLinksData?.length]);
  };

  const handleAddDeletelinks = async (item, value) => {
    const currentOrgId = fetchFromStorage(identifiers.currentOrganization);

    let socialData = socialLinksData.map(data => {
      return {
        linkType: data?.linkType,
        linkUrl: data?.linkUrl,
      };
    });
    // let linkFinalData = removeData ? removeData : customLinksData;

    let deletedCustom = customLinksData?.filter(link => {
      return link?.id !== item?.id;
    });

    let OtherLinksData = deletedCustom.map(data => {
      if (data?.id) {
        let obj = {
          OrganisationSocialLinksId: data?.id,
          linkType: data?.linkType,
          linkName: data?.linkName,
          linkUrl: data?.linkUrl,
        };
        return obj;
      } else {
        let obj2 = { linkType: data?.linkType, linkName: data?.linkName, linkUrl: data?.linkUrl };
        return obj2;
      }
    });

    let finleLinkData = OtherLinksData?.filter(item => {
      return item?.linkName !== '' && item?.linkUrl !== '';
    });

    let payload = {
      SociallinkDetails: socialData.concat(finleLinkData),
    };

    setloading(true);
    try {
      const { status, data } = await axiosInstance.post(URLS.addsocialLInks(currentOrgId), payload);
      if (status === 201) {
        setloading(false);
        setApiMessage(
          'success',
          value
            ? OKTION_VARIABLE?.OKTION_SOCIAL_DELETE_SUCCESSFULLY
            : OKTION_VARIABLE?.OKTION_SOCIAL_ADDED_SUCCESSFULLY,
        );
        if (isNewOrg) {
          setTimeout(() => {
            history.replace(`/organization/${currentOrgId}?social-links`);
          }, 1000);
        }
        getSocial();
      }
    } catch (err) {
      setloading(false);
    }
  };

  // const removeLinkFields = i => {
  //   let removeLink = customLinksData?.filter((item, index) => {
  //     return index !== i;
  //   });

  //   setcustomLinksDataData(removeLink);
  //   // let newLinkValues = [...linkValues];
  //   // newLinkValues.splice(i, 1);
  //   // setLinkValues(newLinkValues);
  //   // // let newCollapseValue = collapse?.filter(el => el !== i);
  //   // // setCollapse(newCollapseValue);
  //   handleAddDeletelinks(removeLink);
  // };

  const onEntered = () => setStatus('Opened');
  const onExited = () => setStatus('Closed');
  const toggle = index => {
    if (collapse?.includes(index)) {
      let newCollapseValue = collapse?.filter(el => el !== index);
      // newCollapseValue.splice(index, 1);
      setCollapse(newCollapseValue);
    } else {
      setCollapse([...collapse, index]);
    }
  };

  let handleChange = (value, id, type) => {
    setisVisible(true);
    let changeData = customLinksData?.map((obj, index) => {
      if (id == index) {
        let newData =
          type == 'linkName'
            ? ((obj.linkName = value), (obj.lnameErr = ''))
            : type == 'linkUrl'
            ? ((obj.linkUrl = value), (obj.llinkErr = ''))
            : '';
      }
      return obj;
    });
    setcustomLinksDataData(changeData);
  };

  const isValidURL = string => {
    var res = string.match(
      /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/,
    );
    return res !== null;
  };

  const handleSave = async () => {
    let flag = false;

    const change = customLinksData?.map(obj => {
      let changename = obj.linkName == '' ? (obj.lnameErr = OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE) : '';
      let changeurl = obj.linkUrl == '' ? (obj.llinkErr = OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE) : '';

      return obj;
    });

    let FlagResult = customLinksData?.filter(item => {
      return item?.linkName == '' || item?.linkUrl == '';
    });
    setcustomLinksDataData(change);
    if (FlagResult?.length <= 0) {
      if (isNewOrg) {
        let { status } = await createDraftOrg();
        if (status) {
          setTimeout(() => {
            handleAddDeletelinks();
          }, 1000);
        }
      } else {
        handleAddDeletelinks();
      }
    }
  };

  const getSocial = async () => {
    setloading(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.getsocialLInks(params?.id));
      if (status === 201) {
        let finalSocialData = data?.data?.OrganisationSocialLinksList;

        let fbResult =
          finalSocialData?.filter(o1 => o1?.linkType == 'facebook')?.length > 0
            ? finalSocialData?.filter(o1 => o1?.linkType == 'facebook')
            : [{ linkType: 'facebook', linkUrl: '' }];
        let twitterResult =
          finalSocialData?.filter(o1 => o1?.linkType == 'twitter')?.length > 0
            ? finalSocialData?.filter(o1 => o1?.linkType == 'twitter')
            : [{ linkType: 'twitter', linkUrl: '' }];
        let instagramResult =
          finalSocialData?.filter(o1 => o1?.linkType == 'instagram')?.length > 0
            ? finalSocialData?.filter(o1 => o1?.linkType == 'instagram')
            : [{ linkType: 'instagram', linkUrl: '' }];
        let linkedinResult =
          finalSocialData?.filter(o1 => o1?.linkType == 'linkedin')?.length > 0
            ? finalSocialData?.filter(o1 => o1?.linkType == 'linkedin')
            : [{ linkType: 'linkedin', linkUrl: '' }];
        let youtubeResult =
          finalSocialData?.filter(o1 => o1?.linkType == 'youtube')?.length > 0
            ? finalSocialData?.filter(o1 => o1?.linkType == 'youtube')
            : [{ linkType: 'youtube', linkUrl: '' }];
        let pinterestResult =
          finalSocialData?.filter(o1 => o1?.linkType == 'pinterest')?.length > 0
            ? finalSocialData?.filter(o1 => o1?.linkType == 'pinterest')
            : [{ linkType: 'pinterest', linkUrl: '' }];

        let filterData = [
          ...fbResult,
          ...twitterResult,
          ...instagramResult,
          ...linkedinResult,
          ...youtubeResult,
          ...pinterestResult,
        ];

        setSocialLinksData(filterData);
        let otherLink = data?.data?.OrganisationCustomSocialLinksList;
        let changeData =
          otherLink?.length > 0
            ? otherLink?.map(obj => {
                let socialData = {
                  ...obj,
                  lnameErr: '',
                  llinkErr: '',
                };
                return socialData;
              })
            : [];
        setcustomLinksDataData(changeData);

        setCollapse([...Array(otherLink?.length).keys()]);
        setloading(false);
        setisVisible(false);
      }
    } catch (err) {
      setloading(false);
    }
  };

  let handlelinkChange = (value, name, item) => {
    setisVisible(true);
    let changeSocialValue = socialLinksData?.map((obj, index) => {
      if (item === index) {
        let test =
          name == 'facebook'
            ? (obj.linkUrl = value)
            : name == 'twitter'
            ? (obj.linkUrl = value)
            : name == 'instagram'
            ? (obj.linkUrl = value)
            : name == 'linkedin'
            ? (obj.linkUrl = value)
            : name == 'youtube'
            ? (obj.linkUrl = value)
            : name == 'pinterest'
            ? (obj.linkUrl = value)
            : '';
      }
      return obj;
    });
    setSocialLinksData(changeSocialValue);
  };

  const handleCancle = () => {
    setSocialLinksData([]);
    setcustomLinksDataData([]);
    getSocial();
  };

  return (
    <>
      <div className="social-links transfer-ownership">
        {loading && <PageLoader />}
        <h1 className="text-center page-title text-lg-left mb-2 mb-md-5 mt-4">
          {OKTION_VARIABLE?.OKTION_SOCIAL_LINKS}
        </h1>
        <div className="formWrapper">
          <div className="event-section">
            <div className="section-header">
              <div className="icon">
                <img src={popularLinks} alt={OKTION_VARIABLE?.OKTION_POPULAR_LINKS} />
              </div>
              <h3>{OKTION_VARIABLE?.OKTION_POPULAR_LINKS}</h3>
            </div>
            <div className="section-fields social-link-wrap pt-lg-2 pb-lg-3 pb-4">
              {socialLinksData?.map((item, index) => {
                return (
                  <>
                    <FormGroup className="focus_field">
                      <input
                        className={`form-control`}
                        name={item?.linkType}
                        type="url"
                        placeholder="https://"
                        value={item?.linkUrl}
                        onChange={e => {
                          handlelinkChange(e.target.value, item?.linkType, index);
                        }}
                      />
                      <Label className="label-helper">{item?.linkType}</Label>
                    </FormGroup>
                  </>
                );
              })}
            </div>
          </div>
          <div className="event-section pt-lg-4">
            <div className="section-header mb-lg-4">
              <div className="icon">
                <img src={OtherLinks} alt={OKTION_VARIABLE?.OKTION_OTHERLINK} />
              </div>
              <h3>{OKTION_VARIABLE?.OKTION_OTHERLINK}</h3>
            </div>
            <div className="section-fields">
              <div className="d-flex align-items-center justify-content-end">
                <Button className="main-button fw-500 btn btn-secondary link-btn" onClick={() => addLinkFields()}>
                  Add Link
                </Button>
              </div>
              <div className="section-fields social-link-wrap pt-3 pb-5">
                {customLinksData?.length > 0 &&
                  customLinksData.map((element, index) => {
                    return (
                      <>
                        <div className="collapse-wrap d-flex justify-content-between pb-3 align-items-center">
                          <div className="collapse-title ">{element?.linkName}</div>
                          <div className="collapse-icon d-flex align-items-center">
                            <img
                              className="cursor-pointer"
                              onClick={() => handleAddDeletelinks(element, true)}
                              src={images?.deleteWithCircle}
                              height="20px"
                              alt=""
                            />
                            <img
                              className={status == 'Closed' ? 'closed cursor-pointer' : 'cursor-pointer'}
                              onClick={() => {
                                toggle(index);
                              }}
                              src={images?.uppArraow}
                              alt=""
                              width="15px "
                            />
                          </div>
                        </div>

                        <Collapse isOpen={collapse?.includes(index)} onEntered={onEntered} onExited={onExited}>
                          <FormGroup className="focus_field">
                            <input
                              className={`form-control ${index}`}
                              name="linkname"
                              value={element.linkName || ''}
                              onChange={e => handleChange(e.target.value, index, 'linkName')}
                              type="text"
                              placeholder={OKTION_VARIABLE?.OKTION_LINKNAME}
                            />
                            <Label className="label-helper">{OKTION_VARIABLE?.OKTION_LINKNAME}</Label>
                            <span className="text-danger">{element?.lnameErr}</span>
                          </FormGroup>
                          <FormGroup className="focus_field">
                            <input
                              className={`form-control ${index}`}
                              name="link"
                              value={element.linkUrl || ''}
                              onChange={e => handleChange(e.target.value, index, 'linkUrl')}
                              type="text"
                              placeholder="https://"
                            />
                            <span className="text-danger">{element?.llinkErr}</span>
                            <Label className="label-helper">{OKTION_VARIABLE?.OKTION_LINK}</Label>
                          </FormGroup>
                        </Collapse>
                      </>
                    );
                  })}{' '}
                {isVisible && (
                  <div className="personal-button-view">
                    <div className="button-style">
                      <>
                        <Button
                          className="app-button  black-button  h-45 mr-2"
                          onClick={() => {
                            handleCancle();
                          }}>
                          {OKTION_VARIABLE?.OKTION_BUTTON_CANCEL}
                        </Button>

                        <Button className="app-button  main-button  h-45 btn-block ml-2 " onClick={() => handleSave()}>
                          {OKTION_VARIABLE?.OKTION_SAVE_CHANGES}
                        </Button>
                      </>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialLinks;
