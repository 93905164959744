import React, { useEffect, useState, useContext } from 'react';
import SearchBox from './Frame/Search/SearchBox';
import { Collapse } from 'reactstrap';
import Col from 'reactstrap/es/Col';
import Row from 'reactstrap/es/Row';
import moment from 'moment';
import { images } from 'library/common/constants/ImageConstants';
import renderHTML from 'react-render-html';
import anchorme from 'anchorme';
import EventTimeDetails from '../EventTimeDetails';
// import { itemFollowingCardData } from '../../../UserProfile/UserProfileData';
import ItemFollowingCards from '../../../UserProfile/Frame/ContributionHistory/Frame/ItemFollowingCards';
import { FormGroup, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { getUserCards } from 'library/common/actions/UserCardsAction';
import { fetchFromStorage } from '../../../../utility';
import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
import axiosInstance from '../../../../main/axios';
import { URLS } from '../../../../library/common/constants/UrlConstants';
// import EventEmitter from 'reactjs-eventemitter';
// import { fetchFromStorage } from 'utility';
// import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
import { useParams, useHistory } from 'react-router-dom';
import { SocketContext } from '../../../../main/context/socket';
const Auction = props => {
  const params = useParams();
  const history = useHistory();
  var eventId = params?.id;
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  var userDetails = fetchFromStorage(identifiers?.userDetail);
  // console.log('eventId', eventId);
  // var auctionFeatureData = props?.eventFeatures?.filter((item, index) => item.type === 'auction');
  // useEffect(() => {
  //   const userDetails = fetchFromStorage(identifiers.userDetail);
  //   // if (userDetails?.id === 5) {/
  //   console.log('deleteAuctionLot', props?.auctionData?.data?.eventFeatures[0]?.auctionOptions);
  //   EventEmitter.subscribe('updateBiddingData', event => {
  //     setLotArray(props?.auctionData?.data?.eventFeatures[0]?.auctionOptions);
  //   });
  //   // }
  // }, []);
  // useEffect(() => {
  //   EventEmitter.subscribe('socketUpdateBiddingData', event => {
  //     console.log('socketUpdateBiddingData', event);
  //     // setLotArray();
  //     // getDonadationData();
  //   });
  // });
  const [loading, setLoading] = useState(false);
  const [itemFollowingCardData, setLotArray] = useState([]);
  const [auctionLotData, setAuctionLotData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [isabout, setIsAbout] = useState(true);
  const [eventOutfit, setEventOutfit] = useState();
  const [auctionDetails, setAuctionDetails] = useState([]);

  const token = fetchFromStorage(identifiers.token);

  useEffect(() => {
    props.getUserCards();
  }, []);
  useEffect(() => {
    // const eventDetails = fetchFromStorage(identifiers.eventDetails);
    setLoading(true);
    axiosInstance
      .get(URLS.getCategoryPublic(eventId))
      .then(({ status, data }) => {
        if (status === 201 || status === 200) {
          setCategoryList(data?.data?.categories);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
      });
    // setLotArray(props?.auctionData?.data?.eventFeatures[0]?.auctionOptions);
    // getEventDetailsById();
    token && getAuctionDeatails();
  }, []);

  // useEffect(() => {
  //   const userDetails = fetchFromStorage(identifiers.userDetail);
  //   EventEmitter.subscribe('socketUpdateBiddingData', event => {
  //     console.log('socketUpdateBiddingData', event);
  //     // if (event?.item?.userId === userDetails?.id) {
  //     const updateditemFollowingCardData = props?.auctionData?.data?.eventFeatures[0]?.auctionOptions.map(
  //       (obj, index) => {
  //         // console.log('objobj', obj, event?.item);
  //         return event?.item?.id === obj?.id ? event?.item : obj;
  //       },
  //     );
  //     setLotArray(updateditemFollowingCardData);
  //     console.log('updateditemFollowingCardData', updateditemFollowingCardData);
  //     // getDonadationData();
  //     // }
  //   });
  // });
  // const auctionLot_Data = () => {
  //   console('call');
  //   setLotArray(props?.auctionData?.data?.eventFeatures[0]?.auctionOptions);
  // };
  // EventEmitter.subscribe('updateBiddingData', auctionLot_Data);
  // useEffect(() => {
  //   EventEmitter.subscribe('updateBiddingData', event => {

  //     setLotArray(props?.auctionData?.data?.eventFeatures[0]?.auctionOptions);
  //   });
  // });
  /* useEffect(event => {
    EventEmitter.subscribe('auctionDeletedLot', event);
  }); */
  /*  useEffect(() => {
    EventEmitter.subscribe('auctionDeletedLot', value => {
      setLotArray(props?.auctionData?.data?.eventFeatures[0]?.auctionOptions);
      console.log('inside emiter');
    });
  }); */
  useEffect(() => {
    setLotArray(props?.auctionData?.data?.eventFeatures[0]?.auctionOptions);
    setAuctionLotData(props?.auctionData?.data?.eventFeatures[0]?.auctionOptions);
  }, [props?.auctionData]);

  const getEventDetailsById = async () => {
    setLoading(true);
    const previewStatus = history?.location?.state?.previewStatus;
    var prevStatus = previewStatus !== undefined ? previewStatus : 0;
    try {
      const { status, data } = await axiosInstance.get(URLS.getPublicEventByid(eventId, prevStatus));
      if (status === 200 || status === 304) {
        setEventOutfit(data?.data?.events);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getAuctionDeatails = async () => {
    setLoading(true);

    const previewStatus = history?.location?.state?.previewStatus;
    var prevStatus = previewStatus !== undefined ? previewStatus : 0;
    var queryString = `?token=${token?.token}&previewStatus=${prevStatus}`;
    const auctionDetails = fetchFromStorage(identifiers.eventDetails);
    // setloader(true);
    try {
      const { data } = await axiosInstance.get(URLS.auctionAll(params?.id, queryString));
      // const { status, data } = await axiosInstance.get(URLS.auctionAll(auctionDetails?.id, queryString));
      setAuctionDetails(data?.data?.eventFeatures[0]?.auctionOptions);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   setLotArray(auctionFeatureData[0]?.auctions);
  // }, [props?.eventFeatures]);

  const onSelect = e => {
    getDonadationData(e.target.value);
  };
  const handleSelect = value => {
    // console.log('auctionLotData', value);
    setSelectedValue(value);
    /* var filterData = auctionLotData;
    if (value === undefined) {
      filterData = auctionLotData;
    } else {
      filterData = auctionLotData.filter(item => item?.categoryId === value);
    }
    setLotArray(filterData); */
    // console.log('auctionLotData', value, filterData);
  };
  const onSearch = value => {
    // console.log('selectedValue', typeof selectedValue);
    var searchData = itemFollowingCardData;
    if (value === '' && (selectedValue === undefined || selectedValue === '')) {
      searchData = auctionLotData;
    } else if (value !== '' && (selectedValue === undefined || selectedValue === '')) {
      searchData = auctionLotData.filter(item =>
        JSON.stringify(item.itemName).toLowerCase().includes(value.toLowerCase()),
      );
    } else if (value !== '' && selectedValue !== undefined) {
      let fileterSearchData = auctionLotData.filter(item => item?.categoryId === selectedValue);
      searchData = fileterSearchData.filter(item =>
        JSON.stringify(item.itemName).toLowerCase().includes(value.toLowerCase()),
      );
    } else if (value === '' && selectedValue !== undefined) {
      /*  searchData = auctionLotData.filter(item =>
        JSON.stringify(item.itemName).toLowerCase().includes(value.toLowerCase()),
      ); */
      searchData = auctionLotData.filter(item => item?.categoryId === selectedValue);
    }
    setLotArray(searchData);
  };
  // console.log('itemFollowingCardData', itemFollowingCardData);
  const getDonadationData = sortBy => {
    // const eventDetails = fetchFromStorage(identifiers.eventDetails);
    setLoading(true);
    axiosInstance
      .get(URLS.filterPublicEventFeature(eventId, sortBy))
      .then(({ status, data }) => {
        if (status === 201 || status === 200) {
          setLoading(false);
          // let itemfollowingid = itemFollowingCardData.filter(
          //   item => item?.id == data?.data?.eventFeatures[0]?.auctionOptions.filter(item => item.id),
          // );
          //
          /*   let getId = data?.data?.eventFeatures[0]?.auctionOptions.filter(
            item => item.id == ,
          ); */
          let result =
            itemFollowingCardData &&
            data?.data?.eventFeatures[0]?.auctionOptions.filter(o1 =>
              itemFollowingCardData.some(o2 => o1.id === o2.id),
            );
          // console.log('auctionLotData', result);
          setLotArray(result);
        }
      })
      .catch(error => {
        setLoading(false);
      });
  };
  return (
    <Col sm="12" className="event-details-auction">
      <Row className="res-org-about ">
        <Col md="8" lg="9">
          <div>
            {props.auctionData?.data?.eventFeatures?.[0]?.featureName && (
              <div className="d-flex align-items-center justify-content-between">
                <h4
                  className="pt-4 mb-3 cursor-pointer"
                  onClick={() => {
                    setIsAbout(!isabout);
                  }}>
                  {props.auctionData?.data?.eventFeatures?.[0]?.featureName}
                </h4>
                <img
                  className={!isabout ? 'closed cursor-pointer' : 'cursor-pointer'}
                  onClick={() => {
                    setIsAbout(!isabout);
                  }}
                  src={images?.uppArraow}
                  alt=""
                  width="15px "
                />
              </div>
            )}
            {props?.eventDetails?.eventAbout != null && (
              <Collapse isOpen={isabout}>
                <p className="fs-16 font-Segoe_UI">
                  {/* {props?.eventDetails?.eventAbout} */}

                  <div>{props.auctionData?.data?.eventFeatures?.[0]?.featureAbout}</div>
                </p>
              </Collapse>
            )}
          </div>
        </Col>
        <Col md="4" lg="3" className="pt-4 mt-3 mb-3">
          <EventTimeDetails
            img={images.watch}
            title={OKTION_VARIABLE?.OKTION_AUCTION_DATE}
            startDate={moment(props.auctionData?.data?.eventFeatures?.[0]?.startTime).format('DD/MM/YYYY hh:mm A')}
            endDate={moment(props.auctionData?.data?.eventFeatures?.[0]?.endTime).format('DD/MM/YYYY hh:mm A')}
            isLocation={false}
          />
        </Col>
      </Row>
      {props.auctionData?.data?.eventFeatures[0]?.auctionOptions?.length > 0 && (
        <div className="">
          <h4 className="pt-4 mb-3">{OKTION_VARIABLE?.OKTION_AUCTION_LOT}</h4>
          <Row>
            <Col md="8" sm="12">
              <SearchBox
                handleSelect={handleSelect}
                onSearch={onSearch}
                eventDetails={props?.eventDetails}
                categoryList={categoryList}
              />
            </Col>
            <Col sm="12">
              <div className="d-flex align-items-center justify-content-end mt-3 mb-2">
                <p className="sort-by fs-16">{OKTION_VARIABLE?.OKTION_SORT_BY}: &nbsp;</p>
                <FormGroup className="mb-0">
                  <Input type="select" name="select" id="exampleSelect" className="cursor-pointer" onChange={onSelect}>
                    {/* <option value="PO">Published Order</option>
                  <option value="RLTOH">Reserve: Low to High</option>
                  <option value="RHTOL">Reserve: High to Low</option>
                  <option value="BLTOH">Bids: Low to High</option>
                  <option value="BHTOL">Bids: High to Low</option>
                  <option value="FF">Featured First</option>
                  <option value="UPF">Unpublished first</option> */}
                    <option value="PO">{OKTION_VARIABLE?.OKTION_PUBLISHED_ORDER}</option>
                    <option value="PVLTOH">{OKTION_VARIABLE?.OKTION_PRICE_LOWTO_HIGH}</option>
                    <option value="PVHTOL">{OKTION_VARIABLE?.OKTION_PRICE_HIGHTO_LOW}</option>
                    <option value="BHTOL">{OKTION_VARIABLE?.OKTION_HOTTEST}</option>
                    <option value="FF">{OKTION_VARIABLE?.OKTION_FEATURE_FIRST}</option>
                  </Input>
                  {/* <Input type="select" name="select" id="exampleSelect" onChange={onSelect} >
                  <option>Price: Low to high</option>
                  <option>Price: High to low</option>
                  <option>Expiring Soon</option>
                </Input> */}
                </FormGroup>
              </div>
            </Col>
            {itemFollowingCardData && itemFollowingCardData?.length > 0 ? (
              itemFollowingCardData?.map((item, index) => (
                <Col sm="12" md="6" lg="4" className="mb-3 mt-3">
                  <ItemFollowingCards
                    isBid={true}
                    index={index}
                    data={item}
                    categoryList={categoryList}
                    eventFeatures={props?.eventFeatures}
                    eventDetailsData={props?.eventDetails}
                    auction_data={props?.auctionData}
                    whitelabel={props?.whitelabel}
                    auctionUpdatedData={props?.auctionUpdatedData}
                    eventOutfit={props?.eventDetails}
                    auctionDetails={auctionDetails?.[index]}
                    isloading={loading}
                    userCards={props?.userCards}
                  />
                </Col>
              ))
            ) : (
              <Col className="mb-2 text-center">
                <h3>{OKTION_VARIABLE?.OKTION_AUCTION_LOTNOT_FOUND}</h3>
              </Col>
            )}
          </Row>
        </div>
      )}
    </Col>
  );
};

const mapStateToProps = ({ userCardsReducer }) => {
  return {
    userCards: userCardsReducer.userCards,
  };
};

export default connect(mapStateToProps, { getUserCards })(Auction);
