import React, { useEffect, useState, useContext } from 'react';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, InputGroupButtonDropdown } from 'reactstrap';
import { images } from '../../../../../../library/common/constants/ImageConstants';
import '../../../../../../library/common/components/Search/styles.scss';
// import { CategoryDropdownData } from '../../../../../ContributorSupplyItems/ContributorSupplyData';
import axiosInstance from '../../../../../../main/axios';
import { URLS } from '../../../../../../library/common/constants/UrlConstants';
import { fetchFromStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { SocketContext } from '../../../../../../main/context/socket';

const SearchBox = ({ onSearch, searchValue, handleSelect, eventDetails, categoryList }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('All Categories');
  const [inputValue, setInputValue] = useState('');
  // const [CategoryDropdownData, setCategoryList] = useState([]);
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  useEffect(() => {
    if (searchValue && searchValue !== '') {
      setInputValue(searchValue);
    }
  }, [searchValue]);

  // useEffect(() => {
  //   var eventDetails = fetchFromStorage(identifiers.eventDetails);
  //   axiosInstance
  //     .get(URLS.getCategoryPublic(eventDetails?.id))
  //     .then(({ status, data }) => {
  //       if (status === 201 || status === 200) {
  //         setCategoryList(data?.data?.categories);
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }, []);

  const handleValueChange = event => {
    setInputValue(event.target.value);
  };

  const handleSearch = () => {
    onSearch(inputValue);
  };

  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

  const handleChange = item => () => {
    setSelectedValue(item?.categoryName || item);
    handleSelect(item?.id);
  };

  // console.log('inputValue', inputValue);
  return (
    <div className="search">
      <div className="form">
        <InputGroup>
          <InputGroupButtonDropdown addonType="append" isOpen={dropdownOpen} toggle={toggleDropDown}>
            <DropdownToggle className="filter-dropdown text-left">
              <span>{selectedValue}</span> <img src={images.down_arrow} alt="" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem key={0} onClick={handleChange('All Categories')}>
                <span className="dropdown-item-name">{OKTION_VARIABLE?.OKTION_ALL_CATEGORY}</span>
              </DropdownItem>
              {categoryList?.map(item => (
                <DropdownItem key={item?.id} onClick={handleChange(item)}>
                  <span className="dropdown-item-name">{item?.categoryName}</span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </InputGroupButtonDropdown>
          <input
            type="text"
            // value={inputValue}
            className="form-control h-60px"
            placeholder={OKTION_VARIABLE?.OKTION_SEARCH_AUCTION_LOT}
            aria-label="Search Auction Items"
            aria-describedby="basic-addon2"
            onChange={handleValueChange}
          />
        </InputGroup>
        <Button
          color="primary"
          className="search-button"
          onClick={handleSearch}
          style={{
            backgroundColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
            borderColor: eventDetails?.primaryColour ? eventDetails?.primaryColour : '',
            color: eventDetails?.primaryTextColour ? eventDetails?.primaryTextColour : '',
          }}>
          {OKTION_VARIABLE?.OKTION_SEARCH}
        </Button>
      </div>
    </div>
  );
};

export default SearchBox;
