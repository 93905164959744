import React from 'react';
import '../../styles.scss';
import { images } from '../../../../../../../../../library/common/constants/ImageConstants';

const TicketSold = ({ totalTicket }) => {
  return (
    <div className="ticketSold">
      <div>
        <img className="mr-3" src={images.auctionWallPriceImg} alt="" />
        {totalTicket?.ticketsSold && `${totalTicket?.ticketsSold} tickets sold`}
      </div>
    </div>
  );
};

export default TicketSold;
