/**
 * Auth Reducer
 */

export const SET_AUTH = 'SET_AUTH';
export const GET_AUTH = 'GET_AUTH';
export const LOGOUT = 'LOGOUT';

export const storeConstants = {
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
  EMAIL_FORGOT_PASSWORD: 'EMAIL_FORGOT_PASSWORD',
  SET_AUTHENTICATION: '@@AUTHENTICATION/SET_AUTHENTICATION',
  GET_ORGANIZATION: '@@ORGANIZATION/GET_ORGANIZATION',
  UPDATE_ORGANIZATION: 'UPDATE_ORGANIZATION',
  GET_EVENT: '@@EVENT/GET_EVENT',
  UPDATE_EVENT: '@@EVENT/UPDATE_EVENT',
  GET_ROLE: '@@ADMIN/GET_ROLE',
  GET_BANK_ACCOUNTS: 'GET_BANK_ACCOUNTS',
  DELETE_BANK_ACCOUNT: 'DELETE_BANK_ACCOUNT',
  CREATE_BANK_ACCOUNT: 'CREATE_BANK_ACCOUNT',
  GET_NOTIFICATION: 'GET_NOTIFICATION',
  ACCEPT_INVITE: 'ACCEPT_INVITE',
  GET_USER_DETAILS: 'GET_USER_DETAILS',
  EDIT_BANK_ACCOUNT: 'EDIT_BANK_ACCOUNT',
  SET_ALL_ORGANIZATION: 'SET_ALL_ORGANIZATION',
  GET_USER_CARDS: 'GET_USER_CARDS',
  CREATE_USER_CARD: 'CREATE_USER_CARD',
  DELETE_USER_CARDS: 'DELETE_USER_CARDS',
  VERIFY_COUPON_CODE: 'VERIFY_COUPON_CODE',
  CART_DETAIL: 'CART_DETAIL',
  UPDATE_USER_CARD: 'UPDATE_USER_CARD',
  UPDATE_DETAIL_USER_CARD: 'UPDATE_DETAIL_USER_CARD',
  SET_LOGIN_USER_DATA: 'SET_LOGIN_USER_DATA',
  ADD_CART: '@@SUBSCRIPTION/ADD_CART',
  FEATURELIST: '@@FEATURELIST/FEATURELIST',
  DASHBOARD: '@@DASHBOARD/GET_DASHBOARD_DATA',
  EVENT_STATS: 'FEATURE/EVENT_STATS',
  FEATURE_RAFFLE: '@@FEATURE/FEATURE_RAFFLE',
  SET_PREVIEWCOLOR: 'SET_PREVIEWCOLOR',
  GET_COUNTRY_DETAILS: 'GET_COUNTRY_DETAILS',
  GET_CURRENCY_LIST: 'GET_CURRENCY_LIST',
  GET_CATEGORY_LIST: 'GET_CATEGORY_LIST',
  SET_CHECKOUT_CARD_DATA: 'SET_CHECKOUT_CARD_DATA',
  SET_SINGLE_CARD_DATA: 'SET_SINGLE_CARD_DATA',
  SET_CARD_DETAILS_BY_ID: 'SET_CARD_DETAILS_BY_ID',
  SET_FLAGFOR_CARD_ADD: 'SET_FLAGFOR_CARD_ADD',
  MOBILE_MENU_STATUS: 'MOBILE_MENU_STATUS',
  SET_MAIL_LIST: 'SET_MAIL_LIST',
  EVENT_MODAL: 'EVENT_MODAL',
  SET_CUSTOM_HEADERS: 'SET_CUSTOM_HEADERS',
  // CREATE_USER_CARD_ERROR: 'CREATE_USER_CARD_ERROR',
};
