import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import './fileUploaderStyles.scss';

const FileUpload = ({ accept, label, multiple, onDrop, text, disabled }) => {
  const handleFileDrop = files => {
    onDrop(
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
  };

  return (
    <Dropzone accept={accept} multiple={multiple} onDrop={handleFileDrop} disabled={disabled}>
      {({ getRootProps, getInputProps }) => (
        <div className="file-uploader">
          {label && <p className="mb-1 inputField-label">{label}</p>}
          <div
            {...getRootProps({
              className: `dropzone d-flex justify-content-center align-items-center mx-auto ${
                disabled ? 'cursor-default' : ''
              }`,
              // onDrop: event => event.stopPropagation(),
            })}>
            <input {...getInputProps()} />
            <p>{text}</p>
          </div>
        </div>
      )}
    </Dropzone>
  );
};

FileUpload.propTypes = {
  accept: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  // ref: PropTypes.string,
  multiple: PropTypes.bool,
  onDrop: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {
  accept: '',
  label: '',
  multiple: false,
  text: '',
  // ref: '',
};

export default FileUpload;
