import Axios from 'axios/index';
import { errorHandler, fetchFromStorage } from '../../utility';
import { identifiers } from '../../library/common/constants/IdentifierConstants';
import headerResponseEventEmitter from 'library/utilities/headerResponse';
import Config from '../../utility/Config';

const axiosInstance = Axios.create({
  baseURL: Config.BaseURL,
  headers: {
    'Content-Type': 'application/json',
    'platform-type': 'web',
    'oktion-connect-version': Config?.version,
    'api-connect-version': Config?.apiVersion,
  },
});

axiosInstance.interceptors.request.use(config => {
  const token = fetchFromStorage(identifiers.token);
  const clonedConfig = config;

  if (token) {
    clonedConfig.headers.common = {
      Authorization: `Bearer ${token.token}`,
      'Content-Type': 'application/json',
    };
  }

  return clonedConfig;
});

axiosInstance.interceptors.response.use(
  response => {
    updateHeaderResponse(response);
    return response;
  },
  error => {
    updateHeaderResponse(error.response);
    errorHandler(error);
    return Promise.reject(error);
  },
  config => {
    return config;
  },
);

const updateHeaderResponse = response => {
  const version = response?.headers?.['oktion-connect-version'];
  const maintenance = response?.headers?.['oktion-maintenance-mode'];
  const maintenanceType = response?.headers?.['oktion-maintenance-mode-type'];
  headerResponseEventEmitter.emit('headerResponseUpdated', version);
  if (maintenance) {
    const maintenanceData = {
      maintenance: maintenance,
      maintenanceType: maintenanceType,
    };
    headerResponseEventEmitter.emit('maintenanceMode', maintenanceData);
  }
  headerResponseEventEmitter.emit('oktionCrash', response);
};

export default axiosInstance;
