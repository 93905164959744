import React, { useContext } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
// import { images } from '../../../../library/common/constants/ImageConstants';
import { SocketContext } from '../../../../main/context/socket';

const RemoveOrganizationModal = ({ isToggle, toggle, handleClick, orgSocketData }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  return (
    <div>
      <Modal size="xl" className="modal-dialog add-price-modal logout-modal" isOpen={isToggle}>
        <div className="text-right">
          {/* <img className="cursor-pointer" onClick={toggle} src={images.close} alt="" /> */}
        </div>
        <ModalBody>
          <div>{/* <img src={images.giftImage} alt="" /> */}</div>
          <p>{orgSocketData?.data?.message}</p>
          <div className=" d-flex justfycon justify-content-center">
            <Button style={{ width: '100px' }} className="app-button main-button mt-2 mr-2 h-45" onClick={handleClick}>
              {OKTION_VARIABLE?.OKTION_OK}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RemoveOrganizationModal;
