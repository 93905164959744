import * as React from 'react';
import { isEmail, ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';

class MultiValueSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: this.props.defaultTags ? this.props.defaultTags : [],
    };
  }

  componentDidUpdate(prevProps) {
    // if (this.state.emails !== prevProps.defaultTags) {
    if (this.props.defaultTags !== prevProps.defaultTags) {
      // this.setState({ emails: prevProps.defaultTags });
      this.setState({ emails: this.props.defaultTags });
    }
  }

  componentDidMount() {
    // console.log(this.props.defaultTags);
  }

  handleOnChange = _emails => {
    this.setState({ emails: _emails });
    if (this.props.handleChange) {
      this.props.handleChange(_emails);
    }
  };

  render() {
    const { emails } = this.state;
    return (
      <ReactMultiEmail
        placeholder={this.props.placeholder}
        emails={emails}
        validateEmail={email => {
          return this.props.isEmail ? isEmail(email) : email;
        }}
        onChange={email => {
          this.handleOnChange(email);
        }}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              {email}
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
    );
  }
}

export default MultiValueSelect;
