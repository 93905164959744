import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import PageLoader from 'library/common/components/PageLoader';
import { getBankAccounts, createBankAccount } from 'library/common/actions/BankAccountsAction';
import '../../../UserProfile/Frame/CardDetails/styles.scss';
import './styles.scss';
import 'react-flags-select/css/react-flags-select.css';
import BankingDetailsItem from './BankingDetailsItem';
import { URLS } from 'library/common/constants/UrlConstants';
import axiosInstance from '../../../../main/axios';
import { fetchFromStorage } from '../../../../utility';
import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
// import ActionMessage from '../../../../library/common/components/ActionMessage';
import { setApiMessage } from '../../../../library/common/constants/function';
// import { EventEmitter } from '../../../../library/common/constants/event';
import EventEmitter from 'reactjs-eventemitter';
import { SocketContext } from '../../../../main/context/socket';

const useForceUpdate = () => {
  let [value, setState] = useState(true);
  return () => setState(!value);
};
const BankingDetails = props => {
  const [isAddNew, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [is_view, setIsView] = useState('');
  const [bankList, setBankList] = useState([]);
  // const [message, setMessage] = useState({
  //   display: false,
  //   type: '',
  //   message: '',
  // });
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  useEffect(() => {
    const is_view = fetchFromStorage(identifiers.is_view);
    setIsView(is_view);

    EventEmitter.subscribe('updateBankaccountlist', event => {
      GetBankAccountApi();
    });
    EventEmitter.subscribe('setActionMessageShow', set_ActionMessage);
    EventEmitter.subscribe('setActionMessageHide', setActionMessageHide);
  }, []);

  const ToggleForm = () => {
    setToggle(!isAddNew);
  };

  useEffect(() => {
    GetBankAccountApi();
    GetCurrencyApi();
  }, []);
  const createBankAccountAPI = async params => {
    await props.createBankAccount(_.get(props, 'orgId'), params);
    setToggle(false);
  };
  // console.log('apiStatusapiStatus', props.apiStatus);
  // console.log('apiStatus bankAccountsList', props.bankAccountsList);

  const GetCurrencyApi = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.getCurrency());
      if (status === 200) {
        setCurrency(data.data.currencies);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const GetBankAccountApi = async () => {
    const org_id = fetchFromStorage(identifiers.currentOrganization);
    setLoading(true);
    try {
      props.getBankAccounts(_.get(props, 'orgId'));
      const { status, data } = await axiosInstance.get(URLS.orgBankAccounts(org_id));
      if (status === 201) {
        setLoading(false);
        setBankList(data?.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
    forceUpdate();
  };
  // const setActionMessage = (display = false, type = '', message = '') => {
  //   setMessage({
  //     display,
  //     type,
  //     message,
  //   });
  // };

  const forceUpdate = useForceUpdate();
  const set_ActionMessage = () => {
    setApiMessage('success', OKTION_VARIABLE?.OKTION_BANK_DETAILS_DELETE);
    // setActionMessage(true, 'Success', 'Banking details deleted successfully');
  };
  const setActionMessageHide = () => {
    // setActionMessage(false);
  };
  // EventEmitter.subscribe('setActionMessageShow', set_ActionMessage);
  // EventEmitter.subscribe('setActionMessageHide', setActionMessageHide);

  return (
    <div className="transfer-ownership card-details bankDetails">
      {loading && <PageLoader />}
      <h1 className="text-center text-lg-left mb-2 mb-md-5 mt-4 page-title">
        {OKTION_VARIABLE?.OKTION_BANKING_DETAILS}
      </h1>
      {/* {message.display && (
        <div className="btn-block">
          <ActionMessage message={message.message} type={message.type} styleClass={message.styleClass} />
        </div>
      )} */}
      <Row className="mb-3">
        {_.get(props, 'bankAccountsList', []).map((item, i) => (
          <Col sm="6" className="mb-3" key={'bankAcc' + i}>
            <BankingDetailsItem
              item={item}
              currency={currency}
              // setActionMessage={setActionMessage}
              toggleModal={props.toggleModal}
              getBankAccountApi={GetBankAccountApi}
              toggleEvent={props.toggleEvent}
            />
          </Col>
        ))}
        {/*   {bankList?.data?.map((item, index) => (
          <Col sm="6" className="mb-3" key={'bankAcc' + index}>
            <BankingDetailsItem item={item} currency={currency} />
          </Col>
        ))} */}
        {/* {console.log('bankList', bankList)} */}
        {isAddNew ? (
          <Col sm="6 ">
            <BankingDetailsItem
              ToggleForm={ToggleForm}
              createBankAccountAPI={createBankAccountAPI}
              CreateNew={true}
              currency={currency}
              is_view={is_view}
              // setActionMessage={setActionMessage}
              toggleModal={props.toggleModal}
              getBankAccountApi={GetBankAccountApi}
              toggleEvent={props.toggleEvent}
              // addNew={true}
            />
          </Col>
        ) : (
          <Col sm="6 mb-3">
            <div
              className={is_view ? 'is-view dottedBox cursor-pointer h-100' : 'dottedBox cursor-pointer h-100'}
              onClick={ToggleForm}>
              {OKTION_VARIABLE?.OKTION_ADD_BANK}
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

// const mapStateToProps = ({ BankAccountsReducer, eventReducer, authReducer }) => {
//   return {
//     bankAccountsList: BankAccountsReducer.bankAccounts,
//     eventReducer: eventReducer.eventByIdData,
//     loginUserData: authReducer.loginUserData,
//   };
// };

// export default connect(mapStateToProps, { getBankAccounts, getEventById, updateEvent })(CreateEvent);

const mapStateToProps = ({ BankAccountsReducer }) => {
  return {
    bankAccountsList: BankAccountsReducer.bankAccounts,
    api_Status: BankAccountsReducer.apiStatus,
  };
};

export default connect(mapStateToProps, { getBankAccounts, createBankAccount })(BankingDetails);
