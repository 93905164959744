// import { EventEmitter } from 'library/common/constants/event';
// import socketIO from 'socket.io-client';
// import Config from '../../utility/Config';
import EventEmitter from 'reactjs-eventemitter';

// let socket = null;

// export function connectSocket(socketPath) {
//   socket = socketIO(Config.BaseURL, {
//     jsonp: false,
//     path: socketPath,
//     upgrade: false,
//     reconnection: true,
//   });
//   return socket.connect();
// }
export function initSocketEvents(userSocket) {
  let socket = userSocket;

  socket.on('JoinSocket', data => {
    // console.log('===join socket===' + JSON.stringify(data));
  });
  socket.on('AuctionWall', data => {
    // console.log('===AuctionWall===', JSON.stringify(data, null, 1));
    EventEmitter.dispatch('SocketAuctionWallDataUpdated', data);
  });
  socket.on('currentBid', data => {
    // console.log('===currentBid socket===' + JSON.stringify(data, null, 1));
    EventEmitter.dispatch('socketUpdateBiddingData', data);
  });
  socket.on('EventDetail', data => {
    // console.log('===eventDetails socket===' + JSON.stringify(data, null, 1));
    EventEmitter.dispatch('socketUpdateEventDetailsData', data);
  });
  socket.on('EventDashboard', data => {
    // console.log('===EventDashboard socket===' + JSON.stringify(data, null, 1));
    EventEmitter.dispatch('socketUpdateEventDashboardData', data);
  });
  socket.on('FeatureDashboard', data => {
    // console.log('===FeatureDashboard socket===' + JSON.stringify(data, null, 1));
    EventEmitter.dispatch('socketUpdateFeatureDashboardData', data);
  });
  socket.on('disconnect', () => {
    // console.log('===disconnect socket===');
    EventEmitter.dispatch('socketConnect');
  });
  socket.on('LeftSocket', () => {
    // console.log('===Left Socket ===');
    EventEmitter.dispatch('socketConnect');
  });
  socket.on('add-message', data => {
    // console.log('===add-message socket===' + JSON.stringify(data, null, 1));
    EventEmitter.dispatch('socketUpdateMessage', data);
  });
  socket.on('mail-count', data => {
    console.log('===socketUpdateCount===', data);
    EventEmitter.dispatch('socketUpdateCount', data);
  });
  // socket.on('RemovedFromOrganisation', data => {
  // console.log('RemovedFromOrganisation', data);
  //   EventEmitter.dispatch('RemovedFromOrganisation', data);
  // })
}
