import React from 'react';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/lib/Col';
import '../../styles.scss';
import Slider from 'react-slick';
import { identifiers } from '../../../../../../../../../library/common/constants/IdentifierConstants';
var CurrencyFormat = require('react-currency-format');
class AuctionWallCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 4,
    };
  }
  componentDidMount() {
    /*     setInterval(() => {
      this.setState({ show: this.state.show + 4 });
    }, 3000); */
  }
  render() {
    const { feturesData, currencyAbbreviation, currencySymbol } = this.props;
    let withOutfeaturedItem =
      feturesData &&
      feturesData?.item &&
      feturesData?.item.length > 0 &&
      feturesData?.item.filter(item => item?.featuredItem === false);
    let withfeaturedItem =
      feturesData &&
      feturesData?.item &&
      feturesData?.item.length > 0 &&
      feturesData?.item.filter(item => item?.featuredItem === true);
    var settings = {
      responsive: [
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            rows: withOutfeaturedItem && withOutfeaturedItem?.length === 2 ? 1 : 4,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        <div className="auctionWallCard">
          {feturesData && feturesData?.item.length > 0 ? (
            <div>
              <Slider
                dots={false}
                infinite={true}
                speed={0}
                autoplay={true}
                autoplaySpeed={15000}
                swipe={false}
                touchMove={false}
                arrows={false}
                pauseOnHover={false}
                className={`${
                  withfeaturedItem && withfeaturedItem?.length > 0 && withfeaturedItem?.length !== 0 ? 'card-row' : ''
                }`}>
                {withfeaturedItem &&
                  withfeaturedItem?.length > 0 &&
                  withfeaturedItem?.map((item, i) => (
                    <div>
                      <div className="d-flex inner-card-block">
                        <Col sm="4" className="p-0">
                          <div className="big-card-img">
                            <img src={identifiers.imageUrlConstant + item?.images[0]?.name} alt="" />
                          </div>
                        </Col>
                        <Col sm="8" className="p-0">
                          <div className="card-content-big">
                            <h1 className="fw-500">{item?.itemName}</h1>
                            <h4 className="font-weight-normal mb-4">
                              {item?.providedBy && item?.providedBy === 'sponsoredBy'
                                ? 'Sponsored by'
                                : item?.providedBy === 'donatedBy'
                                ? 'Donated by'
                                : 'Provided by'}{' '}
                              : {item?.itemProvidedByName}
                            </h4>
                            <h4 className="font-weight-normal">Current Bid : </h4>
                            <h1 className="mb-2">
                              <CurrencyFormat
                                value={
                                  item?.isSold === true && item?.markAsPaid === true
                                    ? item?.buyItNow !== null
                                      ? item?.buyItNow
                                      : item?.currentBid !== null
                                      ? item?.currentBid
                                      : 0
                                    : item?.currentBid !== null
                                    ? item?.currentBid
                                    : 0
                                }
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={`${currencyAbbreviation}  ${currencySymbol}`}
                                // thousandSpacing="2s"
                                thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                                decimalScale="2"
                                fixedDecimalScale={true}
                                className="fs-60"
                              />{' '}
                            </h1>{' '}
                          </div>
                        </Col>
                      </div>
                    </div>
                  ))}{' '}
              </Slider>
            </div>
          ) : (
            <h1>No items available</h1>
          )}
        </div>
        <div className="auctionSmallWallCard">
          <Slider
            dots={false}
            infinite={true}
            speed={0}
            autoplay={true}
            autoplaySpeed={10000}
            swipe={false}
            slidesToShow={2}
            touchMove={false}
            arrows={false}
            slidesToScroll={2}
            // rows={2}
            rows={`${withOutfeaturedItem && withOutfeaturedItem?.length === 2 ? 1 : 2}`}
            pauseOnHover={false}
            {...settings}>
            {withOutfeaturedItem &&
              withOutfeaturedItem?.length > 0 &&
              withOutfeaturedItem.map((item, i) => (
                /* <Row className=""> */
                <Col sm="12">
                  <Row key={i} className="mb-3 box mr-0 ml-0">
                    <Col sm="4" className="p-0">
                      <div className="small-card-img">
                        <img src={identifiers.imageUrlConstant + item.images[0].name} alt="" />
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="card-content">
                        <h4 className="fw-500">{item?.itemName}</h4>
                        <h6 className="font-weight-normal mb-4">
                          {item?.providedBy && item?.providedBy === 'sponsoredBy'
                            ? 'Sponsored by'
                            : item?.providedBy === 'donatedBy'
                            ? 'Donated by'
                            : 'Provided by'}{' '}
                          : {item?.itemProvidedByName}
                        </h6>
                        <h6 className="font-weight-normal">Current Bid : </h6>
                        <h4 className="mb-2 currency">
                          <CurrencyFormat
                            value={
                              item?.isSold === true && item?.markAsPaid === true
                                ? item?.buyItNow !== null
                                  ? item?.buyItNow
                                  : item?.currentBid !== null
                                  ? item?.currentBid
                                  : 0
                                : item?.currentBid !== null
                                ? item?.currentBid
                                : 0
                            }
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={`${currencyAbbreviation}  ${currencySymbol}`}
                            // thousandSpacing="2s"
                            thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                            decimalScale="2"
                            fixedDecimalScale={true}
                            className="fs-25"
                          />
                        </h4>
                      </div>
                    </Col>
                  </Row>
                </Col>
                /* </Row> */
              ))}{' '}
          </Slider>
        </div>
        {/* <TicketSold /> */}
      </>
    );
  }
}

export default AuctionWallCard;
