/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useContext } from 'react';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import { Collapse, FormGroup, Input, Label } from 'reactstrap';
import { images } from '../../../../library/common/constants/ImageConstants';
import _ from 'lodash';
import { cc_cvv_format, cc_expires_format, cc_format } from 'library/utilities/Validators';
import PageLoader from '../../../../library/common/components/PageLoader';
import axiosInstance from '../../../../main/axios';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import { setApiMessage } from '../../../../library/common/constants/function';
import { SocketContext } from 'main/context/socket';
import Notice from 'library/common/components/Notice';
import VerificationModal from '../Verification/VerificationModal';
import { useHistory } from 'react-router-dom';
import { fetchFromStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import PopOver from 'library/common/components/PopOver/PopOver';
import { createDraftOrg } from 'modules/Organization/menu-items';
import '../Verification/styles.scss';
import './styles.scss';

const CardDetails = () => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const history = useHistory();
  const [isAddNew, setToggle] = useState(false);
  const [cardError, serCardError] = useState({ cvc: false, accountNumber: false, expiry: false, verifyCharge: false });
  const [inputValues, setInputValues] = useState({
    cardNumber: '',
    expiry: '',
    cvc: '',
    verifyCharge: '',
  });
  const [loading, setLoading] = useState(false);
  const [toggleDeleteModal, setTogglepDeleteModal] = useState(false);
  const [removeCardId, setRemoveCardId] = useState('');
  const [toggleVerifyModal, setToggleVerifyModal] = useState(false);
  const [verifiedStatus, setVerifiedStatus] = useState('');
  const [isVerify, setIsVerify] = useState(false);
  const [isLastCard, setIsLastCard] = useState(false);
  const [orgCards, setOrgCards] = useState([]);
  const [verifiedDeleteToggle, setVerifiedDeleteToggle] = useState(false);
  const [unableVerifyToggle, setUnableVerifyToggle] = useState(false);
  const [isVerificationToggle, setIsVerificationToggle] = useState(true);
  const org_id = fetchFromStorage(identifiers.currentOrganization);
  const isNewOrg = window.location.pathname === '/organization/organiser-details';

  // GET CARD LIST
  const getCardListData = async () => {
    const currentOrgId = fetchFromStorage(identifiers.currentOrganization);
    try {
      setLoading(true);
      const { status, data } = await axiosInstance.get(URLS.orgCardListURL + `?organisationId=${currentOrgId}`);
      if (status === 200 || status === 201) {
        setOrgCards(data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.msg) {
        setApiMessage('error', error?.response?.data?.msg);
      }
    }
  };
  useEffect(() => {
    if (!isNewOrg) {
      getCardListData();
    }
  }, []);

  // HANDLE CHANGE
  const handleChange = inputField => event => {
    const { value, id } = event.target;
    let inputValue = value;

    if (inputField === 'cardNumber') {
      serCardError({ ...cardError, accountNumber: false });
      inputValue = cc_format(value);
    } else if (inputField === 'expiry') {
      serCardError({ ...cardError, expiry: false });
      inputValue = cc_expires_format(value);
    } else if (inputField === 'cvc') {
      serCardError({ ...cardError, cvc: false });
      inputValue = cc_cvv_format(value);
    } else if (inputField === id) {
      serCardError({ ...cardError, [`${id}`]: false });
      inputValue = value;
    }
    setInputValues({
      ...inputValues,
      [inputField]: inputValue,
    });
  };

  // ADD NEW CARD AND ORG.
  const handleAddCardAndOrg = async () => {
    if (isNewOrg) {
      let { status } = await createDraftOrg();
      if (status) {
        setTimeout(() => {
          handleAddCard();
        }, 1000);
      }
    } else {
      handleAddCard();
    }
  };
  // ADD CARD
  const handleAddCard = () => {
    const currentOrgId = fetchFromStorage(identifiers.currentOrganization);

    let ErrorMessages = {};
    if (inputValues.cardNumber === '') {
      ErrorMessages = { ...ErrorMessages, accountNumber: OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE };
    }
    if (inputValues.expiry === '') {
      ErrorMessages = { ...ErrorMessages, expiry: OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE };
    }
    if (inputValues.cvc === '') {
      ErrorMessages = { ...ErrorMessages, cvc: OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE };
    }

    serCardError(ErrorMessages);
    const expiryMonth = _.chain(inputValues.expiry).split('/').get('[0]').value();
    const expiryYear = _.chain(inputValues.expiry).split('/').get('[1]').value();
    const cardValues = {
      organisationId: currentOrgId,
      cardNumber: _.get(inputValues, 'cardNumber'),
      expiryMonth,
      expiryYear,
      cvc: _.get(inputValues, 'cvc'),
      // isDefault: props?.userCards.length === 0 ? true : inputValues?.isDefault,
      // isSavedForFuture: true,
    };
    if (inputValues.cardNumber !== '' && inputValues.cvc !== '' && inputValues.expiry !== '') {
      addCardData(cardValues);
    }
  };
  const addCardData = async cardValues => {
    try {
      setLoading(true);
      const { status, data } = await axiosInstance.post(URLS.createCardURL, cardValues);
      if (status === 200 || status === 201) {
        setInputValues({
          cardNumber: '',
          expiry: '',
          cvc: '',
          isDefault: false,
          saveForFuture: '',
          verifyCharge: '',
        });
        setToggle(false);
        getCardListData();
        setLoading(false);
        setToggleVerifyModal(!toggleVerifyModal);
        setApiMessage('success', data?.msg);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errCode === 'TRY_AGAIN') {
        setToggleVerifyModal(true);
        setUnableVerifyToggle(true);
        setRemoveCardId(error?.response?.data?.data?.cardId);
      } else if (error?.response?.data?.msg) {
        setApiMessage('error', error?.response?.data?.msg);
      } else {
        setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);
      }
    }
  };

  // DELETE CARD
  const handleCardDeleteToggle = id => {
    if (orgCards?.length === 1) {
      const isVerifiedCard = orgCards?.filter(obj => obj?.status === 'Verified' && obj?.cardId === id);
      if (isVerifiedCard?.length === 1) {
        setVerifiedDeleteToggle(false);
        setIsLastCard(!isLastCard);
      }
    }
    setRemoveCardId(id);
    setTogglepDeleteModal(true);
  };
  const handleCardDelete = async () => {
    if (removeCardId) {
      const sendData = {
        cardId: removeCardId,
        organisationId: org_id,
      };
      try {
        setLoading(true);
        const { status, data } = await axiosInstance.post(URLS.deleteCardURL, sendData);
        if (status === 200) {
          setApiMessage('success', data?.msg);
          getCardListData();
          setLoading(false);
          setTogglepDeleteModal(false);
          setToggleVerifyModal(false);
          setUnableVerifyToggle(false);
          setRemoveCardId('');
        }
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.msg) {
          setApiMessage('error', error?.response?.data?.msg);
        } else {
          setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);
        }
      }
    } else {
      ToggleForm();
    }
  };

  const ToggleForm = () => {
    setToggle(!isAddNew);
    setInputValues({
      cardNumber: '',
      expiry: '',
      cvc: '',
      isDefault: false,
      verifyCharge: '',
    });
    serCardError({ accountNumber: false, cvc: false, expiry: false, verifyCharge: false });
    setTogglepDeleteModal(false);
    setIsLastCard(false);
  };

  // CARD VERIFICATION PROGRESS POPUP CONTENT
  const cardVerificationModalContent = () => {
    return (
      <div>
        <p className="mb-4">{OKTION_VARIABLE?.OKTION_CARD_VERIFICATION_INPROGRESS_DESC_1}</p>
        <p className="mb-4">{OKTION_VARIABLE?.OKTION_CARD_VERIFICATION_INPROGRESS_DESC_2}</p>
        <div>
          <p className="fw-600">{OKTION_VARIABLE?.OKTION_PLEASE_NOTE}:</p>
          <ul className="ml-4">
            <li>
              <p>{OKTION_VARIABLE?.OKTION_VERIFY_NOTE_1}</p>
            </li>
            <li>
              <p>{OKTION_VARIABLE?.OKTION_VERIFY_NOTE_2}</p>
            </li>
            <li>
              <p>{OKTION_VARIABLE?.OKTION_VERIFY_NOTE_3}</p>
            </li>
            <li>
              <p>{OKTION_VARIABLE?.OKTION_VERIFY_NOTE_4}</p>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  // HANDLE VERIFIED
  const handleVefify = async id => {
    if (inputValues?.[`verifyCharge-${id}`] === '' || inputValues?.[`verifyCharge-${id}`] === undefined) {
      serCardError({ ...cardError, [`verifyCharge-${id}`]: OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE });
    }

    if (Number(inputValues?.[`verifyCharge-${id}`])) {
      const sendData = {
        verificationAmount: Number(inputValues?.[`verifyCharge-${id}`]),
        cardId: id,
        organisationId: org_id,
      };
      try {
        setLoading(true);
        const { status, data } = await axiosInstance.post(URLS.verifyCardURL, sendData);
        if (status === 200) {
          setApiMessage('success', data?.msg);
          setLoading(false);
          getCardListData();
          handleVerifyComplete();
        }
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.msg) {
          setApiMessage('error', error?.response?.data?.msg);
        } else {
          setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);
        }
      }
    }
  };
  const handleVerifyComplete = () => {
    setVerifiedStatus(false);
    setIsVerify(!isVerify);
    setTogglepDeleteModal(true);
  };

  const handleContinue = () => {
    setIsVerify(!isVerify);
    setTogglepDeleteModal(false);
  };

  const handleRedirectEventPage = () => {
    history.push(`/manage-event/${org_id}`);
  };

  // MAKE PRIMARY
  const handleMakePrimary = async id => {
    const sendData = {
      cardId: id,
      isDefault: true,
      organisationId: org_id,
    };
    try {
      setLoading(true);
      const { status, data } = await axiosInstance.post(URLS.updateCardURL, sendData);
      if (status === 200) {
        setApiMessage('success', data?.msg);
        setLoading(false);
        getCardListData();
        setTogglepDeleteModal(false);
        setRemoveCardId('');
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.msg) {
        setApiMessage('error', error?.response?.data?.msg);
      } else {
        setApiMessage('error', OKTION_VARIABLE?.SOMETHING_WENT_WRONG);
      }
    }
  };

  const handlefocus = e => {
    var inputElement = document.getElementById(e.target.id);
    if (inputElement) {
      inputElement.removeAttribute('placeholder');
    }
  };
  const handleBlurPlaceholder = (e, place_holder) => {
    var inputElement = document.getElementById(e.target.id);
    if (inputElement) {
      inputElement.setAttribute('placeholder', place_holder);
    }
  };

  const cardFields = card => {
    const isVerified = card && card?.status !== 'Verified';

    return (
      <Col lg="6" md="7" className="mb-3 cardBlock">
        <div className="cardInfo">
          <FormGroup
            className={`focus_field mb-0 ${
              (isVerified ? inputValues?.[`cardNumber-${card?.cardId}`] : inputValues.cardNumber)
                ? 'fixed-fieldset'
                : ''
            }`}>
            <Input
              type="text"
              value={isVerified ? `*****${card?.card?.last4}` : inputValues.cardNumber}
              placeholder={OKTION_VARIABLE?.OKTION_CARD_NUMBER}
              onChange={handleChange('cardNumber')}
              id={isVerified ? `cardNumber-${card?.cardId}` : 'cardNumber'}
              onFocus={handlefocus}
              onBlur={e => handleBlurPlaceholder(e, OKTION_VARIABLE?.OKTION_CARD_NUMBER)}
              disabled={isVerified}
            />
            <label className="label-helper">{OKTION_VARIABLE?.OKTION_CARD_NUMBER}</label>
            {cardError.accountNumber && <div className="text-danger">{OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE}</div>}
          </FormGroup>
          <div className="row">
            <div className="col-sm-6">
              <FormGroup
                className={`focus_field mb-1 mt-2 ${
                  (isVerified ? inputValues?.[`expiry-${card?.cardId}`] : inputValues.expiry) ? 'fixed-fieldset' : ''
                }`}>
                <Input
                  type="text"
                  value={isVerified ? `${card?.card?.expMonth}/${card?.card?.expYear}` : inputValues.expiry}
                  placeholder={OKTION_VARIABLE?.OKTION_CARD_EXPIRY_DATE}
                  onChange={handleChange('expiry')}
                  id={isVerified ? `expiry-${card?.cardId}` : 'expiry'}
                  onFocus={handlefocus}
                  onBlur={e => handleBlurPlaceholder(e, OKTION_VARIABLE?.OKTION_CARD_EXPIRY_DATE)}
                  disabled={isVerified}
                />
                <label className="label-helper">{OKTION_VARIABLE?.OKTION_CARD_EXPIRY_DATE}</label>
                {cardError.expiry && <div className="text-danger">{OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE}</div>}
              </FormGroup>
            </div>
            <div className="col-sm-6">
              <FormGroup
                className={`focus_field mb-1 mt-2 ${
                  (isVerified ? inputValues?.[`cvc-${card?.cardId}`] : inputValues.cvc) ? 'fixed-fieldset' : ''
                }`}>
                <Input
                  type="password"
                  maxlength="3"
                  value={isVerified ? '***' : inputValues.cvc}
                  placeholder={OKTION_VARIABLE?.OKTION_CARD_CVC}
                  onChange={handleChange('cvc')}
                  id={isVerified ? `cvc-${card?.cardId}` : 'cvc'}
                  onFocus={handlefocus}
                  onBlur={e => handleBlurPlaceholder(e, OKTION_VARIABLE?.OKTION_CARD_CVC)}
                  disabled={isVerified}
                />
                <Label className="label-helper">{OKTION_VARIABLE?.OKTION_CARD_CVC}</Label>
                {cardError.cvc && <div className="text-danger">{OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE}</div>}
              </FormGroup>
            </div>
            <div className="col-sm-12">
              {cardError?.errors && <div className="text-danger">{cardError?.errors}</div>}
            </div>
            {isVerified && (
              <Col sm="12" className="mt-2 d-flex align-items-baseline">
                <FormGroup
                  className={`focus_field mb-0 w-100 ${
                    (isVerified ? inputValues?.[`verifyCharge-${card?.cardId}`] : inputValues.verifyCharge)
                      ? 'fixed-fieldset'
                      : ''
                  }`}>
                  <Input
                    type="text"
                    value={isVerified ? inputValues?.[`verifyCharge-${card?.cardId}`] : inputValues.verifyCharge}
                    placeholder={OKTION_VARIABLE?.OKTION_VERIFICATION_CHARGE}
                    onChange={handleChange(isVerified ? `verifyCharge-${card?.cardId}` : 'verifyCharge')}
                    id={isVerified ? `verifyCharge-${card?.cardId}` : 'verifyCharge'}
                    onFocus={handlefocus}
                    onBlur={e => handleBlurPlaceholder(e, OKTION_VARIABLE?.OKTION_VERIFICATION_CHARGE)}
                  />
                  <label className="label-helper">{OKTION_VARIABLE?.OKTION_VERIFICATION_CHARGE}</label>
                  {(isVerified ? cardError?.[`verifyCharge-${card?.cardId}`] : cardError.verifyCharge) && (
                    <div className="text-danger">{OKTION_VARIABLE?.OKTION_REQUIRED_MESSAGE}</div>
                  )}
                </FormGroup>
                <PopOver
                  title={OKTION_VARIABLE?.OKTION_VERIFICATION_CHARGE}
                  description={cardVerificationChargesPopover()}
                  target="visibility"
                />
              </Col>
            )}
            <Col sm="12" className="pl-1 pr-1 pt-1">
              <div className="d-flex justify-content-between align-items-center border-top pt-2 pr-2">
                {isVerified ? (
                  <span
                    className={`verification-status-value ${
                      card?.isVerified === 'Pending'
                        ? 'status-pending'
                        : card?.isVerified === 'Verified'
                        ? 'status-verified'
                        : card?.isVerified === 'Declined'
                        ? 'status-declined'
                        : card?.isVerified === 'Expired'
                        ? 'status-expired'
                        : 'status-unverified'
                    }`}>
                    {card?.isVerified ? card?.isVerified : 'Unverified'}
                  </span>
                ) : (
                  <div></div>
                )}

                <div>
                  <button
                    className="black-button fs-12 fw-500 btn"
                    onClick={() => {
                      if (isVerified) {
                        setVerifiedStatus(isVerified);
                        handleCardDeleteToggle(card?.card?.id);
                      } else {
                        if (inputValues.cardNumber !== '' && inputValues.cvc !== '' && inputValues.expiry !== '') {
                          handleCardDeleteToggle();
                        } else {
                          ToggleForm();
                        }
                      }
                    }}>
                    {OKTION_VARIABLE?.OKTION_FEATURE_DELETE}
                  </button>
                  {!isVerified &&
                    inputValues.cardNumber !== '' &&
                    inputValues.cvc !== '' &&
                    inputValues.expiry !== '' && (
                      <button className="main-button ml-2 fs-12 fw-500 btn" onClick={() => handleAddCardAndOrg()}>
                        {OKTION_VARIABLE?.OKTION_SUBMIT_FOR_VERIFICATION}
                      </button>
                    )}
                  {isVerified && (
                    <button className="main-button ml-2 fs-12 fw-500 btn" onClick={() => handleVefify(card?.card?.id)}>
                      {OKTION_VARIABLE?.OKTION_VERIFY}
                    </button>
                  )}
                </div>
              </div>
            </Col>
          </div>
        </div>
      </Col>
    );
  };
  // CARD VERIFICATION PROGRESS POPUP CONTENT
  const cardVerificationChargesPopover = () => {
    return (
      <div>
        <p className="mb-4">{OKTION_VARIABLE?.OKTION_CARD_POPUP_LINE_1}</p>
        <div>
          <p className="">{OKTION_VARIABLE?.OKTION_CARD_POPUP_LINE_2}</p>
        </div>
      </div>
    );
  };

  // CARD VERIFICATION PROGRESS POPUP CONTENT
  const cardVerificationNoteContent = () => {
    return (
      <div>
        <p className="mt-4 mb-4 card-notes-sub-text">
          {OKTION_VARIABLE?.OKTION_MANDATORY_CARD_OWNERSHIP_VERIFICATION_DESC_1}
        </p>
        <div>
          <p className="fw-600">{OKTION_VARIABLE?.OKTION_PLEASE_NOTE}:</p>
          <ul className="ml-4">
            <li>
              <p className="card-notes-sub-text">{OKTION_VARIABLE?.OKTION_VERIFY_NOTE_1}</p>
            </li>
            <li>
              <p className="card-notes-sub-text">{OKTION_VARIABLE?.OKTION_VERIFY_NOTE_2}</p>
            </li>
            <li>
              <p className="card-notes-sub-text">{OKTION_VARIABLE?.OKTION_VERIFY_NOTE_3}</p>
            </li>
            <li>
              <p className="card-notes-sub-text">{OKTION_VARIABLE?.OKTION_VERIFY_NOTE_4}</p>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div className="card-details pl-3">
      {loading && <PageLoader />}
      <Row className="align-items-center justify-content-lg-between mt-4 mb-4 page-title-block">
        <Col md="12">
          <h1 className="mb-3 text-center page-title text-lg-left">{OKTION_VARIABLE?.OKTION_CARD_DETAILS}</h1>
        </Col>
        <Col md="12">
          <div className="event-section">
            <Notice
              image={images.bulb}
              title={OKTION_VARIABLE?.OKTION_WAY_DO_I_NEED_ENTER_CARD_DETAILS}
              message={OKTION_VARIABLE?.OKTION_WAY_DO_I_NEED_ENTER_CARD_DETAILS_DESC}
            />
          </div>
        </Col>

        <Col md="12">
          <div className="event-section mt-2">
            <div className="notice">
              <img src={images.bulb} alt="" />
              <div className="message w-100">
                <div
                  className="d-flex align-items-center justify-content-between cursor-pointer"
                  onClick={() => setIsVerificationToggle(!isVerificationToggle)}>
                  <div>
                    <p className="mb-2 notice_title fw-500">
                      {OKTION_VARIABLE?.OKTION_MANDATORY_CARD_OWNERSHIP_VERIFICATION}
                    </p>
                    <p className="card-notes-sub-text">
                      {OKTION_VARIABLE?.OKTION_MANDATORY_CARD_OWNERSHIP_VERIFICATION_DESC}
                    </p>
                    <span>{isVerificationToggle ? '' : '...'}</span>
                  </div>
                  <div>
                    <img
                      className={`ml-3 collapse-icon ${isVerificationToggle && 'down'}`}
                      src={images.down_arrow}
                      alt=""
                    />
                  </div>
                </div>
                <Collapse isOpen={isVerificationToggle}>
                  <div>{cardVerificationNoteContent()}</div>
                </Collapse>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        {orgCards?.map(card =>
          card?.status === 'Verified' ? (
            <Col lg="6" md="7" key={card?.card?.id} className="mb-3 cardBlock">
              <div className="cardInfo">
                <div className="row">
                  <Col sm="12" className="pl-1 pr-1">
                    <div className="d-flex visaBox">
                      {card?.card?.brand === 'Visa' || card?.card?.brand === 'visa' ? (
                        <>
                          <img src={images.visaLogoBig} alt="Visa" height="100%" />
                        </>
                      ) : card?.card?.brand === 'MasterCard' || card?.card?.brand === 'mastercard' ? (
                        <>
                          <img src={images.mastercardLogo} alt="MasterCard" />
                        </>
                      ) : card?.card?.brand === 'UnionPay' || card?.card?.brand === 'unionpay' ? (
                        <>
                          <img src={images.unionpayLogo} alt="UnionPay" />
                        </>
                      ) : (
                        <>
                          <img src={images.jcbLogo} alt="jcb" />
                        </>
                      )}
                      <div className="right content">
                        {card?.card?.brand === 'Visa' || card?.card?.brand === 'visa' ? (
                          <>
                            <h5>
                              {OKTION_VARIABLE?.OKTION_VISA} *****{card?.card?.last4}
                            </h5>
                          </>
                        ) : card?.card?.brand === 'MasterCard' || card?.card?.brand === 'mastercard' ? (
                          <>
                            <h5>
                              {OKTION_VARIABLE?.OKTION_MASTERCARD} *****{card?.card?.last4}
                            </h5>
                          </>
                        ) : card?.card?.brand === 'UnionPay' || card?.card?.brand === 'unionpay' ? (
                          <>
                            <h5>
                              {OKTION_VARIABLE?.OKTION_UNIONPAY} *****{card?.card?.last4}
                            </h5>
                          </>
                        ) : (
                          <>
                            <h5>
                              {OKTION_VARIABLE?.OKTION_JCB} *****{card?.card?.last4}
                            </h5>
                          </>
                        )}
                        <p className="fs-12 expColor">
                          {OKTION_VARIABLE?.OKTION_EXPIRES} {card?.card?.expMonth}/{card?.card?.expYear}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center border-top pt-2 pr-2 pl-3">
                      {/* <img
                      onClick={() => handleCardDeleteToggle(card?.card?.id)}
                      className="cursor-pointer"
                      src={images.deleteWithCircle}
                      alt=""
                    /> */}
                      {card?.isDefault ? (
                        <span className="pr-3 color-blue primary-card-text">
                          {OKTION_VARIABLE?.OKTION_PRIMARY_CARD}
                        </span>
                      ) : (
                        <span
                          className="pr-3 color-blue make-primary-card-text"
                          onClick={() => handleMakePrimary(card?.card?.id)}>
                          {OKTION_VARIABLE?.OKTION_MAKE_PRIMARY_CARD}
                        </span>
                      )}
                      <button
                        className="black-button fs-12 fw-500 btn"
                        onClick={() => {
                          setVerifiedDeleteToggle(!verifiedDeleteToggle);
                          handleCardDeleteToggle(card?.card?.id);
                        }}>
                        {OKTION_VARIABLE?.OKTION_FEATURE_DELETE}
                      </button>
                    </div>
                  </Col>
                </div>
              </div>
            </Col>
          ) : (
            cardFields(card)
          ),
        )}
        {isAddNew ? (
          cardFields('')
        ) : (
          <Col lg="6" md="7" className="cardBlock">
            <div className="dottedBox cursor-pointer" onClick={ToggleForm}>
              + {OKTION_VARIABLE?.OKTION_CHECKOUT_ADD_NEW_CART}
            </div>
          </Col>
        )}
      </Row>
      {/* CARD DELETE MODAL */}
      <VerificationModal
        isCloseIcon={isVerify ? false : true}
        isToggle={toggleDeleteModal}
        setToggleModal={isToggle => {
          setTogglepDeleteModal(isToggle);
          setIsLastCard(false);
          setIsVerify(false);
          setVerifiedStatus(false);
          setVerifiedDeleteToggle(false);
        }}
        title={
          isVerify
            ? OKTION_VARIABLE?.OKTION_CARD_VERIFICATION_COMPLETE
            : isLastCard
            ? OKTION_VARIABLE?.OKTION_UH_OH
            : verifiedDeleteToggle
            ? OKTION_VARIABLE?.OKTION_DELETE_CARD
            : `${OKTION_VARIABLE?.OKTION_FEATURE_DELETE + '?'}`
        }
        subDesc={
          verifiedStatus
            ? OKTION_VARIABLE?.OKTION_DELETE_VERIFICATION_PROCESS_DESC
            : isVerify
            ? OKTION_VARIABLE?.OKTION_CARD_VERIFICATION_COMPLETE_DESC
            : isLastCard
            ? OKTION_VARIABLE?.OKTION_MUST_HAVE_ONE_VALID_PAYMENT_METHOD
            : verifiedDeleteToggle
            ? OKTION_VARIABLE?.OKTION_DELETE_THIS_PAYMENT_METHOD
            : OKTION_VARIABLE?.OKTION_ARE_YOU_SURE_YOU_WANT_TO_DELETE
        }
        cancelBtn={
          isVerify ? '' : isLastCard ? OKTION_VARIABLE?.OKTION_BACK_BUTTON : OKTION_VARIABLE?.OKTION_BUTTON_CANCEL
        }
        submitBtn={
          isVerify
            ? OKTION_VARIABLE?.OKTION_CONTINUE
            : isLastCard
            ? OKTION_VARIABLE?.OKTION_ADD_PAYMENT_METHOD
            : OKTION_VARIABLE?.OKTION_FEATURE_DELETE
        }
        handleSubmit={() => {
          isVerify ? handleContinue() : isLastCard ? ToggleForm() : handleCardDelete();
        }}
        className="delete-card-modal"
      />

      {/* CARD VERIFICATION IN PROGRESS MODAL */}
      <VerificationModal
        isCloseIcon={unableVerifyToggle ? true : false}
        isToggle={toggleVerifyModal}
        setToggleModal={isToggle => {
          setToggleVerifyModal(isToggle);
          setUnableVerifyToggle(false);
          const currentOrgId = fetchFromStorage(identifiers.currentOrganization);
          if (isNewOrg) {
            history.replace(`/organization/${currentOrgId}?card-details`);
          }
        }}
        title={
          unableVerifyToggle
            ? OKTION_VARIABLE?.OKTION_UNABLE_TO_VERIFY
            : OKTION_VARIABLE?.OKTION_CARD_VERIFICATION_INPROGRESS
        }
        subDesc={
          unableVerifyToggle ? OKTION_VARIABLE?.OKTION_UNABLE_TO_VERIFY_CARD_TRY_AGAIN : cardVerificationModalContent()
        }
        cancelBtn={
          unableVerifyToggle ? OKTION_VARIABLE?.OKTION_BUTTON_CANCEL : OKTION_VARIABLE?.OKTION_BACK_TO_CARD_DETAILS
        }
        submitBtn={
          unableVerifyToggle ? OKTION_VARIABLE?.OKTION_FEATURE_DELETE : OKTION_VARIABLE?.OKTION_START_EVENT_CREATION
        }
        handleSubmit={() => {
          unableVerifyToggle ? handleCardDelete() : handleRedirectEventPage();
        }}
        className="delete-card-modal"
      />
    </div>
  );
};

export default CardDetails;
