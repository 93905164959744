import React, { useEffect, useState, useRef, useContext } from 'react';
import TicketsList from './TicketsList';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import EventTimeDetails from '../EventTimeDetails';
import { images } from '../../../../library/common/constants/ImageConstants';
import Slider from 'react-slick';
import '../../styles.scss';
// import { ticketCardData } from '../../../UserProfile/UserProfileData';
import TicketsCard from '../../../UserProfile/Frame/ContributionHistory/Frame/TicketsCard';
import { useHistory } from 'react-router-dom';
import LogoutModal from '../Raffle/LogoutModal';
import SendTicketModal from './SendTicketModal';
import { connect } from 'react-redux';
import Button from 'reactstrap/es/Button';
import axiosInstance from '../../../../main/axios';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import moment from 'moment';
import TicketDataModal from './TicketDataModal';
// import { fetchFromStorage } from '../../../../utility';
// import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
import ReactToPrint from 'react-to-print';
// import ActionMessage from '../../../../library/common/components/ActionMessage';
import EventEmitter from 'reactjs-eventemitter';
import { useParams } from 'react-router-dom';
import TicketPrintComponent from './TicketPrintComponent';
import { SocketContext } from 'main/context/socket';
import { fetchFromStorage, saveToStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { setApiMessage } from 'library/common/constants/function';

const pageStyle = `
  @page {
    size: 80mm 50mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

const Tickets = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  const params = useParams();
  var eventId = params?.id;

  const history = useHistory();
  const [count, setCount] = useState({});

  const [isToggle, setToggle] = useState(false);
  const [is_Toggle, setToggleValue] = useState(false);
  const [isToggleModal, setToggleModalValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ticket_array, setFeatureData] = useState([]);
  const [ticketItemData, setticketItemData] = useState('');
  const [TicketCartlist, setTicketCartlist] = useState([]);
  const [TicketData, setTicketData] = useState([]);
  const [eventOutfit, setEventOutfit] = useState();
  const [cartItem, setClickItemData] = useState();
  // const [flag, setFlag] = useState(false);
  const componentRef = useRef();
  // const [message, setMessage] = useState({
  //   display: false,
  //   type: '',
  //   message: '',
  // });
  const userDetail = fetchFromStorage(identifiers?.userDetail);
  useEffect(() => {
    var ticektFeatureData = props?.eventFeatures?.filter((item, index) => item.type === 'ticket');
    var tempObj = {};
    ticektFeatureData.map((item, index) => {
      Object.assign(tempObj, { [`count${index}`]: 0 });
    });
    setCount(tempObj);
    // console.log('ticektFeatureData', ticektFeatureData);

    // var ticektFeatureData = props?.eventFeatures?.filter((item, index) => item.type === 'ticket');
    // var TICKER_FEATURE_DATA = [];
    // TICKER_FEATURE_DATA = ticektFeatureData.map((item, index) => {
    //   return {
    //     ...item,
    //     count: 1,
    //   };
    // });
    // setFeatureData(TICKER_FEATURE_DATA);
  }, [props?.eventFeatures]);

  useEffect(() => {
    props?.isLoggedIn && getTicketPurchase();
    // getEventDetailsById();
  }, [props?.isLoggedIn]);

  // const setActionMessage = (display = false, type = '', message = '') => {
  //   setMessage({
  //     display,
  //     type,
  //     message,
  //   });
  // };
  const getTicketPurchase = async () => {
    try {
      var TICKET_PURCHASE_DATA = [];
      // const eventDetails = fetchFromStorage(identifiers.eventDetails);
      const { status, data } = await axiosInstance.get(URLS.getTicketPurchase(eventId, false));
      // console.log('TICKET_PURCHASE_DATA', status, data?.data?.tickets);
      if (status === 200 || status === 304) {
        TICKET_PURCHASE_DATA = data?.data?.tickets.map(item => {
          var userName =
            item?.user?.firstName !== null && item?.user?.lastName !== null
              ? `${item?.user?.firstName} ${item?.user?.lastName}`
              : item?.user?.firstName !== null
              ? item?.user?.firstName
              : item?.user?.lastName !== null
              ? item?.user?.lastName
              : '';
          return {
            name: userName,
            amount: item?.user?.phoneNumber,
            toggleBundle: true,
            status: '',
            email: item?.user?.email,
            ticketNumber: item?.ticketNumber,
            id: item?.id,
            ticketName: item?.ticketName,
          };
        });
      }
      setTicketCartlist(data?.data?.tickets);
      setTicketData(TICKET_PURCHASE_DATA);
      // this.setState({ loading: false, donationTicketBundle: TICKET_PURCHASE_DATA });
    } catch (err) {
      // this.setState({ loading: false });
    }
  };
  // var eventDetails = fetchFromStorage(identifiers.eventDetails);
  // const url = window.location.pathname;
  // const spliturl = url.split('/');
  // const id = spliturl[spliturl.length - 1];
  // var eventId = eventDetails !== null ? eventDetails?.id : id;
  const getEventDetailsById = async () => {
    const previewStatus = history?.location?.state?.previewStatus;
    var prevStatus = previewStatus !== undefined ? previewStatus : 0;
    try {
      const { status, data } = await axiosInstance.get(URLS.getPublicEventByid(eventId, prevStatus));
      if (status === 200 || status === 304) {
        setEventOutfit(data?.data?.events);
      }
    } catch (err) {}
  };
  const handleAdd = (name, index) => {
    // setFlag(true);
    // setCount({ ...count, [name]: count[`count${index}`] + 1 });
    // const value = parseInt(event.target.value);
    // const name = event.target.name;
    var formData = { ...count, [name]: count[`count${index}`] + 1 };
    setCount({ ...count, [name]: count[`count${index}`] + 1 });

    var ticektFeatureData = props?.eventFeatures?.filter((item, index) => item.type === 'ticket');
    var TICKER_FEATURE_DATA = [];
    TICKER_FEATURE_DATA = ticektFeatureData.map((item, index) => {
      return {
        ...item,
        count: formData[`count${index}`],
      };
    });
    setFeatureData(TICKER_FEATURE_DATA);

    // setCount(count + 1);
  };
  const handleMinus = (name, index) => {
    // setFlag(true);
    if (count[`count${index}`] > 0) {
      var formData = { ...count, [name]: count[`count${index}`] - 1 };
      setCount({ ...count, [name]: count[`count${index}`] - 1 });

      var ticektFeatureData = props?.eventFeatures?.filter((item, index) => item.type === 'ticket');
      var TICKER_FEATURE_DATA = [];
      TICKER_FEATURE_DATA = ticektFeatureData.map((item, index) => {
        return {
          ...item,
          count: formData[`count${index}`],
        };
      });
      setFeatureData(TICKER_FEATURE_DATA);
    }
  };
  const toggle = () => {
    setToggle(!isToggle);
  };
  const toggleVal = () => {
    setToggleValue(!is_Toggle);
  };
  const toggleModal = () => {
    setToggleModalValue(!isToggleModal);
  };

  const onItemClick = item => {
    setticketItemData(item);
    setToggleModalValue(true);
  };
  const handleInputChange = event => {
    const value = parseInt(event.target.value);
    const name = event.target.name;
    var formData = { ...count, [name]: value };
    setCount({ ...count, [name]: value });

    var ticektFeatureData = props?.eventFeatures?.filter((item, index) => item.type === 'ticket');
    var TICKER_FEATURE_DATA = [];
    TICKER_FEATURE_DATA = ticektFeatureData.map((item, index) => {
      return {
        ...item,
        count: formData[`count${index}`],
      };
    });
    setFeatureData(TICKER_FEATURE_DATA);
  };
  const handleCheckValidFeture = async () => {
    var guestCheckoutArray = fetchFromStorage(identifiers?.guestcheckoutdata);
    setLoading(true);
    var senData = [];
    var TICKET_DATA = ticket_array.filter(item => item?.count !== 0);
    senData =
      TICKET_DATA &&
      TICKET_DATA.map((item, index) => {
        return {
          type: 'ticket',
          quantity: item.count,
          eventId: item.eventId,
          referenceId: null,
          purchaseReferenceId: item.id,
          isRaffleTicket: false,
        };
      });

    // if (!Array.isArray(guestCheckoutArray)) {
    //   guestCheckoutArray = [];
    // }

    // let filterValidateCart = [...senData, ...guestCheckoutArray];
    // var filterguestCheckoutArray = filterValidateCart.reduce((prev, cur) => {
    //   if (!Array.isArray(prev)) {
    //     prev = [];
    //   }

    //   const index = prev?.findIndex(v => v?.purchaseReferenceId === cur?.purchaseReferenceId);
    //   if (index === -1) {
    //     prev.push(cur);
    //   } else {
    //     prev[index].total = (prev[index].total || 0) + (cur?.total || 0);
    //     prev[index].quantity = (prev[index].quantity || 0) + (cur?.quantity || 0);
    //   }
    //   return prev;
    // }, []); // Initialize prev as an empty array

    let finalOldCart = guestCheckoutArray?.map(item => {
      return {
        type: item?.type,
        quantity: item?.quantity,
        eventId: item?.eventId,
        referenceId: item?.referenceId,
        purchaseReferenceId: item?.purchaseReferenceId,
        isRaffleTicket: item?.isRaffleTicket,
        donationAmount: item?.donationAmount,
      };
    });

    let filterguestCheckoutArray = {
      cartArray: senData,
      oldCart: Array.isArray(finalOldCart) ? finalOldCart : [],
    };

    try {
      const { status } = await axiosInstance.post(URLS.isValidFeture, filterguestCheckoutArray);
      if (status === 201 || status === 200) {
        setLoading(false);
        if (!props?.isLoggedIn) {
          setToggle(true);
        }
        handleSetCartData();
      }
    } catch (err) {
      setLoading(false);
      setApiMessage('error', err?.response?.data?.msg);
    }
  };

  const handleBtnClick = async () => {
    if (props?.isLoggedIn && userDetail?.userType === 'normal') {
      setLoading(true);
      var senData = [];
      var TICKET_DATA = ticket_array.filter(item => item?.count !== 0);
      senData =
        TICKET_DATA &&
        TICKET_DATA.map((item, index) => {
          return {
            type: 'ticket',
            quantity: item.count,
            eventId: item.eventId,
            referenceId: null,
            purchaseReferenceId: item.id,
            isRaffleTicket: false,
          };
        });

      // const senData = [
      //   {
      //     type: 'ticket',
      //     quantity: count,
      //     eventId: props?.item?.eventId,
      //     referenceId: null,
      //     purchaseReferenceId: props?.item?.id,
      //     isRaffleTicket: false,
      //   },
      // ];
      try {
        const { status } = await axiosInstance.post(URLS.addToCart, senData);
        if (status === 201 || status === 200) {
          setLoading(false);
          // history.push('/checkout');
          history.push('/checkout', {
            FROM_WHERE: 'PUBLIC_EVENT_DETAILS',
            eventId: eventId,
          });
        }
      } catch (err) {
        setLoading(false);
        /*  setActionMessage(true, 'Error', err?.response?.data?.msg);
        setTimeout(() => {
          setActionMessage(false);
        }, 4000); */
        // EventEmitter.dispatch('feturesErrorMessage', err?.response?.data?.msg);
        setApiMessage('error', err?.response?.data?.msg);
      }
    } else if (props?.isLoggedIn && userDetail?.userType === 'guest') {
      handleCheckValidFeture();
    } else {
      handleCheckValidFeture();
    }
  };

  const handleSetCartData = () => {
    var TICKET_DATA = ticket_array.filter(item => item?.count !== 0);
    let CartData =
      TICKET_DATA &&
      TICKET_DATA.map((item, index) => {
        var ticketValue = item?.eventFeatureOptions.filter((item, index) => item.key === 'priceperticket');
        return {
          type: 'ticket',
          quantity: item.count,
          referenceId: null,
          subscriptionName: null,
          subscriptionPrice: null,
          name: item?.featureName,
          amount: ticketValue[0]?.value,
          purchaseReferenceId: item?.id,
          isRaffleTicket: false,
          donationAmount: null,
          eventId: item?.eventId,
          currency: props?.eventDetails?.currency?.abbreviation,
          symbol: props?.eventDetails?.currency?.symbol,
          event: props?.eventDetails,
          eventName: props?.eventDetails?.eventName,
          digiCommission: 0,
          itemDetails: item,
          cardCharedAmount: 0,
          total: ticketValue[0]?.value * item?.count,
        };
      });

    setClickItemData(CartData);
    if (props?.isLoggedIn && userDetail?.userType === 'guest') {
      updateGuestCardList(CartData);
      history.push('/guest-checkout', {
        eventId: params?.id,
      });
    }
  };

  const updateGuestCardList = data => {
    // Fetch the existing guestCheckoutData from storage or initialize it as an empty array
    let guestCheckoutData = fetchFromStorage(identifiers?.guestcheckoutdata) || [];

    // Check if singleItem is an array or object using optional chaining
    if (data?.constructor === Array) {
      // If it's an array, concatenate it with the existing guestCheckoutData
      guestCheckoutData = [...guestCheckoutData, ...data];
    } else if (data) {
      // If it's an object (not null or undefined), push it to the guestCheckoutData array
      guestCheckoutData.push(data);
    }

    // Save the updated guestCheckoutData to storage
    saveToStorage(identifiers?.guestcheckoutdata, guestCheckoutData);

    // Dispatch an event or perform any other necessary actions
    setTimeout(() => {
      EventEmitter.dispatch('updateguestcheckout', guestCheckoutData);
    }, 300);
  };

  const handleClick = () => {
    history.push('/login', {
      redirectTo: 'event-details',
      featureName: 'ticket',
      eventId: eventId,
    });
  };

  var ticektFeatureData = props?.eventFeatures?.filter((item, index) => item.type === 'ticket');

  const venueAddress = [
    props?.eventDetails?.venue?.addressLine1,
    props?.eventDetails?.venue?.addressLine2 + ',',
    <br />,
    props?.eventDetails?.venue?.city + ' ',
    props?.eventDetails?.venue?.state + ' ',
    props?.eventDetails?.venue?.country + ' ',
    props?.eventDetails?.venue?.postCode,
  ];
  const currency = props?.eventDetails?.currency?.abbreviation;
  const currencysymbol = props?.eventDetails?.currency?.symbol;
  let flag = ticket_array.every(i => {
    return i?.count === 0;
  });

  var findLength = TicketCartlist.length > 8 && (TicketCartlist.length - 8) / 10;
  var lengthArray = [];
  var temp = 0;
  for (var i = 0; i < findLength + 1; i++) {
    var start = temp;
    var end = i === 0 ? start + 8 : start + 10;
    temp = end;
    var obj = {
      start: start,
      end: end,
    };
    lengthArray.push(obj);
  }

  let DATE_NOW = moment(new Date()).format('YYYY-MM-DD HH mm');
  let EVENT_END_DATE = moment(props?.eventDetails?.venueEndDate).format('YYYY-MM-DD HH mm');

  let isVenueEndDateDisable = DATE_NOW >= EVENT_END_DATE;

  return (
    <div className="res-org-tickets">
      <div style={{ display: 'none' }}>
        <div ref={componentRef} className="m-2">
          {/* <TicketPrintComponent
            TicketCartlist={TicketCartlist.slice(0, 8)}
            eventDetails={props?.eventDetails}
            venueAddress={venueAddress}
            timeZone={props?.timeZone}
            isEventDetailsShow
          /> */}
          {lengthArray.map((item, index) => {
            return (
              <TicketPrintComponent
                TicketCartlist={TicketCartlist.slice(item?.start, item?.end)}
                eventDetails={props?.eventDetails}
                venueAddress={venueAddress}
                timeZone={props?.timeZone}
                isEventDetailsShow={index === 0 ? true : false}
              />
            );
          })}

          {/* <div className="print-container" style={{ margin: '0', padding: '0' }}>
            {TicketCartlist.map((item, index) => (
              <TicketsCard
                isBarcode={true}
                isTicket={true}
                data={item}
                showImage={true}
                handleItemClick={onItemClick}
                imageUrl={props?.eventDetails?.eventImages[0].name}
                printqrCode
                printImage
              />
            ))}
          </div> */}
        </div>
      </div>
      {/* {message.display && (
        <div className="btn-block pt-2">
          <ActionMessage message={message.message} type={message.type} styleClass={message.styleClass} />
        </div>
      )} */}
      {props?.isLoggedIn && TicketCartlist.length > 0 && (
        <div className="d-flex justify-content-between align-items-center pt-5">
          <h4 className="">{OKTION_VARIABLE?.OKTION_YOUR_TICKETS}</h4>
          <div className="d-flex">
            <p className="fs-16 fw-500 cursor-pointer" onClick={() => setToggleValue(true)}>
              {OKTION_VARIABLE?.OKTION_TICKET_SENDTICKET}
            </p>
            <ReactToPrint
              pageStyle={pageStyle}
              trigger={() => <p className="fs-16 fw-500 pl-4 cursor-pointer hide-xs">Print Tickets</p>}
              content={() => componentRef.current}
            />
          </div>
        </div>
      )}
      <Slider {...settings}>
        {TicketCartlist.map((item, index) => (
          <TicketsCard
            isBarcode={true}
            isTicket={true}
            key={index}
            data={item}
            showImage={true}
            handleItemClick={onItemClick}
            imageUrl={props?.eventDetails?.eventImages[0].name}
          />
        ))}
      </Slider>
      <h4 className="pt-4 mb-3">{OKTION_VARIABLE?.OKTION_TICKET_TITLE}</h4>
      <Row className="event-ticket-main">
        <Col lg="9" md="8" sm="12">
          {ticektFeatureData.map((item, index) => (
            <div className={item?.isActive === false ? 'blur-section ticket-item' : 'ticket-item'}>
              {/* {!item?.isActive && <h3>SOLD</h3>} */}
              <TicketsList
                item={item}
                index={`count${index}`}
                handleInputChange={handleInputChange}
                count={count}
                handleAdd={handleAdd}
                handleMinus={handleMinus}
                loading={loading}
                itemNumber={index}
                currency={currency}
                currencySymbol={currencysymbol}
                eventDetails={props?.eventDetails}
                whitelabel={props?.whitelabel}
                isVenueEndDateDisable={isVenueEndDateDisable}
              />
            </div>
          ))}
          {!flag && (
            <Button
              onClick={handleBtnClick}
              className="main-button btn-block mt-2 bigButton"
              style={{
                backgroundColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
                borderColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
                color: props?.eventDetails?.primaryTextColour ? props?.eventDetails?.primaryTextColour : '',
              }}
              disabled={isVenueEndDateDisable || props?.whitelabel === true ? true : false}>
              {OKTION_VARIABLE?.OKTION_ADD_TO_CART}
            </Button>
          )}
        </Col>
        <Col lg="3" md="4" sm="12" className="mb-3">
          {/* <EventTimeDetails img={images.calendar} startDate="[Event Start Date]" isLocation={false} /> */}
          {props?.timeZone !== undefined &&
            (props?.eventDetails?.location === 1 || props?.eventDetails?.location === 3) && (
              <EventTimeDetails
                img={images.watch}
                title={OKTION_VARIABLE?.OKTION_LEFTFILTER_EVENTDATE}
                startDate={moment(props?.eventDetails?.startDate).format('DD/MM/YYYY hh:mm A')}
                endDate={moment(props?.eventDetails?.endDate).format('DD/MM/YYYY hh:mm A')}
                isLocation={false}
              />
            )}
          {props?.eventDetails?.location !== 1 && (
            <EventTimeDetails
              title={OKTION_VARIABLE?.OKTION_VENUE_LOCATION}
              isLocation
              img={images.location}
              endDate={moment(props?.eventDetails?.venueEndDate).format('DD/MM/YYYY hh:mm A')}
              startDate={moment(props?.eventDetails?.venueStartDate).format('DD/MM/YYYY hh:mm A')}
              address={venueAddress}
              secondaryTitle={OKTION_VARIABLE?.OKTION_LEFTFILTER_VENUE}
            />
          )}
          {/* <EventTimeDetails
            isLocation
            img={images.location}
            endDate="22 August, 5:00 PM"
            startDate="22 August, 11:00 AM"
            address="80 Pyrmont St,Pyrmont NSW 2009"
          /> */}
          <p className="ml-5 pl-2 fw-500">+ &nbsp; {OKTION_VARIABLE?.OKTION_ONLINE_EVENT}</p>
        </Col>{' '}
      </Row>
      <LogoutModal
        isToggle={isToggle}
        toggle={toggle}
        handleClick={handleClick}
        isGuestCheckout
        singleItem={cartItem}
      />
      <SendTicketModal isToggle={is_Toggle} toggle={toggleVal} TicketData={TicketData} />
      <TicketDataModal
        isToggle={isToggleModal}
        toggle={toggleModal}
        item={ticketItemData}
        eventDetails={props?.eventDetails}
        ticket
      />
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};
export default connect(mapStateToProps, {})(Tickets);
