import React, { useState, useContext } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { images } from '../../../../library/common/constants/ImageConstants';
import RelistingModal from './RelistingModal';
import { useHistory } from 'react-router-dom';
import { fetchFromStorage } from '../../../../utility';
import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
import axiosInstance from '../../../../main/axios';
import moment from 'moment';
// import { saveToStorage } from '../../../../utility';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import { SocketContext } from '../../../../main/context/socket';

var CurrencyFormat = require('react-currency-format');
const ItemDetailsSmallModal = ({ isToggle, toggle, data, tabValue, getSupplyItem }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [toggleModal, setToggleModal] = useState(false);
  const [lotData, setLotData] = useState(data);
  const [claimedUserData, setClaimedUSerData] = useState('');
  const history = useHistory();
  const handleToggle = () => {
    setToggleModal(!toggleModal);
  };
  // console.log('lotData', lotData, tabValue);
  // console.log('datadata', data);
  const user_data = fetchFromStorage(identifiers.userDetail);
  // console.log('hasown', data.hasOwnProperty('raffle'));
  // console.log('organisation', user_data?.id);

  const handleWinnerContact = data => {
    // console.log('data', data?.organiserUser?.id);
    let obj = {
      fromUser: true,
      paramsData: {
        recipientId: data?.auctionItem?.winner?.id,
        firstName: data?.auctionItem?.winner?.firstName,
        lastName: data?.auctionItem?.winner?.lastName,
        userProfilePicture: data?.auctionItem?.winner?.userProfilePicture,
        referenceId: data?.id,
        chatType: 'supplyItem',
        referenceName: data?.name,
      },
    };
    history.push('/mail/' + data?.auctionItem?.winner?.id, obj);
  };
  const handleConatct = data => {
    // console.log('data', data?.organiserUser?.id);
    let obj = {
      fromUser: true,
      paramsData: {
        recipientId: data?.organiserUser?.id,
        firstName: data?.organiserUser?.firstName,
        lastName: data?.organiserUser?.lastName,
        userProfilePicture: data?.organiserUser?.userProfilePicture,
        referenceId: data?.id,
        chatType: 'supplyItem',
        referenceName: data?.name,
      },
    };
    history.push('/mail/' + data?.organiserUser?.id, obj);
  };

  const handleClaimed = async () => {
    var requestData = {
      images: lotData?.images,
      ...(lotData?.name !== '' && { name: lotData?.name }),
      ...(lotData?.aboutItme !== '' && { aboutItme: lotData?.aboutItme }),
      ...(lotData?.categoryId !== '' && { categoryId: lotData?.categoryId }),
      ...(lotData?.prizeValue !== '' && { value: lotData?.prizeValue }),
      ...(lotData?.startingBid !== '' && { startingBid: lotData?.startingBid }),
      ...(lotData?.bidIncrement !== '' && { bidIncrement: lotData?.bidIncrement }),
      ...(lotData?.reserveAmount !== '' && { reserveAmount: lotData?.reserveAmount }),
      ...(lotData?.buyItNow !== '' && { buyItNow: lotData?.buyItNow }),
      ...(lotData?.itemExpiryDate !== '' && { itemExpiryDate: moment(lotData.itemExpiryDate).toISOString() }),
      ...(lotData?.startingBid !== '' && { startingBid: lotData?.startingBid }),
      ...(lotData?.postageAndHandeling !== '' && { postageAndHandeling: lotData?.postageAndHandeling }),
      ...(lotData?.postageHandlingNote !== '' && { postageHandlingNote: lotData?.postageHandlingNote }),
      ...(lotData?.status !== '' && { status: lotData?.status }),
      ...(lotData?.itemStatus !== '' && { postageHandlingNote: lotData?.itemStatus }),
      ...(lotData?.currencyId !== '' && { currency: data?.currency?.id }),
      ...(lotData?.value !== '' && { value: lotData?.value }),
      ...(lotData?.markAsClaimed !== '' && { markAsClaimed: true }),
    };
    try {
      const { status, data } = await axiosInstance.put(URLS.updateSupplyItem(lotData?.id), requestData);
      // setLoading(false);
      if (status === 200 || status === 201 || status === 304) {
        window.scrollTo(0, 0);
        setClaimedUSerData(data);
        // setLoading(false);
        // setActionMessage(true, 'Success', 'Success');
        getSupplyItem();
        // props.toggle();
        // setTimeout(() => {
        //   setActionMessage(false);
        // }, 4000);
      }
    } catch (err) {
      // setLoading(false);
      if (err.response) {
        // setActionMessage(true, 'Error', err.response.data.validation.body.message);
        // setTimeout(() => {
        //   setActionMessage(false);
        // }, 4000);
      } else {
        // setActionMessage(true, 'Error', 'Something went wrong');
        // setTimeout(() => {
        //   setActionMessage(false);
        // }, 5000);
      }
    }
  };
  const handleOnClick = () => {
    history.push(`/event-details/${data?.event?.id}`);
    // saveToStorage(identifiers.eventDetails, lotData?.event);
    // saveToStorage(identifiers.publicEventDetails, lotData?.event);
    // saveToStorage(identifiers.eventDetails, event);
  };
  // console.log('data?.auctionItem?.winnerId', data?.organiserUser?.id);
  // console.log('data?.auctionItem?.winnerId 1 ', org_id);

  return (
    <div className="AddPriceModal ">
      <Modal size="md" className="customModal add-price-modal" isOpen={isToggle}>
        <div className="text-right">
          <img className="cursor-pointer" onClick={toggle} src={images.close} alt="" />
        </div>
        <ModalBody>
          <div className="addPriceModalForm itemDetailsSmallModal">
            <h5 className="mb-2">{data?.name}</h5>
            <div className="d-flex justify-content-between">
              <div>
                <span className="fs-12 smallBadge">{data?.itemStatus}</span>{' '}
                <span className="fs-16 ml-2">{data?.event?.eventName}</span>
              </div>
              <div>
                {/* <a href="#" className="underline fs-16">
                  Go to Event Page
                </a> */}
                <button className="underline fs-16 contactBtn" onClick={handleOnClick}>
                  {OKTION_VARIABLE?.OKTION_GOTO_EVENT}
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2 borderBottom pb-3">
              <div>
                <span className="fs-16 ml-2">
                  {data?.organiserUser?.firstName} {data?.organiserUser?.lastName}
                </span>
              </div>
              <div>
                {/* {console.log('org_id',org_id, data?.organiserUser?.id)} */}
                {user_data?.id !== lotData?.organiserUser?.id ? (
                  <button onClick={() => handleConatct(data)} className="underline fs-16 contactBtn">
                    {/*  <a href={`/mail/${data?.organiserUser?.id}`} className="underline fs-16">*/}
                    {OKTION_VARIABLE?.OKTION_CONTACT}
                  </button>
                ) : null}
              </div>
            </div>
            <div className="mb-4 mt-4">
              <p className="fs-16 fw-500 mb-1">
                {data.hasOwnProperty('raffle') === true
                  ? OKTION_VARIABLE?.OKTION_PRIZE_DETAILS
                  : data.hasOwnProperty('auction') === true
                  ? OKTION_VARIABLE?.OKTION_LOT_DETAILS
                  : ''}{' '}
              </p>
              {data.hasOwnProperty('raffle') === true ? (
                <>
                  <p className="fs-16 mb-1">
                    {OKTION_VARIABLE?.OKTION_PRIZE_VALUE} :
                    {/* {data?.currency?.abbreviation}{' '}
                    {isNaN(parseFloat(data?.value)) ? '0.00' : parseFloat(data?.value).toFixed(2)} */}
                    <CurrencyFormat
                      value={data?.value !== null ? data?.value : '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={data?.currency !== null && `${data?.currency?.abbreviation} ${data?.currency?.symbol}`}
                      // thousandSpacing="2s"
                      thousandSpacing={data?.currency?.symbol === '₹' ? '2s' : '3'}
                      decimalScale="2"
                      fixedDecimalScale={true}
                    />
                  </p>
                  <p className="fs-16 mb-1">
                    {OKTION_VARIABLE?.OKTION_PRIZE_PLACE} : {/* {data?.currency?.abbreviation}{' '} */}
                    {/* {isNaN(parseFloat(data?.raffleItem?.prizePlace)) ? '0' : data?.raffleItem?.prizePlace} */}
                    <CurrencyFormat
                      value={data?.raffleItem?.prizePlace !== null ? data?.raffleItem?.prizePlace : '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={data?.currency !== null && `${data?.currency?.abbreviation} ${data?.currency?.symbol}`}
                      // thousandSpacing="2s"
                      thousandSpacing={data?.currency?.symbol === '₹' ? '2s' : '3'}
                      decimalScale="2"
                      fixedDecimalScale={true}
                    />
                  </p>
                </>
              ) : data.hasOwnProperty('auction') === true ? (
                <>
                  <p className="fs-16 mb-1">{OKTION_VARIABLE?.OKTION_BID_ZERO}</p>
                  <p className="fs-16 mb-1">
                    {OKTION_VARIABLE?.OKTION_CURRENT_BID} : {/* {data?.currency?.abbreviation}{' '} */}
                    {/* {isNaN(parseFloat(data?.auctionItem?.currentBid))
                      ? '0.00'
                      : parseFloat(data?.auctionItem?.currentBid).toFixed(2)} */}
                    <CurrencyFormat
                      value={data?.auctionItem?.currentBid !== null ? data?.auctionItem?.currentBid : '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={data?.currency !== null && `${data?.currency?.abbreviation} ${data?.currency?.symbol}`}
                      // thousandSpacing="2s"
                      thousandSpacing={data?.currency?.symbol === '₹' ? '2s' : '3'}
                      decimalScale="2"
                      fixedDecimalScale={true}
                    />
                  </p>
                  <p className="fs-16 mb-1">
                    {OKTION_VARIABLE?.OKTION_RESERVE} : {/* {data?.currency?.abbreviation}{' '} */}
                    {/* {isNaN(parseFloat(data?.reserveAmount)) ? '0.00' : parseFloat(data?.reserveAmount).toFixed(2)} */}
                    <CurrencyFormat
                      value={data?.reserveAmount !== null ? data?.reserveAmount : '0.00'}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={data?.currency !== null && `${data?.currency?.abbreviation} ${data?.currency?.symbol}`}
                      // thousandSpacing="2s"
                      thousandSpacing={data?.currency?.symbol === '₹' ? '2s' : '3'}
                      decimalScale="2"
                      fixedDecimalScale={true}
                    />
                  </p>
                  <p className="fs-16 mb-1">
                    {OKTION_VARIABLE?.OKTION_RESERVE_MET} :
                    {data?.auctionItem?.reserveMet === false ? OKTION_VARIABLE?.OKTION_NO : OKTION_VARIABLE?.OKTION_YES}
                  </p>
                </>
              ) : (
                ''
              )}
              {/* <p className="fs-16 mb-1">Current Bid: {data?.auctionItem?.currentBid}</p>
              <p className="fs-16 mb-1">Reserve: {data?.reserveAmount}</p>
              <p className="fs-16 mb-1">Reserve Met: {data?.reserveMet}</p> */}
            </div>
            {data?.auctionItem?.winnerId !== null && (
              <>
                {data.hasOwnProperty('auction') === true && (
                  <>
                    <div className="mb-4 mt-4">
                      <p className="fs-16 fw-500 mb-1">{OKTION_VARIABLE?.OKTION_WINNER_DETAILS}</p>
                      <div className="d-flex justify-content-between">
                        <p className="fs-16 mb-1">
                          {OKTION_VARIABLE?.OKTION_WINNER} : {lotData?.auctionItem?.winner?.firstName}{' '}
                          {lotData?.auctionItem?.winner?.lastName}
                        </p>
                        <p>
                          {/* <a href="#" className="underline fs-16">
                            Contact
                          </a> */}
                          {user_data?.id !== lotData?.organiserUser?.id ? (
                            <button onClick={() => handleWinnerContact(data)} className="underline fs-16 contactBtn">
                              {/*  <a href={`/mail/${data?.organiserUser?.id}`} className="underline fs-16">*/}
                              {OKTION_VARIABLE?.OKTION_CONTACT}
                            </button>
                          ) : null}
                        </p>
                      </div>
                      <p className="fs-16 mb-1">
                        {OKTION_VARIABLE?.OKTION_EMAIL_ADDRESS} : {lotData?.auctionItem?.winner?.email}
                      </p>
                      <p className="fs-16 mb-1">
                        {OKTION_VARIABLE?.OKTION_EMAIL_ADDRESS} : {lotData?.auctionItem?.winner?.phoneNumber}
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
            {data?.raffleItem?.winner !== null && (
              <>
                {data.hasOwnProperty('raffle') === true && (
                  <>
                    <div className="mb-4 mt-4">
                      <p className="fs-16 fw-500 mb-1">{OKTION_VARIABLE?.OKTION_WINNER_DETAILS}</p>
                      <div className="d-flex justify-content-between">
                        <p className="fs-16 mb-1">
                          {OKTION_VARIABLE?.OKTION_WINNER} : {lotData?.raffleItem?.winner[0]?.firstName}{' '}
                          {lotData?.raffleItem?.winner[0]?.lastName}
                        </p>
                        <p>
                          {/* <a href="#" className="underline fs-16">
                            Contact
                          </a> */}
                          {user_data?.id !== lotData?.organiserUser?.id ? (
                            <button onClick={() => handleConatct(data)} className="underline fs-16 contactBtn">
                              {/*  <a href={`/mail/${data?.organiserUser?.id}`} className="underline fs-16">*/}
                              {OKTION_VARIABLE?.OKTION_CONTACT}
                            </button>
                          ) : null}
                        </p>
                      </div>
                      <p className="fs-16 mb-1">
                        {' '}
                        {OKTION_VARIABLE?.OKTION_EMAIL_ADDRESS_COLON} {lotData?.raffleItem?.winner[0]?.email}
                      </p>
                      <p className="fs-16 mb-1">
                        {OKTION_VARIABLE?.OKTION_MOBILE} : {lotData?.raffleItem?.winner[0]?.phoneNumber}
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
            {data.hasOwnProperty('auction') === true ? (
              <div className="mb-4 mt-4">
                <p className="fs-16 fw-500 mb-1">{OKTION_VARIABLE?.OKTION_PAYMENT_STATUS}</p>
                <p className="fs-16 mb-1">
                  {OKTION_VARIABLE?.OKTION_SALE_STATUS} :
                  <span className={lotData?.auctionItem?.markAsPaid === true ? 'green-color' : 'red-color'}>
                    {lotData?.auctionItem?.markAsPaid === true
                      ? OKTION_VARIABLE?.OKTION_SOLD
                      : OKTION_VARIABLE?.OKTION_UNSOLD}
                  </span>
                </p>
                <p className="fs-16 mb-1">
                  {OKTION_VARIABLE?.OKTION_PAYMENT_STATUS} :
                  <span className={lotData?.auctionItem?.markAsPaid === true ? 'green-color' : 'red-color'}>
                    {lotData?.auctionItem?.markAsPaid === true
                      ? OKTION_VARIABLE?.OKTION_PAID
                      : OKTION_VARIABLE?.OKTION_UNPAID}
                  </span>
                </p>
                <p className="fs-16 mb-1">
                  {OKTION_VARIABLE?.OKTION_CLAIM_STATUS} :
                  <span className={lotData?.auctionItem?.markAsClaimed === true ? 'green-color' : 'red-color'}>
                    {lotData?.auctionItem?.markAsClaimed === true
                      ? OKTION_VARIABLE?.OKTION_CLAIMED
                      : OKTION_VARIABLE?.OKTION_UNCLAIMED}
                  </span>
                </p>
              </div>
            ) : (
              <div className="mb-4 mt-4">
                <p className="fs-16 fw-500 mb-1">{OKTION_VARIABLE?.OKTION_CLAIM_STATUS}</p>
                <p className="fs-16 mb-1">
                  {OKTION_VARIABLE?.OKTION_WIN_STATUS} :
                  <span className={lotData?.raffleItem?.winner !== null ? 'green-color' : 'red-color'}>
                    {lotData?.raffleItem?.winner !== null ? ' Won' : ' Unwon'}
                  </span>
                </p>
                <p className="fs-16 mb-1">
                  {OKTION_VARIABLE?.OKTION_CLAIM_STATUS} :
                  <span className={lotData?.raffleItem?.markAsClaimed === true ? 'green-color' : 'red-color'}>
                    {lotData?.raffleItem?.markAsClaimed === true ? ' Claimed' : ' Unclaimed'}
                  </span>
                </p>
              </div>
            )}
            {/* {console.log('itemStatus', data.itemStatus)} */}
            {data?.itemStatus === 'unsold' ? (
              <Button className="app-button mt-2 mr-2 mWidth-100 main-button" onClick={handleToggle}>
                {OKTION_VARIABLE?.OKTION_RELIST_LOT}
              </Button>
            ) : (
              <Button
                className={
                  data?.markAsClaimed
                    ? 'app-button mt-2 mr-2 mWidth-100 claimedButton'
                    : 'app-button mt-2 mr-2 mWidth-100 main-button'
                }
                onClick={handleClaimed}>
                {data?.markAsClaimed ? 'Claimed' : 'Unclaimed'}
              </Button>
            )}

            {/*  <div className="mb-4 mt-4">
              <p className="fs-16 fw-500 mb-1">payment Status</p>
              <p className="fs-16 mb-1">
                Sale Status: <span className="red-color">Unwon</span>
              </p>
              <p className="fs-16 mb-1">
                Payment Status: <span className="red-color">Unclaimed</span>
              </p>
              <p className="fs-16 mb-1">
                Claim Status: <span className="red-color">Unclaimed</span>
              </p>
            </div>
            <Button className="app-button mt-2 mr-2 mWidth-100 main-button" onClick={handleToggle}>
              Relist Lot
            </Button> */}
          </div>
        </ModalBody>
      </Modal>

      <RelistingModal isToggle={toggleModal} toggle={handleToggle} data={data} />
    </div>
  );
};

export default ItemDetailsSmallModal;
