import React, { useState, useEffect, useRef, useLayoutEffect, useContext } from 'react';
import Slider from 'react-slick';
// import { ticketCardData } from '../../../UserProfile/UserProfileData';
import TicketsCard from '../../../UserProfile/Frame/ContributionHistory/Frame/TicketsCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../../UserProfile/Frame/ContributionHistory/styles.scss';
import RaffleSlider from './RaffleSlider';
import { Button, Col, Collapse, Row } from 'reactstrap';
import EventTimeDetails from '../EventTimeDetails';
import { images } from '../../../../library/common/constants/ImageConstants';
import RaffleAddToCard from './RaffleAddToCard';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LogoutModal from './LogoutModal';
import moment from 'moment';
import axiosInstance from '../../../../main/axios';
import EventEmitter from 'reactjs-eventemitter';
// import { fetchFromStorage } from '../../../../utility';
// import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import TicketDataModal from '../Tickets/TicketDataModal';
import { SocketContext } from 'main/context/socket';
import ReactToPrint from 'react-to-print';
import { useParams } from 'react-router-dom';
import { fetchFromStorage, saveToStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';

const pageStyle = `
  @page {
    size: 80mm 50mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

const Raffle = props => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const [isToggle, setToggle] = useState(false);
  const [raffleTicket, setRaffleTicket] = useState([]);
  const [ticketItemData, setticketItemData] = useState('');
  const [isToggleModal, setToggleModalValue] = useState(false);
  const [size, setSize] = useState(window.innerWidth);
  const [singleItem, setClickItemData] = useState('');
  const [isabout, setIsAbout] = useState(true);

  const params = useParams();
  var eventId = params?.id;
  const history = useHistory();
  const componentRef = useRef();
  const userDetail = fetchFromStorage(identifiers?.userDetail);

  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  var slidesShow =
    size > 1279
      ? '4'
      : size > 959 && size < 1279
      ? '3'
      : size > 599 && size < 959
      ? '2'
      : size > 320 && size < 599
      ? '1'
      : '1';

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: Number(slidesShow),
    slidesToScroll: 1,
  };

  useEffect(() => {
    props?.isLoggedIn && getTicketPurchase();
  }, [props?.isLoggedIn]);
  const toggle = () => {
    setToggle(!isToggle);
  };
  const handleClick = () => {
    history.push('/login', {
      redirectTo: 'event-details',
      featureName: 'raffle',
      eventId: eventId,
    });
  };
  const handleBtnClick = data => {
    if (!props?.isLoggedIn) {
      setToggle(true);
    } else {
      updateGuestCardList(data);
      history.push('/guest-checkout', {
        eventId: params?.id,
      });
    }
  };

  const updateGuestCardList = data => {
    // Fetch the existing guestCheckoutData from storage or initialize it as an empty array
    let guestCheckoutData = fetchFromStorage(identifiers?.guestcheckoutdata) || [];

    // Check if singleItem is an array or object using optional chaining
    if (data?.constructor === Array) {
      // If it's an array, concatenate it with the existing guestCheckoutData
      guestCheckoutData = [...guestCheckoutData, ...data];
    } else if (data) {
      // If it's an object (not null or undefined), push it to the guestCheckoutData array
      guestCheckoutData.push(data);
    }

    // Save the updated guestCheckoutData to storage
    saveToStorage(identifiers?.guestcheckoutdata, guestCheckoutData);

    // Dispatch an event or perform any other necessary actions
    setTimeout(() => {
      EventEmitter.dispatch('updateguestcheckout', guestCheckoutData);
    }, 300);
  };

  const getTicketPurchase = async () => {
    try {
      // const eventDetails = fetchFromStorage(identifiers.eventDetails);
      const { status, data } = await axiosInstance.get(URLS.getTicketPurchase(eventId, true));

      if (status === 200 || status === 304) {
        setRaffleTicket(data?.data?.tickets);
      }
    } catch (err) {}
  };
  const toggleModal = () => {
    setToggleModalValue(!isToggleModal);
  };

  const onItemClick = item => {
    setticketItemData(item);
    setToggleModalValue(true);
  };
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  var raffleFeatureData = props?.eventFeatures?.filter((item, index) => item.type === 'raffle');

  return (
    <>
      <Row className="res-raffale-prz">
        <Col md="8" lg="9">
          <div>
            {raffleFeatureData[0]?.featureName && (
              <div className="d-flex align-items-center justify-content-between">
                <h4
                  className="pt-4 mb-3 cursor-pointer"
                  onClick={() => {
                    setIsAbout(!isabout);
                  }}>
                  {raffleFeatureData[0]?.featureName}
                </h4>
                <img
                  className={!isabout ? 'closed cursor-pointer' : 'cursor-pointer'}
                  onClick={() => {
                    setIsAbout(!isabout);
                  }}
                  src={images?.uppArraow}
                  alt=""
                  width="15px "
                />
              </div>
            )}
            {raffleFeatureData[0]?.featureAbout != null && (
              <Collapse isOpen={isabout}>
                <p className="fs-16 font-Segoe_UI">
                  {/* {props?.eventDetails?.eventAbout} */}

                  <div>{raffleFeatureData[0]?.featureAbout}</div>
                </p>
              </Collapse>
            )}
          </div>
        </Col>
        <Col md="4" lg="3" className="pt-4 mt-3 mb-3">
          {props?.timeZone !== undefined && (
            <EventTimeDetails
              isLocation
              title={OKTION_VARIABLE?.OKTION_DRAW_DATE}
              img={images.watch}
              address={moment(raffleFeatureData[0]?.startTime).format('DD/MM/YYYY h:mm a')}
              // address={moment(raffleFeatureData[0]?.startTime).utcOffset(props?.timeZone).format('DD/MM/YYYY h:mm a')}
            />
          )}
          {/*   <EventTimeDetails
            title="Raffle Draw Time"
            isLocation 
            img={images.location}
            address="80 Pyrmont St,Pyrmont NSW 2009"
          /> */}
          {props?.eventDetails?.chanceToWinStatus === 1 && (
            <EventTimeDetails
              title={OKTION_VARIABLE?.OKTION_CHANCE_TO_WIN}
              isLocation
              img={images.winIcon}
              address={`${raffleFeatureData[0]?.chanceToWin} ${OKTION_VARIABLE?.OKTION_CHANCE_TO_WIN}`}
            />
          )}
        </Col>

        <div style={{ display: 'none' }}>
          <div ref={componentRef} className="m-2">
            <div className="print-container" style={{ margin: '0', padding: '0' }}>
              {raffleTicket.map((item, index) => (
                <TicketsCard
                  isBarcode={false}
                  isTicket={true}
                  key={index}
                  data={item}
                  showImage={true}
                  handleItemClick={onItemClick}
                  imageUrl={props?.eventDetails?.eventImages[0].name}
                  printImage
                  raffleDrawDate={
                    raffleFeatureData ? moment(raffleFeatureData[0]?.startTime).format('DD/MM/YYYY h:mm a') : null
                  }
                />
              ))}
            </div>
          </div>
        </div>
        <Col sm="12">
          <div className="d-flex justify-content-between align-items-center pt-4 mt-3 mb-1 pl-2 pr-2">
            {raffleTicket && raffleTicket?.length > 0 && (
              <>
                <h4 className="mb-0">{OKTION_VARIABLE?.OKTION_YOUR_RAFFLE}</h4>
                {/* <p className="fw-500 fs-16">Print Tickets</p> */}
                <ReactToPrint
                  pageStyle={pageStyle}
                  trigger={() => <p className="fw-500 fs-16 cursor-pointer">Print Tickets</p>}
                  content={() => componentRef.current}
                />
              </>
            )}
          </div>
          <Slider {...settings}>
            {raffleTicket.map((item, index) => (
              <TicketsCard
                isBarcode={false}
                isTicket={true}
                key={index}
                data={item}
                showImage={true}
                handleItemClick={onItemClick}
                imageUrl={props?.eventDetails?.eventImages[0].name}
                raffleDrawDate={
                  raffleFeatureData ? moment(raffleFeatureData[0]?.startTime).format('DD/MM/YYYY h:mm a') : null
                }
                // raffleDrawDate={
                //   raffleFeatureData ? moment(raffleFeatureData[0]?.startTime).format('DD/MM/YYYY h:mm a') : null
                // }
              />
            ))}
          </Slider>
        </Col>
        <Col lg="9" md="8">
          <h4 className="pt-4 mb-3">{OKTION_VARIABLE?.OKTION_RAFFLE_PRIZE}</h4>
          {raffleFeatureData[0]?.rafflePrizes.map((item, i) => (
            <RaffleSlider data={item} index={i} />
          ))}
        </Col>
      </Row>

      {raffleFeatureData[0]?.ticketBundles.length > 0 && (
        <>
          <Row className="res-raffale-prz direction-reverse">
            <Col sm="12">
              {' '}
              <h4 className="pt-4 mb-3">{OKTION_VARIABLE?.OKTION_RAFFLE_TICKET_BUNDLES}</h4>{' '}
            </Col>
            {raffleFeatureData[0]?.ticketBundles.map((item, i) => (
              <Col lg="3" md="6" sm="12" className="mb-4">
                <RaffleAddToCard
                  key={i}
                  ticketName={item.ticketBundleName}
                  price={item.bundlePrize}
                  noTicket={item.ticketQuantity}
                  item={item}
                  eventDetails={raffleFeatureData[0]}
                  handleBtnClick={data => {
                    handleBtnClick(data);
                  }}
                  islogin={props?.isLoggedIn}
                  eventDetailsData={props?.eventDetails}
                  whitelabel={props?.whitelabel}
                  setClickItemData={data => {
                    setClickItemData(data);
                  }}
                />
              </Col>
            ))}
          </Row>
        </>
      )}
      <div className="create-button-view">
        <Button
          onClick={executeScroll}
          className="main-button btn-block fw-500 h-45 raffle-buy-button"
          disabled={raffleFeatureData[0]?.isDraw === '1' ? true : false}
          style={{
            backgroundColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
            borderColor: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
            color: props?.eventDetails?.primaryTextColour ? props?.eventDetails?.primaryTextColour : '',
          }}>
          {OKTION_VARIABLE?.OKTION_BUY_RAFFLE_TICKET}
        </Button>
      </div>
      <div ref={myRef}>
        <Row className="mt-3">
          <Col sm="12">
            <hr />
          </Col>
        </Row>
      </div>
      <LogoutModal
        isToggle={isToggle}
        toggle={toggle}
        handleClick={handleClick}
        singleItem={singleItem}
        isGuestCheckout={true}
      />
      <TicketDataModal
        isToggle={isToggleModal}
        toggle={toggleModal}
        item={ticketItemData}
        eventDetails={props?.eventDetails}
        raffleDrawDate={raffleFeatureData ? moment(raffleFeatureData[0]?.startTime).format('DD/MM/YYYY h:mm a') : null}
      />
    </>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};
export default connect(mapStateToProps, {})(Raffle);
