import React, { useState, useContext } from 'react';
import './styles.scss';
import Col from 'reactstrap/es/Col';
import Row from 'reactstrap/es/Row';
import AdminTable from './Frame/AdminTable';
import TabWrapper from './TabWrapper';
import AdminTableModal from './AdminTableModal/AdminTableModal';
import InviteUser from './Frame/InviteUser';
import { SocketContext } from '../../../../main/context/socket';

const Admin = () => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  document.title = 'Admin';
  const [isModalToggle, setModalToggle] = useState(false);
  const modalToggle = () => {
    setModalToggle(!isModalToggle);
  };

  return (
    <div className="tickets event-dashboard transfer-ownership">
      <Row className="align-items-center justify-content-lg-between">
        <Col sm="12">
          <h1 className="mb-3 text-center text-lg-left page-title  mb-3 mt-4">
            {OKTION_VARIABLE?.OKTION_ADMIN_MANAGER}
          </h1>
        </Col>
      </Row>
      <div className="row">
        <div className="col-sm-12">
          <AdminTable />
          <InviteUser />
          <TabWrapper updatedModalToggle={modalToggle} />
          {isModalToggle && <AdminTableModal toggle={modalToggle} isToggle={isModalToggle} />}
        </div>
      </div>
    </div>
  );
};

export default Admin;
