import React, { useContext } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { images } from '../../../../../../library/common/constants/ImageConstants';
import { SocketContext } from '../../../../../../main/context/socket';
// import { images } from '../../../../library/common/constants/ImageConstants';

const DeleteFeatureModal = ({ isToggle, toggle, handleDeleteFeature, FROM_WHERE, isEventStatus }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  return (
    <div>
      <Modal
        size="xl"
        className="modal-dialog add-price-modal logout-modal delete-event-Modal delete-lot-modal"
        isOpen={isToggle}>
        <div className="text-right pr-3">
          <img className="cursor-pointer" onClick={toggle} src={images.close} alt="" />
        </div>
        <ModalBody>
          <div>{/* <img src={images.giftImage} alt="" /> */}</div>
          <p className="pt-2 pb-4 title">
            {FROM_WHERE === 'contribution'
              ? OKTION_VARIABLE?.OKTION_UPDATE_CARDDETAILS
              : OKTION_VARIABLE?.OKTION_DELETE_FEATURE_MESSAGE}{' '}
          </p>
          <div className=" d-flex justfycon justify-content-center">
            <Button className="app-button main-button mt-2 mr-2 h-45" onClick={toggle}>
              {OKTION_VARIABLE?.OKTION_BUTTON_CANCEL}
            </Button>
            <Button
              className="app-button  black-button mt-2 mr-2 h-45"
              disabled={isEventStatus === 'Closed' ? true : false}
              onClick={() => {
                handleDeleteFeature();
                toggle();
              }}>
              {FROM_WHERE === 'contribution'
                ? OKTION_VARIABLE?.OKTION_ADD_CARD_DETAILS
                : OKTION_VARIABLE?.OKTION_FEATURE_DELETE}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DeleteFeatureModal;
