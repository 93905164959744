import React, { useContext } from 'react';
import './styles.scss';
import TabWrapper from './TabWrapper';
import { Col, Row } from 'reactstrap';
import { SocketContext } from '../../main/context/socket';

const SupplyItem = () => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  // const [isToggle, setToggle] = useState(false);
  // const toggle = () => {
  //   setToggle(!isToggle);
  // };
  return (
    <div className="container">
      <Row>
        <Col>
          <div className="tickets event-dashboard supply-item">
            <h1 className="mb-5 mt-3 text-center text-lg-left page-title">{OKTION_VARIABLE?.OKTION_SUPPLY_LOT}</h1>
            <div className="row">
              <div className="col-sm-12">
                <TabWrapper />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SupplyItem;
