import React, { useContext, useState, useEffect } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import axiosInstance from 'main/axios';
import { URLS } from 'library/common/constants/UrlConstants';
import PageLoader from 'library/common/components/PageLoader';
import { setApiMessage } from 'library/common/constants/function';
import NoOrgModal from './NoOrganisationModal';
import { ReactComponent as QuickEvent } from '../../../resources/images/quick-event.svg';
import { ReactComponent as NewEvent } from '../../../resources/images/full-event.svg';
import { ReactComponent as Checked } from '../../../resources/images/checked.svg';
import { ReactComponent as Uncheked } from '../../../resources/images/unchecked.svg';
import { ReactComponent as Closed } from '../../../resources/images/close.svg';
import { Radio, FormControlLabel, RadioGroup } from '@material-ui/core';
import { SocketContext } from '../../../main/context/socket';
import { Link, useHistory } from 'react-router-dom';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { eventModalToggle } from '../../../library/common/actions/EventActions';
import { fetchFromStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import NoPublishOrg from './NoPublishOrg';

const NewEventSelectModal = ({ iseventToggle }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedFeature, setSelectedFeature] = useState(null);
  const [featureData, setFeatureData] = useState([]);
  const [selectedFeatureData, setselectedFeatureData] = useState({});
  const [loading, setLoading] = useState(false);
  const [noOrgModalOpen, setNoOrgModalOpen] = useState(false);
  const [noPublishModalOpen, setNoPublishModalOpen] = useState(false);

  const EventFeature = [
    { id: 1, lable: OKTION_VARIABLE?.OKTION_AUCTION, value: 'Auction' },
    { id: 2, lable: OKTION_VARIABLE?.OKTION_DONATION, value: 'Donations' },
    { id: 3, lable: OKTION_VARIABLE?.OKTION_RAFFLE, value: 'Raffle' },
  ];

  const handleEventModal = () => {
    dispatch(eventModalToggle(false));
  };

  useEffect(() => {
    if (iseventToggle) {
      getEvent();
    }
  }, [iseventToggle]);

  const getEvent = async () => {
    try {
      const { status, data } = await axiosInstance.get(URLS.getFetureStatus(true));
      if (status === 200) {
        setFeatureData(data?.features?.features);
      }
    } catch (err) {}
  };

  const handleChangeEvent = e => {
    setSelectedFeature(e.target.value);
    let selectedEvent = featureData?.find(item => {
      return item?.name == e.target.value;
    });
    setselectedFeatureData(selectedEvent);
  };

  const handleEvent = async () => {
    var userDetail = JSON.parse(localStorage.getItem('userDetail'));
    if (userDetail?.CurrentOrgDetails?.status === 'published') {
      if (selectedFeatureData) {
        if (userDetail?.currentOrganisation) {
          setLoading(true);
          const senData = [
            {
              type: 'feature',
              referenceId: selectedFeatureData?.id,
            },
          ];

          try {
            const { status, data } = await axiosInstance.post(URLS.addToCart, senData);
            if (status === 201) {
              setLoading(false);
              dispatch(eventModalToggle(false));
              setSelectedFeature(null);
              history.push('/checkout');
            }
          } catch (err) {
            setLoading(false);
            setApiMessage('error', err?.response?.data?.msg);
          }
        } else {
          dispatch(eventModalToggle(false));
          setNoOrgModalOpen(true);
        }
      }
    } else {
      setNoPublishModalOpen(true);
      dispatch(eventModalToggle(false));
    }
  };

  const handleNoPublishOrgClose = () => {
    setNoPublishModalOpen(false);
  };

  const handleNoPublishOrg = () => {
    const currentOrganisationId = fetchFromStorage(identifiers.currentOrganization);
    history.push(`/organization/${currentOrganisationId}?organiser-details`);
    dispatch(eventModalToggle(false));
    setNoPublishModalOpen(false);
  };

  const handleOkButton = () => {
    setNoOrgModalOpen(false);
    history.push('/organization/organiser-details?organiser-details');
  };

  return (
    <>
      <div>
        <Modal className="modal-dialog event-modal" isOpen={iseventToggle}>
          {loading && <PageLoader />}
          <Closed
            className="model-close"
            onClick={() => {
              handleEventModal();
            }}
          />
          <div className="text-center">
            <h2> {OKTION_VARIABLE?.OKTION_NEW_EVENT}</h2>
          </div>
          <ModalBody>
            <div className="event-grid-wrap">
              <div className="event-content">
                <QuickEvent />
                <div className="event-description">
                  <h4 className="text-center"> {OKTION_VARIABLE?.OKTION_QUICK_EVENT}</h4>
                  <p dangerouslySetInnerHTML={{ __html: OKTION_VARIABLE?.OKTION_QUICK_EVENT_DESC }}></p>
                  <RadioGroup aria-label="Event" name="eventfeature" className="event-radio">
                    {EventFeature?.map(item => {
                      return (
                        <>
                          <FormControlLabel
                            value={item?.value}
                            control={<Radio icon={<Uncheked />} checkedIcon={<Checked />} disableRipple />}
                            label={item?.lable}
                            onChange={e => {
                              handleChangeEvent(e);
                            }}
                          />
                        </>
                      );
                    })}
                  </RadioGroup>
                </div>

                <Button
                  onClick={() => {
                    handleEvent();
                  }}
                  className="event-button blue-button btn btn-secondary"
                  disabled={selectedFeature ? false : true}>
                  {selectedFeatureData?.length > 0
                    ? OKTION_VARIABLE?.OKTION_EVENT_ADDCART + ' ' + '$' + selectedFeatureData?.[0]?.price
                    : OKTION_VARIABLE?.OKTION_ADD_TO_CART}
                </Button>
              </div>
              <div className="event-content">
                {' '}
                <NewEvent />
                <div className="event-description">
                  <h4 className="text-center">{OKTION_VARIABLE?.OKTION_FULL_EVENT}</h4>
                  <p dangerouslySetInnerHTML={{ __html: OKTION_VARIABLE?.OKTION_FULL_EVENT_DESC }}></p>
                  {/* <p>{OKTION_VARIABLE?.OKTION_FULL_EVENT_SECODARY_DESC}</p> */}
                  <p>
                    {OKTION_VARIABLE?.OKTION_FULL_EVENT_SECODARY_DESC_1 +
                      featureData?.[0]?.price?.toFixed(2) +
                      OKTION_VARIABLE?.OKTION_FULL_EVENT_SECODARY_DESC_2}
                  </p>
                </div>
                <Link
                  to="/create-event"
                  onClick={() => {
                    handleEventModal();
                  }}>
                  <Button className="event-button blue-button  btn btn-secondary">
                    {OKTION_VARIABLE?.OKTION_CREATE_FULL_EVENT}
                  </Button>
                </Link>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
      <div>
        <NoOrgModal isToggle={noOrgModalOpen} toggle={setNoOrgModalOpen} handleClick={handleOkButton} />
        <NoPublishOrg isToggle={noPublishModalOpen} toggle={handleNoPublishOrgClose} handleClick={handleNoPublishOrg} />
      </div>
    </>
  );
};

export default NewEventSelectModal;
