import React, { useEffect, useState, useContext } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import AdminsTab from './Frame/AdminsTab';
// import ActionMessage from '../../../../library/common/components/ActionMessage';
// import { setApiMessage } from '../../../../library/common/constants/function';
import { useParams } from 'react-router-dom';
import PageLoader from '../../../../library/common/components/PageLoader';
import { connect } from 'react-redux';
import { handleByRole } from '../../../../library/common/actions/AdminActions';
import { fetchFromStorage } from '../../../../utility';
import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
import { EventEmitter } from 'library/common/constants/event';
import { SocketContext } from '../../../../main/context/socket';

const TabWrapper = props => {
  const orgId = useParams();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [activeTab, setActiveTab] = useState('1');
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const isNewOrg = window.location.pathname === '/organization/organiser-details';

  // const [message, setMessage] = useState({
  //   display: false,
  //   type: '',
  //   message: '',
  // });

  useEffect(() => {
    const user_id = fetchFromStorage(identifiers.userDetail);
    setUserId(user_id?.id);
    let role;
    if (activeTab === '1') {
      role = 'admin';
    } else {
      role = 'manager';
    }
    if (!isNewOrg) {
      props.handleByRole(orgId.id, role);
    }
  }, []);
  const toggle = async tab => {
    if (activeTab !== tab) setActiveTab(tab);
    let role;
    if (tab === '1') {
      role = 'admin';
    } else {
      role = 'manager';
    }
    props.handleByRole(orgId.id, role);

    EventEmitter.dispatch('handle_role_update_list', tab);
  };

  // const handleByRole = async role => {
  //   setLoading(true);
  //   try {
  //     const { status, data } = await axiosInstance.get(URLS.organizationByRole(orgId.id, role));
  //     if (status === 200) {
  //       setUserList(data.data.users);
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     setLoading(false);
  //     if (err.response) {
  //       setActionMessage(true, 'Error', err.response.data.msg);
  //     }
  //   }
  // };

  // const setActionMessage = (display = false, type = '', message = '') => {
  //   setMessage({
  //     display,
  //     type,
  //     message,
  //   });
  // };
  return (
    <div className="tabWithBottomBorder">
      {loading && <PageLoader />}
      <div>
        {/* {message.display && (
          <div className="btn-block">
            <ActionMessage message={message.message} type={message.type} styleClass={message.styleClass} />
          </div>
        )} */}
      </div>
      <h4 className="mb-2 font-weight-normal">{OKTION_VARIABLE?.OKTION_MANAGE_ADMIN_MANAGER}</h4>
      <Nav tabs>
        <NavItem className={`${activeTab === '1' && 'active'}`}>
          <NavLink
            onClick={() => {
              toggle('1');
            }}
            className="tab-design">
            {OKTION_VARIABLE?.OKTION_ADMINS}
          </NavLink>
        </NavItem>
        <NavItem className={`d-flex ${activeTab === '2' && 'active'}`}>
          <NavLink
            onClick={() => {
              toggle('2');
            }}
            className="tab-design">
            {OKTION_VARIABLE?.OKTION_MANAGERS}
          </NavLink>
        </NavItem>
        <div
          id="visibility"
          onClick={() => props.updatedModalToggle()}
          className="question-icon ml-3 d-inline-block tabInfoIcon">
          ?
        </div>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <AdminsTab userlist={props.userList && props.userList} activeTab={activeTab} role="Admin" />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <AdminsTab userlist={props.userList && props.userList} activeTab={activeTab} role="Manager" />
        </TabPane>
      </TabContent>
    </div>
  );
};

const mapStateToProps = ({ adminReducer }) => {
  // console.log('userList', adminReducer.userList);
  return {
    userList: adminReducer.userList,
  };
};

export default connect(mapStateToProps, { handleByRole })(TabWrapper);
