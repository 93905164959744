import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'red' : 'blue',
  }),
  indicatorSeparator: (provided, state) => {
    return { display: 'none' };
  },
  dropdownIndicator: (provided, state) => {
    return {
      borderTop: '8px solid #1D1D1D',
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      borderBottom: 0,
      height: '1px',
      width: '10px',
      marginRight: '14px',
      svg: { display: 'none' },
    };
  },
  control: (provided, state) => {
    return {
      ...provided,
      lineHeight: '24px',
      minHeight: 'auto',
      border: 'none',
      background: 'transparent',
      borderColor: 'inherit',
      ':hover': { border: 'none' },
      height: '100%',
      cursor: 'pointer',
    };
  },
  singleValue: (provided, state) => {
    return { ...provided };
  },
  placeholder: (provided, state) => {
    return { ...provided, top: '30%' };
  },
};

const SelectBoxWithFlag = ({
  selectOptions,
  selectedValue,
  onChange,
  className,
  placeholder,
  is_view,
  isCssFromProps,
  custom_styles,
}) => {
  return (
    <Select
      placeholder={placeholder || 'Select Currency'}
      styles={isCssFromProps ? custom_styles : customStyles}
      theme={theme => ({
        ...theme,
        borderRadius: '3px',
        colors: {
          ...theme.colors,
          primary: '#ffc629',
        },
      })}
      className={className}
      value={selectedValue.countryCode !== undefined && selectedValue}
      disabled={is_view}
      getOptionLabel={option => (
        <>
          <img width="25" style={{ marginRight: '16px' }} src={option.flag} alt="" /> {option.label}
        </>
      )}
      onChange={onChange}
      options={[...selectOptions]}
    />
  );
};

SelectBoxWithFlag.propTypes = {
  selectOptions: PropTypes.array,
  onChange: PropTypes.func,
  selectValue: PropTypes.string,
};

SelectBoxWithFlag.defaultProps = {
  selectOptions: [],
  selectValue: '',
  onChange: () => {},
};

export default SelectBoxWithFlag;
