import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { images } from '../../../../../library/common/constants/ImageConstants';
import RoleTable from './RoleTable';

const AdminTableModal = ({ isToggle, toggle }) => {
  return (
    <div>
      <Modal size="lg" className="customModal add-price-modal draw_winner_modal" isOpen={isToggle}>
        <div className="text-right">
          <img className="cursor-pointer adminrole_closeIcon" onClick={toggle} src={images.close} alt="" />
        </div>
        <ModalBody>
          <div className="draw_table">
            <RoleTable />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AdminTableModal;
