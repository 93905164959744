import React, { useEffect, useState } from 'react';
import { images } from 'library/common/constants/ImageConstants';
import EventDetails from '../../../../EventDetails/EventDetails';
import axiosInstance from 'main/axios';
import { URLS } from 'library/common/constants/UrlConstants';
import { fetchFromStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import './styles.scss';
import { connect } from 'react-redux';

const Preview = props => {
  const eventDetails = fetchFromStorage(identifiers.eventDetails);
  const [eventOutfit, setEventOutfit] = useState([]);
  useEffect(() => {
    getEventDetailsById();
  }, []);
  var eventID = props.location?.state?.eventID;
  const getEventDetailsById = async () => {
    // setloader(true);
    // const eventDetails = fetchFromStorage(identifiers.eventDetails);
    try {
      const { status, data } = await axiosInstance.get(URLS.getPublicEventByid(eventID, 0));
      // setloader(false);
      if (status === 200 || status === 304) {
        setEventOutfit(data?.data?.events);
      }
    } catch (err) {
      // setloader(false);
    }
  };
  // var colorPicker = props.colorData['0'];
  var propsValue = props.history.location.state;

  return (
    <div className="home-container contributorSupplyItems">
      <div className="header">
        <img alt="powered by oktion" className="powered-by" src={images?.poweredBy} />
      </div>
      <div className="banner position-relative">
        <div>
          <EventDetails
            whitelabel={true}
            previewPropsValue={propsValue}
            preview_color={props.colorData['0']}
            preview_colour={props.colorData}
            // hexCode={props.hexCode}
          />
        </div>
        <div className="org-details">
          <div className="text-center mt-3 bottomSection">
            <div className="text-center">
              {/* <img
                className="imgWidth"
                src={identifiers.imageUrlConstant + eventOutfit?.organisation?.imagePath}
                alt=""
              /> */}

              {/*<div className='square'>Company Logo</div>*/}
            </div>
            {/* <h5 className="mt-2 font-weight-normal text-center mb-2">{eventOutfit?.organisation?.name}</h5>
            <p className="fs-16 pl-5 pr-5">{eventOutfit?.organisation?.aboutTheOrganisation}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ eventReducer }) => {
  return {
    colorData: eventReducer.colorData,
  };
};

export default connect(mapStateToProps, {})(Preview);
