import React, { useContext } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { SocketContext } from 'main/context/socket';
import { ReactComponent as VersioControl } from 'resources/images/version-update.svg';

const VersionConroll = ({ isToggle }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  return (
    <Modal
      size="xl"
      className="modal-dialog verison-modal-wrap d-flex align-items-center text-center w-100"
      isOpen={isToggle}
      centered>
      <div className="text-right"></div>
      <ModalBody>
        <div className="version-content p-lg-2 p-md-2 p-0">
          <VersioControl className="w-100" />
          <h3 className="pt-4 pb-lg-3 pb-md-3 pb-1 mb-1 "> {OKTION_VARIABLE?.OKTION_VERSION_UPGRADE}</h3>
          <p dangerouslySetInnerHTML={{ __html: OKTION_VARIABLE?.OKTION_VERSION_UPGRADE_DESC }} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default VersionConroll;
