import React, { useContext, useState } from 'react';
import { Button, Card, CardBody, CardImg } from 'reactstrap';
import PropTypes from 'prop-types';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { images } from 'library/common/constants/ImageConstants';
import { saveToStorage } from '../../../../utility';
import './styles.scss';
import { identifiers } from '../../constants/IdentifierConstants';
// import { timeDiffCalc } from 'library/common/constants/function';
import moment from 'moment';
import renderHTML from 'react-render-html';
import anchorme from 'anchorme';
import axiosInstance from 'main/axios';
import { URLS } from 'library/common/constants/UrlConstants';
import PageLoader from 'library/common/components/PageLoader';
import Countdown from 'react-countdown';
import Tooltip from '@mui/material/Tooltip';
import { SocketContext } from 'main/context/socket';
import { setApiMessage } from 'library/common/constants/function';

dayjs.extend(relativeTime);
var CurrencyFormat = require('react-currency-format');

const EventCard = ({
  key,
  types,
  image,
  event,
  isHorizontal,
  isManaged,
  isContributions,
  isFollow,
  handleEventList,
  isFromSearch,
  // setActionMessage,
}) => {
  const history = useHistory();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const [loading, setLoader] = useState(false);
  const handleManage = event => {
    // saveToStorage(identifiers.eventDetails, event);
    var redirectUrl =
      event?.featureType[0] === 'auction'
        ? 'auctionquick'
        : event?.featureType[0] === 'donation'
        ? 'donationquick'
        : event?.featureType[0] === 'raffle'
        ? 'rafflequick'
        : 'event=dashboard';
    // setTimeout(() => {
    if (event?.eventType === 'quick') {
      history.push(`/my-event/${event?.id}?event=${redirectUrl}`);
    } else {
      history.push(`/my-event/${event?.id}?event=details`);
    }
    // }, 300);

    // history.push(`/my-event?Tickets/${id}`);
  };
  const handleLocation = () => {
    const location = event?.location;
    if (location === 1) {
      return OKTION_VARIABLE?.OKTION_ONLINE;
    } else if (location === 2) {
      return OKTION_VARIABLE?.OKTION_VENUE;
    } else if (location === 3) {
      return OKTION_VARIABLE?.OKTION_ONLINE_VENUE;
    }
  };
  const handleEventLocation = () => {
    const location = event?.location;
    if (location === 1) {
      return OKTION_VARIABLE?.OKTION_ONLINE;
    } else if (location === 2) {
      return OKTION_VARIABLE?.OKTION_VENUE;
    } else if (location === 3) {
      return OKTION_VARIABLE?.OKTION_ONLINE_VENUE;
    }
  };
  const handleStatus = () => {
    const status = event?.status;
    /*   if (status === 'published') {
      return 'Active';
    } else if (status === 'saved') {
      return 'Draft';
    } else {
      return 'Closed';
    } */
    let today = moment(new Date()).format('YYYY-MM-DD');
    let current_time = moment(new Date()).format('HHmm');
    if (status === 'suspended') {
      return 'Suspended';
    } else if (status === 'saved') {
      let eventEndDate = moment(event?.endDate).format('YYYY-MM-DD');
      let eventEndTime = moment(event?.endDate).format('HHmm');
      if (moment(today).isAfter(eventEndDate)) {
        return 'Closed';
      } else {
        return 'Draft';
      }
    } else {
      let eventEndDate = moment(event?.endDate).format('YYYY-MM-DD');
      let eventEndTime = moment(event?.endDate).format('HHmm');
      if (moment(today).isAfter(eventEndDate)) {
        return 'Closed';
      } else {
        if (today === eventEndDate && eventEndTime < current_time) {
          return 'Closed';
        } else {
          return 'Active';
        }
      }
    }
  };

  const handleClick = () => {
    history.push(`/event-details/${event?.id}`);
    // saveToStorage(identifiers.eventDetails, event);
    // saveToStorage(identifiers.publicEventDetails, event);
  };
  const handleMyContribution = event => {
    history.push(`/my-event-page/contribution/${event?.id}`, { event });
    // saveToStorage(identifiers.eventDetails, event);
  };
  const handleOnClick = () => {
    history.push(`/event-details/${event?.id}`);
    // saveToStorage(identifiers.eventDetails, event);
    // saveToStorage(identifiers.publicEventDetails, event);
  };
  const handleUnfollow = async event => {
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.post(URLS.eventUnFollow(event?.id));
      handleEventList();
      setApiMessage('success', OKTION_VARIABLE?.OKTION_REMOVE_FOLLOWING_LIST);
      // setActionMessage(true, 'Success', OKTION_VARIABLE?.OKTION_REMOVE_FOLLOWING_LIST);
      // setTimeout(() => {
      //   setActionMessage(false);
      // }, 3000);
    } catch {}
    setLoader(false);
  };
  const renderTime = ({ hours, minutes, days, seconds }) => {
    return (
      <>
        <div>
          {days > 0 ? days : 0} {days === 0 || days === 1 ? ' day' : ' days'} {hours > 0 ? hours : 0}{' '}
          {hours === 0 || hours === 1 ? ' hour' : ' hours'} {minutes > 0 ? minutes : 0}{' '}
          {minutes === 0 || minutes === 1 ? ' minute' : ' minutes'} left
        </div>
      </>
    );
  };
  var today = moment();
  var endDate = moment(event?.endDate && event?.endDate);
  let totalDays = 0;
  let totalHours = 0;
  let clearHours = 0;
  let totalMinutes = 0;
  let clearMinutes = 0;
  totalDays = endDate.diff(today, 'days');
  totalHours = endDate.diff(today, 'hours');
  clearHours = totalHours % 24;
  totalMinutes = endDate.diff(today, 'minutes');
  clearMinutes = totalMinutes % 60;
  if (isHorizontal) {
    return (
      <>
        {loading === true && <PageLoader />}
        <Card className={`shadow-sm event-card ${isHorizontal && 'isHorizontal'}`}>
          <CardBody>
            <div className="row">
              <div
                // className="col-xl-3 col-md-3 "
                className={isFollow === true ? 'col-xl-3 col-md-3 cursor-pointer' : 'col-xl-3 col-md-3'}
                onClick={isFollow === true && handleOnClick}>
                {event?.isPaidEventPlace ? <img src={images.badge} className="card-badge" alt="" /> : ''}
                {/* {event?.isPremiumPlacement === 1 ? <img src={images.badge} className="card-badge" alt="" /> : ''} */}
                {event?.eventImages ? (
                  <>
                    {event?.eventImages[0]?.name === undefined || event?.eventImages[0]?.name === '' ? (
                      <div className="defaultNoImageBackground" />
                    ) : (
                      <CardImg
                        top
                        width="100%"
                        src={identifiers.imageUrlConstant + '' + event?.eventImages[0]?.name}
                        alt="Card image cap"
                      />
                    )}
                  </>
                ) : (
                  <div className="defaultNoImageBackground" />
                )}
              </div>
              <div
                className={isFollow === true ? 'col-xl-7 col-md-7 cursor-pointer' : 'col-xl-6 col-md-6'}
                onClick={isFollow === true && handleOnClick}>
                <div className="card_content">
                  <span className="event-type">{handleEventLocation()}</span>
                  <p className="event-date mt-0">
                    {event?.endDate && dayjs(event?.endDate).format('DD MMM YYYY')}{' '}
                    <span className="event-time">{event?.endDate && dayjs(event?.endDate).format('hh:mm A')}</span>
                  </p>{' '}
                  {isFollow === true ? (
                    <button className="eventname-button" onClick={handleOnClick}>
                      {event?.eventName &&
                        renderHTML(
                          anchorme({
                            input: (event?.eventName).toString().replace(/\n/g, '<br/>'),
                            options: {
                              attributes: {
                                target: '_blank',
                                class: 'detected',
                              },
                            },
                          }),
                        )}
                    </button>
                  ) : isContributions !== true ? (
                    <p className="event-name">
                      {event?.eventName &&
                        renderHTML(
                          anchorme({
                            input: (event?.eventName).toString().replace(/\n/g, '<br/>'),
                            options: {
                              attributes: {
                                target: '_blank',
                                class: 'detected',
                              },
                            },
                          }),
                        )}
                    </p>
                  ) : (
                    // <p className="event-name">{event?.eventName}</p>
                    <button className="eventname-button" onClick={handleOnClick}>
                      {event?.eventName &&
                        renderHTML(
                          anchorme({
                            input: (event?.eventName).toString().replace(/\n/g, '<br/>'),
                            options: {
                              attributes: {
                                target: '_blank',
                                class: 'detected',
                              },
                            },
                          }),
                        )}
                    </button>
                  )}
                  {/*   {event?.endDate && (
                  <p className="time-left mb-1">{timeDiffCalc(new Date(), new Date(moment(event?.endDate)))}</p>
                )} */}{' '}
                  {/*  {event?.endDate && (
                  <p className="time-left mb-1">
                    {totalDays > 0 ? totalDays : 0} {totalDays > 0 ? ' days' : ' day'} {clearHours > 0 ? clearHours : 0}{' '}
                    {clearHours > 0 ? ' hours' : ' hour'} {clearMinutes > 0 ? clearMinutes : 0}{' '}
                    {clearMinutes > 0 ? ' minutes' : ' minute'} left
                  </p>
                )} */}
                  {event?.endDate ? (
                    handleStatus() === 'Closed' ? (
                      <p className="time-left mb-1 red-color">{OKTION_VARIABLE?.OKTION_EVENT_CLOSE}</p>
                    ) : (
                      <p className="time-left mb-1">
                        {/*  {totalDays > 0 ? totalDays : 0} {totalDays > 0 ? ' days' : ' day'}{' '}
                        {clearHours > 0 ? clearHours : 0} {clearHours > 0 ? ' hours' : ' hour'}{' '}
                        {clearMinutes > 0 ? clearMinutes : 0} {clearMinutes > 0 ? ' minutes' : ' minute'} left{' '} */}
                        <Countdown date={endDate} renderer={renderTime} key={endDate} />
                      </p>
                    )
                  ) : null}
                  {event?.raisedAmountStatus === 1 && (
                    <p className="amount-raised">
                      <span className="fw-500">
                        <CurrencyFormat
                          value={event?.amountRaised || '0.00'}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={`${event?.currency !== null ? event?.currency?.abbreviation : ''} ${
                            event?.currency !== null ? event?.currency?.symbol : ''
                          }`}
                          thousandSpacing={
                            event?.currency !== null ? (event?.currency?.symbol === '₹' ? '2s' : '3') : ''
                          }
                          decimalScale="2"
                          fixedDecimalScale={true}
                        />
                        {/* {event?.currency != null && `${event?.currency?.abbreviation} ${event?.currency?.symbol}`}
                    {isNaN(parseFloat(event?.amountRaised).toFixed(2))
                      ? '0.00'
                      : parseFloat(event?.amountRaised).toFixed(2)} */}
                      </span>{' '}
                      {OKTION_VARIABLE?.OKTION_RAISED}
                    </p>
                  )}
                </div>
              </div>
              <div className={isFollow === true ? 'col-xl-2 col-md-3' : 'col-xl-3 col-md-3 img-section'}>
                <div className="card_content">
                  <p
                    className={
                      handleStatus() === 'Active'
                        ? `active_link_color ${handleStatus()}`
                        : handleStatus() === 'Draft'
                        ? `draft_link_color ${handleStatus()}`
                        : handleStatus() === 'Closed'
                        ? `close_link_color ${handleStatus()}`
                        : handleStatus() === 'Suspended'
                        ? `suspended_link_color ${handleStatus()}`
                        : ''
                    }>
                    {handleStatus()}
                  </p>
                  {isFollow !== true && (
                    <p className="public_text">
                      {event.isPublic === 1 ? (isFromSearch ? '' : 'Public') : isFromSearch ? '' : 'Private'}
                    </p>
                  )}
                  <div className="extra-info">
                    {event?.auction && (
                      <Tooltip title={OKTION_VARIABLE?.OKTION_AUCTION} arrow>
                        <img src={images.auction} alt="auction" />
                      </Tooltip>
                    )}
                    {event?.donation && (
                      <Tooltip title={OKTION_VARIABLE?.OKTION_DONATION} arrow>
                        <img src={images.donation} alt="donation" />
                      </Tooltip>
                    )}
                    {event?.raffle && (
                      <Tooltip title={OKTION_VARIABLE?.OKTION_RAFFLE} arrow>
                        <img src={images.raffle} alt="auction" />
                      </Tooltip>
                    )}
                    {event?.ticket && (
                      <Tooltip title={OKTION_VARIABLE?.OKTION_TICKET} arrow>
                        <img src={images.tickets} alt="tickets" />
                      </Tooltip>
                    )}
                  </div>
                  {isManaged && (
                    <Button
                      onClick={() => {
                        saveToStorage(identifiers.eventDetails, event);
                        setTimeout(() => {
                          handleManage(event);
                        }, 300);
                      }}
                      className="main-button btn-block mt-3 button-md">
                      {OKTION_VARIABLE?.OKTION_FEATURE_MANAGE}
                    </Button>
                  )}
                  {/* {console.log('event', event)} */}
                  {isContributions && (
                    <Button
                      onClick={() => handleMyContribution(event)}
                      className="main-button btn-block mt- 2 my-contribution-button">
                      {OKTION_VARIABLE?.OKTION_MY_CONTRIBUTION}
                    </Button>
                  )}
                  {isFollow === true && (
                    <Button onClick={() => handleUnfollow(event)} className="main-button btn-block mt-2 fw-500">
                      {OKTION_VARIABLE?.OKTION_UNFOLLOW}
                      {/* onClick={() => EventEmitter.dispatch('unFollowEvent', event?.id)} */}
                    </Button>
                  )}
                </div>
              </div>
              {/* <div className="extra-info-smallscreen">
                {event?.auction && <img src={images.auction} alt="tickets" />}
                {event?.donation && <img src={images.donation} alt="donation" />}
                {event?.raffle && <img src={images.raffle} alt="raffle" />}
                {event?.ticket && <img src={images.tickets} alt="tickets" />}
              </div> */}
              {isContributions && (
                <Button
                  onClick={() => handleMyContribution(event)}
                  className="main-button btn-block mt- 2 my-contribution-button-smallscreen">
                  {OKTION_VARIABLE?.OKTION_MY_CONTRIBUTION}
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
  var currentDate = new Date();
  var isPastDate = moment(currentDate).isAfter(new Date(moment(event?.endDate)));

  return (
    <>
      <Card className="shadow-sm event-card responsive_card" onClick={handleClick}>
        <div className="card_image">
          {event?.isPaidEventPlace ? <img src={images.badge} className="card-badge" alt="" /> : ''}
          {/* {event?.isPremiumPlacement === 1 ? <img src={images.badge} className="card-badge" alt="" /> : ''} */}
          {event?.eventImages ? (
            <CardImg
              top
              width="100%"
              src={identifiers.imageUrlConstant + '' + event?.eventImages[0]?.name}
              alt="Card image cap"
            />
          ) : (
            <div className="noImage"></div>
          )}
        </div>
        <div class="card_content">
          <CardBody>
            {/* <span className="event-type">{types.join(' + ')}</span> */}
            <span className="event-type">{handleLocation()}</span>
            <p className="event-date">
              {event.endDate && dayjs(event.endDate).format('DD MMM YYYY')}{' '}
              <span className="event-time">{event.endDate && dayjs(event.endDate).format('hh:mm A')}</span>
            </p>
            <p className="event-name">
              {event?.eventName !== null &&
                renderHTML(
                  anchorme({
                    input: event?.eventName && event?.eventName?.toString().replace(/\n/g, '<br/>'),
                    options: {
                      attributes: {
                        target: '_blank',
                        class: 'detected',
                      },
                    },
                  }),
                )}
            </p>
            {event.startDate && (
              <>
                {!isPastDate ? (
                  <p className="time-left">
                    {/* {timeDiffCalc(new Date(), new Date(moment(event?.endDate)))} */}
                    <Countdown date={endDate} renderer={renderTime} key={endDate} />
                  </p>
                ) : (
                  <p className="text-danger mb-2">{OKTION_VARIABLE?.OKTION_EVENT_CLOSE_MESSAGE}</p>
                )}
              </>
            )}
            {event?.raisedAmountStatus === 1 && (
              <p className="amount-raised">
                <span className="fw-500">
                  <CurrencyFormat
                    value={event?.amountRaised || '0.00'}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={`${event?.currency !== null ? event?.currency?.abbreviation : ''} ${
                      event?.currency !== null ? event?.currency?.symbol : ''
                    }`}
                    thousandSpacing={event?.currency !== null ? (event?.currency?.symbol === '₹' ? '2s' : '3') : ''}
                    decimalScale="2"
                    fixedDecimalScale={true}
                  />
                  {/* {event?.currency != null && `${event?.currency?.abbreviation} ${event?.currency?.symbol}`}
              {isNaN(parseFloat(event?.amountRaised).toFixed(2)) ? '0.00' : parseFloat(event?.amountRaised).toFixed(2)} */}
                </span>{' '}
                {OKTION_VARIABLE?.OKTION_RAISED}
              </p>
            )}
            {/* <div className="extra-info">
            {event?.featureTypes?.includes('oktion') && <img src={images.auction} alt="" />}
            {event?.featureTypes?.includes('donation') && <img src={images.donation} alt="" />}
            {event?.featureTypes?.includes('raffle') && <img src={images.raffle} alt="" />}
            {event?.featureTypes?.includes('ticket') && <img src={images.tickets} alt="" />}
          </div> */}
            <div className="extra-info">
              {event?.auction && (
                <Tooltip title={OKTION_VARIABLE?.OKTION_AUCTION} arrow>
                  <img src={images.auction} alt="auction" />
                </Tooltip>
              )}
              {event?.donation && (
                <Tooltip title={OKTION_VARIABLE?.OKTION_DONATION} arrow>
                  <img src={images.donation} alt="donation" />
                </Tooltip>
              )}
              {event?.raffle && (
                <Tooltip title={OKTION_VARIABLE?.OKTION_RAFFLE} arrow>
                  <img src={images.raffle} alt="raffle" />
                </Tooltip>
              )}
              {event?.ticket && (
                <Tooltip title={OKTION_VARIABLE?.OKTION_TICKET} arrow>
                  <img src={images.tickets} alt="tickets" />
                </Tooltip>
              )}
            </div>
          </CardBody>
        </div>
      </Card>
    </>
  );
};

EventCard.propTypes = {
  image: PropTypes.string.isRequired,
  types: PropTypes.array.isRequired,
  event: PropTypes.object.isRequired,
  isHorizontal: PropTypes.bool,
  isManaged: PropTypes.bool,
  isContributions: PropTypes.bool,
};

EventCard.defaultProps = {
  isHorizontal: false,
  isManaged: false,
  isContributions: false,
};

export default EventCard;
