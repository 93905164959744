import React, { useContext } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { images } from '../../../../library/common/constants/ImageConstants';
import { SocketContext } from '../../../../main/context/socket';
import { useHistory, useParams } from 'react-router-dom';
import { fetchFromStorage, saveToStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import EventEmitter from 'reactjs-eventemitter';

const LogoutModal = ({
  isToggle,
  toggle,
  handleClick,
  singleItem,
  isGuestCheckout,
  guestUserStatus,
  isfromWinningLot,
  lotId,
  data,
  bidType,
  bidCount,
}) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const history = useHistory();
  const params = useParams();

  const updateGuestCardList = () => {
    // Fetch the existing guestCheckoutData from storage or initialize it as an empty array
    let guestCheckoutData = fetchFromStorage(identifiers?.guestcheckoutdata) || [];

    // Check if singleItem is an array or object using optional chaining
    if (singleItem?.constructor === Array) {
      // If it's an array, concatenate it with the existing guestCheckoutData
      guestCheckoutData = [...guestCheckoutData, ...singleItem];
    } else if (singleItem) {
      // If it's an object (not null or undefined), push it to the guestCheckoutData array
      guestCheckoutData.push(singleItem);
    }

    // Save the updated guestCheckoutData to storage
    saveToStorage(identifiers?.guestcheckoutdata, guestCheckoutData);

    // Dispatch an event or perform any other necessary actions
    setTimeout(() => {
      EventEmitter.dispatch('updateguestcheckout', guestCheckoutData);
    }, 300);
  };

  const handleGuestChekout = () => {
    if (guestUserStatus) {
      history.push('/guest-signup', {
        eventId: params?.id,
        isAuction: true,
        singleItem: data,
        bidCount: bidCount,
        bidType: bidType,
      });
    } else {
      updateGuestCardList(singleItem);
      history.push('/guest-checkout', {
        eventId: params?.id,
      });
    }
  };

  return (
    <div>
      <Modal
        size="xl"
        className="modal-dialog add-price-modal logout-modal"
        isOpen={isToggle}
        toggle={() => toggle(false)}>
        <div className="text-right">
          <img className="cursor-pointer" onClick={toggle} src={images.close} alt="" />
        </div>
        <ModalBody>
          <div>
            <img src={images.giftImage} alt="" />
          </div>
          <h3 className="pt-4 pb-4">
            {isGuestCheckout ? OKTION_VARIABLE?.OKTION_MAKE_CONTRIBUTE_GUEST : OKTION_VARIABLE?.OKTION_MAKE_CONTRIBUTE}
          </h3>
          <div className=" d-flex justfycon justify-content-center">
            <Button className="app-button main-button mt-2 mr-2 h-45" onClick={handleClick}>
              {OKTION_VARIABLE?.OKTION_LOGIN}
            </Button>
            {isGuestCheckout && (
              <Button
                style={{ width: '45%' }}
                className="app-button blue-button mt-2 h-45"
                onClick={() => {
                  handleGuestChekout();
                  // updateGuestCardList(singleItem);
                }}>
                {guestUserStatus
                  ? OKTION_VARIABLE?.OKTION_CONTINUE_AS_GUEST
                  : OKTION_VARIABLE?.OKTION_CHECKOUT_AS_GUEST}
              </Button>
            )}
            {isfromWinningLot && (
              <Button
                style={{ width: '45%' }}
                className="app-button blue-button mt-2 h-45"
                onClick={() => {
                  history.push(`/guest-as-email`, {
                    lotId: data?.id,
                    singleItem: singleItem,
                  });
                }}>
                {OKTION_VARIABLE?.OKTION_CHECKOUT_AS_GUEST}
              </Button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LogoutModal;
