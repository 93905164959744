import * as actionTypes from '../constants/StoreConstants';

const initialState = {
  eventByIdData: {},
  colorData: {},
  ModalToggle: false,
};

const EventReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.storeConstants.GET_EVENT:
      return {
        ...state,
        eventByIdData: action.payload,
      };
    case actionTypes.storeConstants.SET_PREVIEWCOLOR:
      return {
        ...state,
        colorData: action.payload,
      };
    case actionTypes.storeConstants.EVENT_MODAL:
      return {
        ...state,
        ModalToggle: action.payload,
      };
    default:
      return state;
  }
};

export default EventReducer;
