export const images = {
  logo: require('resources/images/logo.svg'),
  badge: require('resources/images/badge.svg'),
  raffle: require('resources/images/raffle.svg'),
  tickets: require('resources/images/tickets.svg'),
  donation: require('resources/images/donation.svg'),
  auction: require('resources/images/auction.svg'),
  mainBg: require('resources/images/main-bg.png'),
  heroBg: require('resources/images/hero-bg.png'),
  mapMarker: require('resources/images/map-marker.svg'),
  mapMarkerYellow: require('resources/images/map-marker-yellow.svg'),
  mapMarkerBlack: require('resources/images/map-marker-black.svg'),
  eventImage1: require('resources/images/event-image.png'),
  eventImage2: require('resources/images/event-image-2.png'),
  eventImage3: require('resources/images/event-image-3.png'),
  general: require('resources/images/general.svg'),
  calendar: require('resources/images/calendar.svg'),
  presentation: require('resources/images/presentation.svg'),
  user: require('resources/images/user.svg'),
  dgLogo: require('resources/images/dg-logo.png'),
  jumpman: require('resources/images/jumpman.png'),
  cartItem: require('resources/images/cart-item.svg'),
  visaLogo: require('resources/images/visa-logo.png'),
  visaLogoBig: require('resources/images/visa-logo-big.png'),
  dgLogoBig: require('resources/images/dg-logo-big.png'),
  subscription: require('resources/images/subscription.png'),
  rafelIcon: require('resources/images/event-thumb.svg'),
  draft: require('resources/images/draft.svg'),
  price: require('resources/images/price.svg'),
  ticket: require('resources/images/ticket.svg'),
  auctionWithBorder: require('resources/images/auctionWithBorder.svg'),
  donationWithBorder: require('resources/images/donationWithBorder.svg'),
  causeWithBorder: require('resources/images/causeWithBorder.svg'),
  giftWithBorder: require('resources/images/giftWithBorder.svg'),
  priceWithBorder: require('resources/images/priceWithBorder.svg'),
  up: require('resources/images/up.png'),
  bulb: require('resources/images/bulb.png'),
  delete: require('resources/images/trash.svg'),
  deleteWithCircle: require('resources/images/deleteWithCircle.svg'),
  ticketAuction: require('resources/images/ticketAuction.svg'),
  uppArraow: require('resources/images/uppArrow.png'),
  downArrow: require('resources/images/downArrow.png'),
  down_arrow: require('resources/images/down_arrow.png'),
  ticketDonation: require('resources/images/ticketDonation.svg'),
  close: require('resources/images/close.png'),
  draft_drawer: require('resources/images/draft_drawer.png'),
  launch: require('resources/images/launch.png'),
  wonPriceCard1: require('resources/images/wonPriceCard1.png'),
  raffleWallImg: require('resources/images/raffle_wall_img.png'),
  raffleWallCardImg2: require('resources/images/raffleWallCardImg2.png'),
  congratulation: require('resources/images/congratulation.png'),
  itemcardOverlayImage: require('resources/images/itemcardOverlayImage.png'),
  prizeIcon: require('resources/images/prizeIcon.svg'),
  hamberger: require('resources/images/hamberger.png'),
  scanCode: require('resources/images/scanCode.png'),
  launchAuctionWallIMg1: require('resources/images/launchAuctionWallIMg1.png'),
  launchAuctionWallIMg2: require('resources/images/launchAuctionWallIMg2.png'),
  auctionWallPriceImg: require('resources/images/auctionWallPriceImg.png'),
  Menu: require('resources/images/Menu.png'),
  blackDelete: require('resources/images/black-del.png'),
  closeWithCircle: require('resources/images/closeWithCircle.png'),
  yellowTick: require('resources/images/yellowTick.png'),
  winInactive: require('resources/images/user-profile/winInactive.svg'),
  winActive: require('resources/images/user-profile/winActive.svg'),
  personalDetailActive: require('resources/images/user-profile/personal-detail-active.svg'),
  personalDetailInActive: require('resources/images/user-profile/personal-detail-inactive.svg'),
  cardDetailsActive: require('resources/images/user-profile/cardDetails-active.svg'),
  cardDetailsInActive: require('resources/images/user-profile/cardDetails-inactive.svg'),
  passwordActive: require('resources/images/user-profile/password-active.svg'),
  passwordInActive: require('resources/images/user-profile/password-inactive.svg'),
  subscriptionActive: require('resources/images/user-profile/subscription-active.svg'),
  subscriptionInActive: require('resources/images/user-profile/subscription-inactive.svg'),
  privacyPolicyActive: require('resources/images/user-profile/privacyPolicy-active.svg'),
  privacyPolicyInActive: require('resources/images/user-profile/privacyPolicy-inactive.svg'),
  eventHistoryActive: require('resources/images/user-profile/eventHistory-active.svg'),
  eventHistoryInActive: require('resources/images/user-profile/eventHistory-inactive.svg'),
  contributionInactive: require('resources/images/user-profile/contributionActive.svg'),
  contributionActive: require('resources/images/user-profile/contributionInactive.svg'),
  subscriptionImg: require('resources/images/user-profile/subscriptionImg.png'),
  subscriptionTopImg: require('resources/images/user-profile/SubscriptionTopImg.png'),
  subscriptionExicutiveImg: require('resources/images/user-profile/subscriptionExicutiveImg.png'),
  subscriptionOneOfEvent: require('resources/images/user-profile/subscriptionOneOfEvent.png'),
  currentBid: require('resources/images/currentBid.svg'),
  startBid: require('resources/images/startBid.svg'),
  bidIncrement: require('resources/images/bidIncrement.svg'),
  winLosSliderImg: require('resources/images/user-profile/winLosSliderImg.png'),
  winlossModalImg1: require('resources/images/user-profile/winlossModalImg1.png'),
  winlossModalImg2: require('resources/images/user-profile/winlossModalImg2.png'),
  winlossModalImg3: require('resources/images/user-profile/winlossModalImg3.png'),
  donationImg: require('resources/images/user-profile/donationImg.png'),
  barcodeImg: require('resources/images/user-profile/barcode.png'),
  circleWithYellowTick: require('resources/images/user-profile/circleWithYellowTick.png'),
  Visa_Logo_md: require('resources/images/user-profile/Visa_Logo-md.png'),
  calendorWithCircle: require('resources/images/calendorWithCircle.png'),
  listview: require('resources/images/listview.png'),
  prevIcon: require('resources/images/prevIcon.png'),
  nextIcon: require('resources/images/nextIcon.png'),
  eventDetailsBanner: require('resources/images/eventDetailsBanner.png'),
  location: require('resources/images/location.png'),
  watch: require('resources/images/watch.png'),
  nikeImg: require('resources/images/nike.png'),
  banner_con1: require('resources/images/banner_con1.png'),
  networkIcon: require('resources/images/networkIcon.png'),
  dateCalendar: require('resources/images/date-calendor.png'),
  winIcon: require('resources/images/winIcon.png'),
  tick: require('resources/images/tick.png'),
  admins_active: require('resources/images/admins_active.svg'),
  admins_inActive: require('resources/images/admins_inActive.svg'),
  attendees_active: require('resources/images/manage-event/attendees_active.svg'),
  attendees_inActive: require('resources/images/manage-event/attendees_inActive.svg'),
  table_active: require('resources/images/manage-event/table_active.svg'),
  table_inActive: require('resources/images/manage-event/table_inActive.svg'),
  event_dashboard_active: require('resources/images/manage-event/event_dashboard_active.svg'),
  event_dashboard_inActive: require('resources/images/manage-event/event_dashboard_inActive.svg'),
  event_details_active: require('resources/images/manage-event/event_details_active.svg'),
  event_details_inActive: require('resources/images/manage-event/event_details_inActive.svg'),
  feature_active: require('resources/images/manage-event/feature_active.svg'),
  feature_inActive: require('resources/images/manage-event/feature_inActive.svg'),
  sponsor_active: require('resources/images/manage-event/sponsor_active.svg'),
  sponsor_inActive: require('resources/images/manage-event/sponsor_inActive.svg'),
  whiteLabel_active: require('resources/images/manage-event/whiteLabel_active.svg'),
  whiteLabel_inActive: require('resources/images/manage-event/whiteLabel_inActive.svg'),
  search: require('resources/images/search.png'),
  checkIcon: require('resources/images/checkIcon.png'),
  warningIcon: require('resources/images/iconWarning.png'),
  infoIcon: require('resources/images/infoIcon.png'),
  DropdownDown: require('resources/images/DropdownDown.png'),
  bankingDetail: require('resources/images/bankingDetails.svg'),
  building_with_bg: require('resources/images/general_building_icon_bg.svg'),
  building_without_bg: require('resources/images/general_building_icon.svg'),
  edit_raffle: require('resources/images/edit.svg'),
  grid_view: require('resources/images/grid.svg'),
  yellowArrow: require('resources/images/rightArrow.png'),
  mastercardLogo: require('resources/images/user-profile/Mastercard-logo.png'),
  preview: require('resources/images/preview.png'),
  johnStephenson: require('resources/images/johnStephenson.png'),
  giftImage: require('resources/images/gift-image.png'),
  cart: require('resources/images/cart.png'),
  SendIcon: require('resources/images/send-icon.svg'),
  Noprofile: require('resources/images/no-profile.png'),
  rectangle: require('resources/images/Rectangle.svg'),
  supplyLot: require('resources/images/supplyLot.svg'),
  claimed: require('resources/images/claimed.png'),
  unClaimed: require('resources/images/unclaimed.png'),
  cartItemWithBorder: require('resources/images/cartitemWithborder.svg'),
  PlaceHolderImage: require('resources/images/PlaceHolderImage.png'),
  poweredByOktion: require('resources/images/poweredByOktion.svg'),
  poweredBy: require('resources/images/powered-by.png'),
  whiteLabelAddToCart: require('resources/images/whitelabel_addToCart.svg'),
  donationChart: require('resources/images/donation-chart.svg'),
  donationSmallChart: require('resources/images/donation-small-chart.svg'),
  unionpayLogo: require('resources/images/unionpay-logo.png'),
  jcbLogo: require('resources/images/jcb-logo.png'),
  datepicker: require('resources/images/watch.svg'),
  followIcon: require('resources/images/follow-icon.svg'),
  locationIcon: require('resources/images/location.svg'),
  locationIconGray: require('resources/images/locationIconGray.svg'),
  transferActive: require('resources/images/transfer-Active.svg'),
  transferInactive: require('resources/images/transfer-Inactive.svg'),
  bankActive: require('resources/images/bank-Active.svg'),
  bankInactive: require('resources/images/bank-Inactive.svg'),
  supplyActive: require('resources/images/supply-Active.svg'),
  supplyInactive: require('resources/images/supply-Inactive.svg'),
  menuIcon: require('resources/images/menu-icon.svg'),
  closeIcon: require('resources/images/closeIcon.svg'),
  drawer_icon: require('resources/images/drawer-icon.svg'),
  cross_icon: require('resources/images/cross-icon.svg'),
  search_icon: require('resources/images/search-icon.svg'),
  copy_regular: require('resources/images/copy_link.svg'),
  arrow: require('resources/images/arrow.svg'),
  back_arrow: require('resources/images/back-arrow.svg'),
  calender_without_background: require('resources/images/calender_without_background.svg'),
  app_logo: require('resources/images/ic_launcher.png'),
  socialInactive: require('resources/images/social_links_deactive.svg'),
  socialactive: require('resources/images/social_links_active.svg'),
  eventHand: require('resources/images/event-hand.svg'),
  eventGift: require('resources/images/event-gift.svg'),
  eventTicket: require('resources/images/event-ticket.svg'),
  deleteSponser: require('resources/images/edit_icon.svg'),
  oktionCrashImg: require('resources/images/oktion-crash.png'),
  verificationActive: require('resources/images/verification_active.svg'),
  verificationInactive: require('resources/images/verification_inactive.svg'),
  IdentificationDocIcon: require('resources/images/Identification_doc_icon.svg'),
  verificationNoIcon: require('resources/images/verification-no-icon.svg'),
  verificationYesIcon: require('resources/images/verification-yes-icon.svg'),
  verificationDeleteIcon: require('resources/images/verification-delete-icon.svg'),
  verificationAlertIcon: require('resources/images/verification-alert.svg'),
  verificationBusinessIcon: require('resources/images/business-verification-icon .svg'),
  socailLinkInactiveIcon: require('resources/images/social-inactive-icon.svg'),
  socailLinkActiveIcon: require('resources/images/social-active-icon.svg'),
};
