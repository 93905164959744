import React, { useState, useEffect, useContext } from 'react';
import Col from 'reactstrap/es/Col';
import { Button } from 'reactstrap';
import axiosInstance from 'main/axios';
import { fetchFromStorage } from 'utility';
import { URLS } from 'library/common/constants/UrlConstants';
// import ActionMessage from 'library/common/components/ActionMessage';
import { connect } from 'react-redux';
import LogoutModal from '../../../Raffle/LogoutModal';
import { useHistory } from 'react-router-dom';
import { identifiers } from 'library/common/constants/IdentifierConstants';
import { EventEmitter } from 'library/common/constants/event';
// import EventEmitter from 'reactjs-eventemitter';
import { useParams } from 'react-router-dom';
import Event_Emitter from 'reactjs-eventemitter';
import { SocketContext } from 'main/context/socket';

var CurrencyFormat = require('react-currency-format');
const BiddingAmountCount = ({
  data,
  isLoggedIn,
  sendData,
  handleSetMaxBid,
  handleBidNow,
  handleBuyItNow,
  count,
  currency,
  currencySymbol,
  handleAdd,
  handleMinus,
  auctionData,
  bidding,
  eventDetailsData,
  singleItem,
  setQuickBid,
  auctionUpdatedData,
  auctionFeatureData,
}) => {
  const params = useParams();
  const [bidCount, setCount] = useState(count);
  const [biddingData, setBiddingData] = useState({});
  const [isToggle, setToggle] = useState(false);
  const history = useHistory();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  // const [counter, setCounter] = useState(1);
  // const [allAuction, setAllAuction] = useState(0);

  // useEffect(() => {
  //   setBiddingData(singleItem);
  // }, [singleItem]);

  useEffect(() => {
    auctionAll();
    Event_Emitter.subscribe('socketUpdateBiddingData', value => {
      auctionAll();
    });
  }, []);

  useEffect(() => {
    EventEmitter.subscribe('updateBiddingData', auction_data => {
      // auctionAll();

      setBiddingData(auction_data);

      // setCount(auction_data?.auction_data + auction_data?.bidIncrement);
      // setCount(auctionUpdatedData?.auction_data + auctionUpdatedData?.bidIncrement);
      // console.log('auctionUpdatedData', auctionUpdatedData);
    });
  });
  useEffect(() => {
    EventEmitter.subscribe('socketUpdateBiddingData', value => {
      // console.log('checkvalue', value);
      // setCount(auctionUpdatedData?.auction_data + auctionUpdatedData?.bidIncrement);
      // setAuctionUpdatedData(value);
      // getAuctionDeatails();
    });
  }, []);
  useEffect(() => {
    setCount(count);
  }, [handleAdd]);
  useEffect(() => {
    setCount(count);
  }, [handleMinus]);
  const toggle = () => {
    setToggle(!isToggle);
  };

  const handleClick = () => {
    history.push('/login', {
      redirectTo: 'event-details',
      featureName: 'auction',
      eventId: params?.id,
    });
  };
  const auctionAll = async () => {
    // console.log('hello');
    const token = fetchFromStorage(identifiers.token);
    var eventID = params?.id;
    // console.log('token', token);
    // var queryString = `?token=${token?.token}&sort=PO`;
    var queryString = isLoggedIn ? `?token=${token?.token}` : '';
    const { status, data } = await axiosInstance.get(URLS.auctionAll(eventID, queryString));
    if (status === 200) {
      // console.log('okokokok', data);
      // setCounter(2);
      var auction_data = data?.data?.eventFeatures[0]?.auctionOptions?.find(item => item.id === singleItem?.id);
      // console.log('update_data', data?.data?.eventFeatures[0]?.auctionOptions);
      setBiddingData(auction_data);
      // setAllAuction(1);
      // setCount(auction_data?.currentBid + auction_data?.bidIncrement);
    }
  };

  // console.log('biddingData childcomponent', biddingData);

  return (
    <>
      <Col sm="12" className={setQuickBid === true ? 'mt-3 p-0' : 'bidding-count mt-3'}>
        <div className="d-flex justify-content-between sm-d-flex align-items-center">
          <div
            className={auctionFeatureData[0]?.isActive === false ? 'addButtons blur-section' : 'addButtons'}
            onClick={handleMinus}
            style={{
              backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
            }}>
            -
          </div>
          <h5>
            {/* {`${currency} 
            ${parseFloat(bidCount).toFixed(2)}`} */}
            <CurrencyFormat
              value={bidCount !== null ? bidCount : '0.00'}
              displayType={'text'}
              thousandSeparator={true}
              prefix={`${currency} ${currencySymbol}`}
              thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
              // thousandSpacing="2s"
              decimalScale="2"
              fixedDecimalScale={true}
              style={{ fontSize: '20px' }}
            />
          </h5>
          <div
            className={auctionFeatureData[0]?.isActive === false ? 'addButtons blur-section' : 'addButtons'}
            onClick={handleAdd}
            style={{
              backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
            }}>
            +
          </div>
        </div>
        <Button
          onClick={handleBuyItNow}
          className={
            singleItem?.isSold || auctionFeatureData[0]?.isActive === false
              ? 'btn-block mt-2'
              : 'btn-block mt-2 blue-button'
          }
          disabled={
            singleItem?.isSold ||
            auctionFeatureData[0]?.isActive === false ||
            singleItem?.currentBid >= singleItem?.buyItNow ||
            singleItem?.buyItNow === null ||
            singleItem?.markAsPaid ||
            singleItem?.buyItNow === 0
          }>
          {singleItem > singleItem?.buyItNow || singleItem?.buyItNow === null || singleItem?.buyItNow === 0 ? (
            OKTION_VARIABLE?.OKTION_BUYIT_NOW
          ) : (
            /* `${currency} ${parseFloat(singleItem?.buyItNow).toFixed(2)}` */
            <CurrencyFormat
              value={singleItem?.buyItNow !== null ? singleItem?.buyItNow : '0.00'}
              displayType={'text'}
              thousandSeparator={true}
              prefix={`${OKTION_VARIABLE?.OKTION_BUYIT_NOWFOR} ${currency} ${currencySymbol}`}
              thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
              // thousandSpacing="2s"
              decimalScale="2"
              fixedDecimalScale={true}
            />
          )}
        </Button>
        <div className="d-flex justify-content-between sm-d-flex">
          <Button
            onClick={handleBidNow}
            className={eventDetailsData?.primaryColour ? 'btn-block mt-2 mr-3' : 'main-button btn-block mt-2 mr-3'}
            style={{
              backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
            }}
            disabled={singleItem?.isSold || auctionFeatureData[0]?.isActive === false || singleItem?.markAsPaid}
            // disabled={count > biddingData?.currentBid ? false : true}
          >
            {OKTION_VARIABLE?.OKTION_BID_NOW}
          </Button>
          <Button
            onClick={handleSetMaxBid}
            className={eventDetailsData?.primaryColour ? 'btn-block mt-2' : 'main-button btn-block mt-2'}
            disabled={singleItem?.isSold || auctionFeatureData[0]?.isActive === false || singleItem?.markAsPaid}
            style={{
              backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
              color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
            }}>
            {OKTION_VARIABLE?.OKTION_SETMAX_BID}
          </Button>
        </div>
        {setQuickBid === true ? (
          <div className="d-flex justify-content-around mt-2">
            <div>
              <p
                className="sm-fs-12"
                style={{
                  color: eventDetailsData?.secondaryTextColour ? eventDetailsData?.secondaryTextColour : '',
                }}>
                {OKTION_VARIABLE?.OKTION_CURRENTBID}{' '}
              </p>
              <p className="d-flex justify-content-center">
                <CurrencyFormat
                  value={biddingData?.myCurrentBid !== null ? biddingData?.myCurrentBid : '0.00'}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={`${currency} ${currencySymbol}`}
                  thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                  // thousandSpacing="2s"
                  decimalScale="2"
                  fixedDecimalScale={true}
                  style={{ fontSize: '18px' }}
                />
              </p>
            </div>
            <div>
              <p
                className="sm-fs-12"
                style={{
                  color: eventDetailsData?.secondaryTextColour ? eventDetailsData?.secondaryTextColour : '',
                }}>
                {OKTION_VARIABLE?.OKTION_MAX_BID}
              </p>
              <p className="d-flex justify-content-center">
                <CurrencyFormat
                  value={biddingData?.myMaxBid !== null ? biddingData?.myMaxBid : '0.00'}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={`${currency} ${currencySymbol}`}
                  thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                  // thousandSpacing="2s"
                  decimalScale="2"
                  fixedDecimalScale={true}
                  style={{ fontSize: '18px' }}
                />
              </p>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-around mt-2">
            <p
              className="sm-fs-12"
              style={{
                color: eventDetailsData?.secondaryTextColour ? eventDetailsData?.secondaryTextColour : '',
              }}>
              {OKTION_VARIABLE?.OKTION_CURRENTBID} :{' '}
              <CurrencyFormat
                value={biddingData?.myCurrentBid !== null ? biddingData?.myCurrentBid : '0.00'}
                displayType={'text'}
                thousandSeparator={true}
                prefix={`${currency} ${currencySymbol}`}
                thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                // thousandSpacing="2s"
                decimalScale="2"
                fixedDecimalScale={true}
                style={{ fontSize: '18px' }}
              />
            </p>
            <p
              className="sm-fs-12"
              style={{
                color: eventDetailsData?.secondaryTextColour ? eventDetailsData?.secondaryTextColour : '',
              }}>
              {OKTION_VARIABLE?.OKTION_MAX_BID} :{' '}
              <CurrencyFormat
                value={biddingData?.myMaxBid !== null ? biddingData?.myMaxBid : '0.00'}
                displayType={'text'}
                thousandSeparator={true}
                prefix={`${currency} ${currencySymbol}`}
                thousandSpacing={currencySymbol === '₹' ? '2s' : '3'}
                // thousandSpacing="2s"
                decimalScale="2"
                fixedDecimalScale={true}
                style={{ fontSize: '18px' }}
              />
            </p>
          </div>
        )}
      </Col>
      <LogoutModal isToggle={isToggle} toggle={toggle} handleClick={handleClick} />
    </>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, {})(BiddingAmountCount);
