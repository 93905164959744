import React, { useContext } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { images } from '../../../../../library/common/constants/ImageConstants';
import { SocketContext } from 'main/context/socket';
// import { images } from '../../../../library/common/constants/ImageConstants';

const ConfirmationModal = ({ isToggle, toggle, handleSubmitForm, orgName, role }) => {
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  return (
    <div>
      <Modal size="xl" className="modal-dialog add-price-modal logout-modal delete-event-Modal" isOpen={isToggle}>
        <div className="text-right">
          <img className="cursor-pointer pr-3" onClick={toggle} src={images.close} alt="" />
        </div>
        <ModalBody>
          {/* <div>{/ <img src={images.giftImage} alt="" /> /}</div> */}
          <div></div>
          <p className="pt-4 pb-4 title">{`Do you want to remove the ${role} this ${orgName}?`}</p>
          <div className="d-flex justify-content-between mobileButton">
            <Button className="app-button black-button h-60" onClick={toggle}>
              {OKTION_VARIABLE?.OKTION_BUTTON_CANCEL}
            </Button>
            <Button className="app-button main-button h-60" onClick={() => handleSubmitForm()}>
              {OKTION_VARIABLE?.OKTION_BUTTON_CONFIRM}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
