import React, { useState, useEffect, useContext } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
// import { eventDetailsPageData } from './EventDetailsTab';
import Auction from './Frames/Auction/Auction';
import About from './Frames/About/About';
import Donations from './Frames/Donation/Donation';
import Raffle from './Frames/Raffle/Raffle';
import Sponsors from './Frames/Sponsors/Sponsors';
import Tickets from './Frames/Tickets/Tickets';
import { URLS } from '../../library/common/constants/UrlConstants';
import { fetchFromStorage, saveToStorage } from '../../utility';
import { identifiers } from '../../library/common/constants/IdentifierConstants';
import PageLoader from '../../library/common/components/PageLoader';
import axiosInstance from '../../main/axios';
import ActionMessage from '../../library/common/components/ActionMessage';
import EventEmitter from 'reactjs-eventemitter';
import jwt from 'jsonwebtoken';
import { connect } from 'react-redux';
import { getUserDetails, logout, setAuthentication } from 'library/common/actions/AuthActions';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import 'swiper/swiper.scss';
import { SocketContext } from '../../main/context/socket';

const TabWrapper = props => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  var eventId = params?.id;
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  const eventDetailsPageData = [
    { id: 1, name: OKTION_VARIABLE?.OKTION_ABOUT },
    { id: 2, name: OKTION_VARIABLE?.OKTION_AUCTION, value: 'auction' },
    { id: 3, name: OKTION_VARIABLE?.OKTION_DONATION, value: 'donation' },
    { id: 4, name: OKTION_VARIABLE?.OKTION_RAFFLE, value: 'raffle' },
    { id: 5, name: OKTION_VARIABLE?.OKTION_TICKET, value: 'ticket' },
    { id: 6, name: OKTION_VARIABLE?.OKTION_SPONSORS },
  ];
  const [activeTab, setActiveTab] = useState('1');
  const [loading, setLoader] = useState(false);
  const [eventFeatures, seteventFeatures] = useState([]);
  const [auctionData, setAuctionDetails] = useState([]);
  const [windowSize, setWindowsize] = useState('');
  const [timeZone, setTimeZone] = useState('');
  const [auctionUpdatedData, setAuctionUpdatedData] = useState({});
  const isAuction = history?.location?.state?.isAuction;

  const [message, setMessage] = useState({
    display: false,
    type: '',
    message: '',
  });
  const token = fetchFromStorage(identifiers?.token);
  const searchParams = new URLSearchParams(location?.search);
  const autoLoginToken = searchParams.get('token');
  const fetureType = searchParams.get('type');
  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      EventEmitter.dispatch('activeTabData', tab);
    }
  };

  function isJwt(token) {
    try {
      // Attempt to decode the token
      const decoded = jwt.decode(token);

      // Check if the decoding was successful
      if (decoded) {
        // Optionally, you can further validate the decoded token here
        return true; // It's a valid JWT
      } else {
        return false; // It's not a valid JWT
      }
    } catch (error) {
      return false; // An error occurred during decoding, it's not a valid JWT
    }
  }

  useEffect(() => {
    EventEmitter.subscribe('socketUpdateBiddingData', value => {
      setAuctionUpdatedData(value);
      getAuctionDeatails();
    });
    isJwt(autoLoginToken) && handleAutoLogin();
  }, []);

  useEffect(() => {
    if (token) {
      (async () => {
        // setLoader(true);
        try {
          const { status, data } = await axiosInstance.get(URLS.getTimeZone());
          if (status === 200) {
            // setLoader(false);
            var TIMEZONE_ARRAY = data?.data?.timezones;
            var timeZoneOffset = TIMEZONE_ARRAY.find(item => item.id === props?.eventDetails?.timeZoneId);
            setTimeZone(timeZoneOffset?.utcOffset);
          }
        } catch (err) {
          // setLoader(false);
        }
      })();
    }
  }, [props?.eventDetails]);

  // useEffect(() => {
  //   EventEmitter.subscribe('updateLotData', event => {
  //     getAuctionDeatails();
  //   });
  // });
  useEffect(() => {
    getEventDetailsById();
    getAuctionDeatails();
    var defaultTabValue =
      props.featureName === 'auction'
        ? '2'
        : props.featureName === 'donation'
        ? '3'
        : props.featureName === 'raffle'
        ? '4'
        : props.featureName === 'ticket'
        ? '6'
        : '1';
    setActiveTab(isAuction ? '2' : fetureType ? '3' : defaultTabValue);
    EventEmitter.dispatch('setFeaturesData', defaultTabValue);
    EventEmitter.subscribe('updateBiddingData', event => {
      getAuctionDeatails();
    });
    // EventEmitter.subscribe('updateauctionlotdata', event => {
    //   console.log('updateauctionlotdata', event);
    //   getEventDetailsById();
    // });

    // console.log('colour', props.preview_color);
  }, []);

  const getEventDetailsById = async () => {
    // const eventDetails = fetchFromStorage(identifiers.eventDetails);
    const previewStatus = history?.location?.state?.previewStatus;
    var prevStatus = previewStatus !== undefined ? previewStatus : 0;
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.getFeatureByEventId(eventId, prevStatus, token?.token));
      setLoader(false);
      if (status === 200 || status === 304) {
        seteventFeatures(data?.data?.eventFeatures);
        EventEmitter.dispatch('setFeaturesData', data?.data?.eventFeatures);
      }
    } catch (err) {
      setLoader(false);
    }
  };
  useEffect(() => {
    EventEmitter.subscribe('feturesErrorMessage', event => {
      setActionMessage(true, 'Error', event);
      setTimeout(() => {
        setActionMessage(false);
      }, 4000);
    });
  });

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });
  const handleResize = () => {
    setWindowsize(window.innerWidth);
  };

  const getAuctionDeatails = async () => {
    // const auctionDetails = fetchFromStorage(identifiers.eventDetails);
    // console.log('token', token);
    const previewStatus = history?.location?.state?.previewStatus;
    var prevStatus = previewStatus !== undefined ? previewStatus : 0;
    var queryString = props?.isLoggedIn
      ? `?token=${token?.token}&previewStatus=${prevStatus}`
      : `?previewStatus=${prevStatus}`;
    // setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.auctionAll(eventId, queryString));
      // setLoader(false);
      setAuctionDetails(data);
    } catch (err) {
      // setLoader(false);
    }
  };

  const setActionMessage = (display = false, type = '', message = '') => {
    setMessage({
      display,
      type,
      message,
    });
  };

  const handleLogout = async () => {
    setLoader(true);
    const authToken = {
      token: autoLoginToken,
    };
    const userDetail = fetchFromStorage(identifiers.userDetail);
    const requestParameters = {
      webAppToken: authToken?.user?.hasOwnProperty('webAppToken') ? authToken?.user?.webAppToken : '',
    };
    try {
      const { status, data } = await axiosInstance.post(URLS.logoutByUserId(userDetail?.id), requestParameters);
      if (status === 200 || status === 201) {
        props.logout();
        saveToStorage('token', authToken);
        window.location.href = '/event-details/' + eventId + '?type=donation';
      }
    } catch (err) {
      setLoader(false);
    }
  };

  const handleAutoLogin = () => {
    const storedToken = fetchFromStorage(identifiers?.token);

    if (storedToken?.token) {
      handleLogout();
    } else {
      const jsonToken = {
        token: autoLoginToken,
      };
      saveToStorage('token', jsonToken);
      props.getUserDetails();
      window.location.href = '/event-details/' + eventId + '?type=donation';
    }
  };
  return (
    <div
      className="eventDetailsTab"
      // style={{ color: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '' }}
    >
      <Row>
        <Col>{loading && <PageLoader />}</Col>
      </Row>
      <Nav
        tabs
        className="event-tab border-bottom border-top details-tab-main"
        style={{
          color: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
        }}>
        {/* <Swiper
          className="show-on-mobile listwrap"
          spaceBetween={2}
          slidesPerView={3.25}
          navigation
          centeredSlides={false}
          onSlideChange={() => {}}
          onSwiper={swiper => {
            //console.log(swiper)
          }}>
          {eventDetailsPageData.map((item, i) => {
            var featureType = props?.eventDetails?.featureType;
            var flag = props?.eventDetails && featureType.includes(item?.value);
            var tempFlag = item.hasOwnProperty('value');

            return (
              <>
                {(flag || !tempFlag) && (
                  <SwiperSlide>
                    <div
                      className={`${activeTab === `${item.id}` && 'active'}`}
                      style={{
                        borderBottom: `${
                          activeTab === `${item.id}` && props?.eventDetails?.primaryColour
                            ? `solid 5px ${props?.eventDetails?.primaryColour}`
                            : ''
                        }`,
                      }}>
                      <a
                        // className={props.white_label ? props.preview_color : ''}
                        style={{
                          color: `${
                            activeTab === `${item.id}` && props?.eventDetails?.primaryColour
                              ? props?.eventDetails?.primaryColour
                              : ''
                          }`,
                        }}
                        onClick={() => {
                          toggle(`${item.id}`);
                        }}>
                        {item.name}
                      </a>
                    </div>
                  </SwiperSlide>
                )}
              </>
            );
          })}{' '}
        </Swiper> */}
        <div className="show-on-mobile">
          <div class="tab-container">
            <div class="tabs">
              {eventDetailsPageData.map((item, i) => {
                var featureType = props?.eventDetails?.featureType;
                var flag = props?.eventDetails && featureType.includes(item?.value);
                var tempFlag = item.hasOwnProperty('value');

                return (
                  <>
                    {(flag || !tempFlag) && (
                      <li
                        className={`${activeTab === `${item?.id}` && 'active'} tab`}
                        style={{
                          borderBottom: `${
                            activeTab === `${item?.id}` && props?.eventDetails?.primaryColour
                              ? `solid 5px ${props?.eventDetails?.primaryColour}`
                              : ''
                          }`,
                        }}>
                        <a
                          className="nav-link"
                          // className={props.white_label ? props.preview_color : ''}
                          style={{
                            color: `${
                              activeTab === `${item?.id}` && props?.eventDetails?.primaryColour
                                ? props?.eventDetails?.primaryColour
                                : ''
                            }`,
                          }}
                          onClick={() => {
                            toggle(`${item?.id}`);
                          }}>
                          {item.name}
                        </a>
                      </li>
                    )}
                  </>
                );
              })}{' '}
            </div>
          </div>
        </div>
        {eventDetailsPageData.map((item, i) => {
          var featureType = props?.eventDetails?.featureType;
          var flag = props?.eventDetails && featureType.includes(item?.value);
          var tempFlag = item.hasOwnProperty('value');
          return (
            <>
              {(flag || !tempFlag) && (
                <NavItem
                  className={`hide-on-mobile ${activeTab === `${item.id}` && 'active'}`}
                  style={{
                    borderBottom: `${
                      activeTab === `${item.id}` && props?.eventDetails?.primaryColour
                        ? `solid 5px ${props?.eventDetails?.primaryColour}`
                        : ''
                    }`,
                  }}>
                  <NavLink
                    // className={props.white_label ? props.preview_color : ''}
                    style={{
                      color: `${
                        activeTab === `${item.id}` && props?.eventDetails?.primaryColour
                          ? props?.eventDetails?.primaryColour
                          : ''
                      }`,
                    }}
                    onClick={() => {
                      toggle(`${item.id}`);
                    }}>
                    {item.name}
                  </NavLink>
                </NavItem>
              )}
            </>
          );
        })}{' '}
      </Nav>
      {message.display && (
        <div className="btn-block mt-2">
          <ActionMessage message={message.message} type={message.type} styleClass={message.styleClass} />
        </div>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <About eventDetails={props?.eventDetails} whitelabel={props?.whitelabel} timeZone={timeZone} />
        </TabPane>
        {}
        <TabPane tabId="2">
          {eventFeatures.some(item => item.type === 'auction') && (
            <Row>
              <Col sm="12">
                <Auction
                  auctionData={auctionData}
                  eventFeatures={eventFeatures}
                  eventDetails={props?.eventDetails}
                  whitelabel={props?.whitelabel}
                  auctionUpdatedData={auctionUpdatedData}
                />
              </Col>
            </Row>
          )}
        </TabPane>
        <TabPane tabId="3">
          {eventFeatures.some(item => item.type === 'donation') && (
            <Row>
              <Col sm="12">
                <Donations
                  eventFeatures={eventFeatures}
                  eventDetails={props?.eventDetails}
                  whitelabel={props?.whitelabel}
                  timeZone={timeZone}
                />
              </Col>
            </Row>
          )}
        </TabPane>
        <TabPane tabId="4">
          {eventFeatures.some(item => item.type === 'raffle') && (
            <Raffle
              eventDetails={props?.eventDetails}
              eventFeatures={eventFeatures}
              previewcolor={props.preview_color}
              label_value={props.white_lable}
              whitelabel={props?.whitelabel}
              timeZone={timeZone}
            />
          )}
        </TabPane>
        <TabPane tabId="5">
          {eventFeatures.some(item => item.type === 'ticket') && (
            <Tickets
              eventFeatures={eventFeatures}
              eventDetails={props?.eventDetails}
              whitelabel={props?.whitelabel}
              timeZone={timeZone}
            />
          )}
        </TabPane>

        <TabPane tabId="6">
          <Sponsors eventDetails={props?.eventDetails} />
        </TabPane>
      </TabContent>
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, { getUserDetails, logout, setAuthentication })(TabWrapper);
