import React, { useState, useContext } from 'react';
import { Card, CardBody } from 'reactstrap';
import Button from 'reactstrap/es/Button';
import { addToCart } from '../../../../library/common/actions/SubscriptionAction';
import { connect } from 'react-redux';
import axiosInstance from '../../../../main/axios';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import { useHistory, useParams } from 'react-router-dom';
import PageLoader from '../../../../library/common/components/PageLoader';
import EventEmitter from 'reactjs-eventemitter';
import { SocketContext } from 'main/context/socket';
import { setApiMessage } from 'library/common/constants/function';
import { fetchFromStorage } from 'utility';
import { identifiers } from 'library/common/constants/IdentifierConstants';
// import { NonceProvider } from 'react-select';

var CurrencyFormat = require('react-currency-format');

const RaffleAddToCard = ({
  ticketName,
  price,
  noTicket,
  item,
  eventDetails,
  handleBtnClick,
  eventDetailsData,
  whitelabel,
  setClickItemData,
  islogin,
}) => {
  const history = useHistory();
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const params = useParams();
  var eventId = params?.id;
  const userDetail = fetchFromStorage(identifiers?.userDetail);

  var obj = {
    // "id": 3896,
    type: 'ticket',
    quantity: count,
    referenceId: null,
    subscriptionName: null,
    subscriptionPrice: null,
    name: item?.ticketBundleName,
    // amount: item?.bundlePrize * count,
    amount: item?.bundlePrize,
    purchaseReferenceId: item?.id,
    isRaffleTicket: true,
    donationAmount: null,
    eventId: eventDetailsData?.id,
    currency: eventDetailsData?.currency?.abbreviation,
    symbol: eventDetailsData?.currency?.symbol,
    event: eventDetailsData,
    eventName: eventDetailsData?.eventName,
    digiCommission: 0,
    ticketPrice: item?.bundlePrize,
    cardCharedAmount: 0,
    total: item?.bundlePrize * count,
  };

  const handleClick = async () => {
    // const senData = {
    //   eventId: eventId.id,
    //   type: 'feature',
    //   referenceId: value,
    // };

    if (islogin && userDetail?.userType === 'normal') {
      setLoading(true);
      const senData = [
        {
          type: 'ticket',
          quantity: count,
          eventId: eventDetails?.eventId,
          referenceId: null,
          purchaseReferenceId: item?.id,
          isRaffleTicket: true,
        },
      ];
      try {
        const { status } = await axiosInstance.post(URLS.addToCart, senData);
        if (status === 201 || status === 200) {
          setLoading(false);
          // history.push('/checkout');
          history.push('/checkout', {
            FROM_WHERE: 'PUBLIC_EVENT_DETAILS',
            eventId: eventId,
          });
        }
      } catch (err) {
        setLoading(false);
        // EventEmitter.dispatch('feturesErrorMessage', err?.response?.data?.msg);
        setApiMessage('error', err?.response?.data?.msg);
      }
    } else if (islogin && userDetail?.userType === 'guest') {
      handleCheckValidFeture();
    } else {
      handleCheckValidFeture();
    }
  };

  const handleCheckValidFeture = async () => {
    var guestCheckoutArray = fetchFromStorage(identifiers?.guestcheckoutdata);
    const senData = {
      type: 'ticket',
      quantity: count,
      eventId: eventDetails?.eventId,
      referenceId: null,
      purchaseReferenceId: item?.id,
      isRaffleTicket: true,
    };

    // if (!Array.isArray(guestCheckoutArray)) {
    //   guestCheckoutArray = [];
    // }

    // let filterValidateCart = [...guestCheckoutArray, senData];
    // var filterguestCheckoutArray = filterValidateCart.reduce((prev, cur) => {
    //   if (!Array.isArray(prev)) {
    //     prev = [];
    //   }

    //   const index = prev?.findIndex(v => v?.purchaseReferenceId === cur?.purchaseReferenceId);
    //   if (index === -1) {
    //     prev.push(cur);
    //   } else {
    //     prev[index].total = (prev[index].total || 0) + (cur?.total || 0);
    //     prev[index].quantity = (prev[index].quantity || 0) + (cur?.quantity || 0);
    //   }
    //   return prev;
    // }, []); // Initialize prev as an empty array

    let finalOldCart = guestCheckoutArray?.map(item => {
      return {
        type: item?.type,
        quantity: item?.quantity,
        eventId: item?.eventId,
        referenceId: item?.referenceId,
        purchaseReferenceId: item?.purchaseReferenceId,
        isRaffleTicket: item?.isRaffleTicket,
        donationAmount: item?.donationAmount,
      };
    });

    let filterguestCheckoutArray = {
      cartArray: [senData],
      oldCart: Array.isArray(finalOldCart) ? finalOldCart : [],
    };
    setLoading(true);
    try {
      const { status } = await axiosInstance.post(URLS.isValidFeture, filterguestCheckoutArray);
      if (status === 201 || status === 200) {
        setClickItemData(obj);
        setLoading(false);
        handleBtnClick(obj);
      }
    } catch (err) {
      setLoading(false);
      setApiMessage('error', err?.response?.data?.msg);
    }
  };

  const handleAdd = () => {
    setCount(count + 1);
  };
  const handleMinus = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };
  const handleInputChange = event => {
    let inputValue = event.target.value;
    inputValue = inputValue === '' ? 1 : inputValue.replace(/^0/, '1');
    setCount(Number(inputValue));
  };

  const handleInputFocus = event => {
    event.target.select();
  };

  return (
    <>
      {loading && <PageLoader />}
      <div
        className="wonItemPrizeCard addToCart cursor-pointer"
        style={{
          pointerEvents: !eventDetails?.isActive ? 'none' : '',
          opacity: !eventDetails?.isActive ? '0.4' : '1',
        }}>
        <Card>
          <CardBody className="text-center">
            <p className="mb-4 mt-3 fw-500">{ticketName}</p>
            <h3 className="mb-2">
              {/* {eventDetailsData?.currency?.abbreviation} {eventDetailsData?.currency?.symbol} */}
              {/* {count * price} */}
              <CurrencyFormat
                value={count * price}
                displayType={'text'}
                thousandSeparator={true}
                prefix={`${eventDetailsData?.currency != null ? eventDetailsData?.currency?.abbreviation : ''} ${
                  eventDetailsData?.currency != null ? eventDetailsData?.currency?.symbol : ''
                }`}
                thousandSpacing="2s"
                decimalScale="2"
                fixedDecimalScale={true}
                className="text-currency"
              />
            </h3>
            <h5 className="font-weight-normal">
              {noTicket} {noTicket === 1 ? OKTION_VARIABLE?.OKTION_TICKET : OKTION_VARIABLE?.OKTION_TICKET_TITLE}
            </h5>

            <div className="d-flex justify-content-center mt-4">
              <div
                className="addButtons"
                onClick={handleMinus}
                style={{
                  backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                  borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                  color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
                  pointerEvents: whitelabel === true && 'none',
                  opacity: whitelabel === true && '0.5',
                }}>
                -
              </div>
              <input
                value={count}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                className="addButtonInput h-45"
                type="number"
                placeholder=""
                // onKeyPress={event => {
                //   const inputValue = event.target.value;
                //   const keyPressed = event.key;

                //   // Check if the input is empty and the key pressed is "0"
                //   if (inputValue === '' && keyPressed === '0') {
                //     event.preventDefault();
                //   }
                // }}
              />
              <div
                className="addButtons"
                onClick={handleAdd}
                style={{
                  backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                  borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                  color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
                  pointerEvents: whitelabel === true && 'none',
                  opacity: whitelabel === true && '0.5',
                }}>
                +
              </div>
            </div>

            <Button
              className="main-button h-60 w-100 mt-3"
              onClick={() => {
                handleClick();
              }}
              disabled={
                whitelabel === true || eventDetails?.isDraw === '1' || item?.totalCartItems > 0
                  ? true
                  : false || count === 0
              }
              style={{
                backgroundColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                borderColor: eventDetailsData?.primaryColour ? eventDetailsData?.primaryColour : '',
                color: eventDetailsData?.primaryTextColour ? eventDetailsData?.primaryTextColour : '',
              }}>
              {OKTION_VARIABLE?.OKTION_ADD_TO_CART}
            </Button>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
export default connect(mapStateToProps, { addToCart })(RaffleAddToCard);
